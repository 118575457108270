.tag-outline[data-v-02fe24ac] {
  list-style-type: none;
  padding-left: 0;
}
.tag-outline > li[data-v-02fe24ac] {
    margin-top: var(--spacing-15);
}
.tag-outline > li[data-v-02fe24ac]:first-child {
    margin-top: 0;
}
