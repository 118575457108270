.alerts-wrapper[data-v-e0706ede] {
  padding: 16px 24px 0 24px;
  margin-bottom: -8px;
}
.capture[data-v-e0706ede] {
  padding: 24px;
}
.capture-collapse[data-v-e0706ede] {
    padding: 8px 0 16px 0;
}
.capture-optional[data-v-e0706ede] {
    padding: 0 24px 24px 24px;
}
.capture-optional .ecs-form-set[data-v-e0706ede]:not(:first-child) {
      margin-top: 24px;
}
.ecs-file-list-meta .ecs-switch-wrapper[data-v-e0706ede] {
  color: var(--color-gray-15);
}
