.ecs-select-tile[data-v-ed663fc8] {
  flex: 1;
}
.ecs-form-control.dat-select[data-v-ed663fc8] {
  width: 72px;
}
.ai-summaries[data-v-ed663fc8] {
  margin-top: var(--spacing-50);
}
.ai-summaries .ecs-switch-wrapper label[data-v-ed663fc8] {
    font-weight: var(--font-weight-medium);
}
.ai-summaries .ecs-form-group[data-v-ed663fc8] {
    width: 490px;
    padding-left: 52px;
}
.ai-topic-summaries[data-v-ed663fc8] {
  padding-left: 52px;
  display: block;
}
