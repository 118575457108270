.runtime-footer[data-v-3f3a3ac9] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
  font-size: 12px;
}
.runtime-footer > div[data-v-3f3a3ac9] {
    position: relative;
    z-index: 1;
}
.runtime-footer .runtime[data-v-3f3a3ac9],
  .runtime-footer .current[data-v-3f3a3ac9] {
    color: rgba(101, 104, 122, 0.7);
}
.runtime-footer .total[data-v-3f3a3ac9] {
    color: #65687A;
    font-weight: 500;
    margin-left: 4px;
}
