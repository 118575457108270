<template>
  <VueMultiselect v-if="tokenSearch" ref="search" id="search" class="search-standalone" :placeholder="placeholder" track-by="id" label="key" group-label="label" group-values="value" :hide-selected="true" :multiple="true" :option-height="50" :show-no-results="false" :options="options" :model-value="filters" :close-on-select="false" :class="{ 'multiselect--empty': empty, 'multiselect--notempty': hasPills }" :show-labels="true" :disabled="disabled || loading" :taggable="false" :groupSelect="null" @select="select" @remove="remove" @search-change="autocomplete" selectLabel="" selectedLabel="" deselectLabel="">

    <template v-slot:option="props">
      <ecs-multiselect-option v-if="props.option.key" type="key" :icon="icons[props.option.field]">{{ props.option.text || tidyUpText(props.option.key, props.option.field) }}</ecs-multiselect-option>
      
      <ecs-multiselect-option v-if="props.option.$groupLabel" type="header">{{ props.option.$groupLabel }}</ecs-multiselect-option>
    </template>

    <template v-slot:tag="props">
      <ecs-multiselect-search-token @remove="remove(props.option)" :icon="icon(props.option)">{{ filterText(props.option) }}</ecs-multiselect-search-token>
    </template>
  </VueMultiselect>
</template>

<script>
  import EcPopover      from 'ec-common/components/utility/popover'
  import VueMultiselect from 'vue-multiselect'
  import Mousetrap      from 'mousetrap'

  export default {
    components: { EcPopover, VueMultiselect },

    props: {
      placeholder : String,
      totalLabel  : String,
      totalCount  : Number,
      namespace   : String,
      matterId    : [Number, String],
      disabled    : Boolean,
      loading     : Boolean,
      documentId  : Number
    },

    data() {
      return {
        showSearchWarning : false,
        shouldFocus       : false,
        tokenSearch       : true,
        options           : [],
        icons: {
          'type'                      : 'marker',
          'excerpt'                   : 'text',
          'favorability'              : 'reorder',
          'user.name'                 : 'commenter',
          'commenter.name'            : 'commenter',
          'creator.name'              : 'commenter',
          'comment.text'              : 'comment',
          'tags.name'                 : 'tag',
          'tags.name.raw'             : 'tag',
          'profiles.name'             : 'person',
          'annotations.profiles.name' : 'person'
        }
      }
    },

    computed: {
      haveCount(){
        return this.totalCount >= 0 && !this.loading
      },

      countLabel() {
        return _.pluralize(this.totalLabel, this.totalCount, false)
      },

      filters() { // ticket #6639 truncate text that appears in the pills
        const filts = this.$store.getters[`${ this.namespace }/filters`]
        return _.map(filts, filter => {
          return Object.assign({}, filter, {
            text: this.truncateText(filter.text)
          })
        })
      },

      query() {
        return this.$store.getters[`${ this.namespace }/query`]
      },

      isIssueProfiles() {
        return this.$store.getters['witnesses/isIssueProfiles']
      },

      empty() {
        return _.isEmpty(_.flatMap(this.options, 'value'))
      },

      hasPills() {
        return !_.isEmpty(this.filters)
      },

      /*
      me() {
        return this.$store.getters['me/profile']
      }
      */
    },

    methods: {
      truncateText(q){
        if (Number.isInteger(q) || typeof q === 'boolean' || typeof q === 'undefined')
          return q

        let ret = q.substring(0, 60).trim()
        if (q.length > 60) ret += '…'
        return ret
      },

      autocomplete: _.debounce(function(q) {
        var filters = this.filters
        if (_.isEmpty(_.trim(q))) return this.options = []
        
        this.$store.dispatch(`${ this.namespace }/autocomplete`, {
          data    : { matter_id: this.matterId },
          options : { params : { q, filters, document_id: this.documentId } }
        })
        .then(resp => {
          // filter out Related Profiles and Witnesses from the Entry itself
          let response = _.filter(resp.body, row => {
            let addRow = true
            _.forEach(row.value, function(val) {
              if (val.field == 'profiles.name.raw'|| val.field == 'profiles.witnesses')
                addRow = false
            })
            return addRow
          })
          
          this.options = _.concat([{
            label: this.namespace == 'annotations' ? 'Excerpt' : 'Full Text',
            value: [{
              id       : this.namespace == 'annotations' ? `excerpt|${q}` : `text|${q}`,
              operator : 'AND',
              field    : this.namespace == 'annotations' ? 'excerpt' : 'text.*',
              key      : q,
              text     : q
            }]
          }], response)

          if (_.isEmpty(this.options)){
            this.options = [{
              label: 'No suggested searches available for this query',
              value: [{id: q, key: q, text: 'No Results Match Your Search Criteria', operator: 'OR', field: 'noresults'}]
            }]
          }
        })
      }, 300),

      setSearchQuery(e) { // Not seeing this called from anywhere?
        if (_.isEqual(e.keyCode, 13))
          this.$store.commit(`${ this.namespace }/setQuery`, e.target.value)
      },

      toggleSearch() {
        if (!this.tokenSearch && !_.isEmpty(this.query))
          return this.showSearchWarning = true

        this.$store.commit(`${ this.namespace }/clearFilter`)
        this.tokenSearch = !this.tokenSearch
      },

      select(q) {
        if (_.isEmpty(_.trim(q))) return
        this.$store.commit(`${ this.namespace }/setFilter`, q)
        this.options = []
      },

      remove(q) {
        if (_.isEmpty(_.trim(q))) return
        this.$store.commit(`${ this.namespace }/delFilter`, q)
        this.options = []
      },

      reset() {
        this.$store.commit(`${ this.namespace }/clearFilter`)
        this.$store.commit(`${ this.namespace }/clearQuery`)
        this.showSearchWarning = false
        this.tokenSearch = true
      },

      icon(opt){
        return opt.icon ? opt.icon : this.icons[opt.field]
      },

      filterText(opt){
        let returnText = opt.text || opt.key
        if (opt.field == 'import_id') {
          returnText = `Import ID: ${_.padStart(opt.key, 4, '0')}`
        } else if (opt.field == 'via') {
          returnText = `Source: ${_.capitalize(opt.text)}`
        }
        return returnText
      },

      tidyUpText(str, field) { // Initial Cap Names, but not email addresses
        if (str.includes('@') || field == 'name.raw' || field == 'tags.name.raw')
          return str
        var split = str.split(" ")
        var tidy = split.map(word => word[0] ? word[0].toUpperCase() + word.slice(1, word.length) : word )
        return tidy.join(" ")
      },

      setSearchFocus(){
        this.shouldFocus = true
      },

      searchFocus(){
        setTimeout(() => {
          if (this.$refs.advSearch) {
            this.$refs.advSearch.focus()
          } else {
            this.$refs.search.$el.focus()
          }
          this.shouldFocus = false
        }, 50)
      }
    },

    watch: {
      disabled(){
        if (this.shouldFocus && !this.loading && !this.disabled) {
          this.searchFocus()
          this.shouldFocus = false
        }
      },

      loading(){
        if (this.shouldFocus && !this.loading && !this.disabled) {
          this.searchFocus()
          this.shouldFocus = false
        }
      },

      documentId(n, old){
        if (n != old)
          this.reset()
      }
    },

    mounted() {
      this.$bus.$on('searchFocus', this.setSearchFocus)
      this.$bus.$on('clearMarkFilters', this.reset)
      Mousetrap.bind('command+shift+f', (e) => {
        this.searchFocus()
      })
    },

    beforeUnmount() {
      this.$bus.$off('searchFocus', this.setSearchFocus)
      this.$bus.$off('clearMarkFilters', this.reset)
    }
  }
</script>

<style>
  .multiselect--notempty input::-webkit-input-placeholder, /* Chrome/Opera/Safari */
  .multiselect--notempty input::-moz-placeholder, /* Firefox 19+ */
  .multiselect--notempty input:-ms-input-placeholder, /* IE 10+ */
  .multiselect--notempty input:-moz-placeholder { /* Firefox 18- */
    height:0; width:0;
  }
  .noresults {
    display: none;
  }
  @media only screen and (max-width: 1460px) {
    div[data-test='annotation-search-wrap'] .multiselect.search-standalone .multiselect__placeholder {
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 1400px) {
    div[data-test='annotation-search-wrap'] .multiselect.search-standalone .multiselect__placeholder {
      font-size: 12px;
      white-space: nowrap;
    }
  }
</style>
