.ecs-modal[data-v-661fbe3d] {
  z-index: 1071;
}
.footer-button-bar .ecs-form-check[data-v-661fbe3d] {
  margin: 0 0 0 8px;
}
.footer-stats[data-v-661fbe3d] {
  font-size: var(--type-scale-2-font-size);
  line-height: 24px;
  color: var(--color-gray-10);
}
.footer-stats b[data-v-661fbe3d] {
    font-weight: var(--font-weight-medium);
}
.footer-breadcrumb[data-v-661fbe3d] {
  padding: 0 14px;
  font-size: var(--type-scale-2-font-size);
  color: var(--color-gray-9);
}
.footer-breadcrumb > span[data-v-661fbe3d] {
    flex-shrink: 0;
}
.folder-path[data-v-661fbe3d] {
  min-width: 0;
}
.folder-path span[data-v-661fbe3d] {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.folder-path span[data-v-661fbe3d]:before {
      content: "/";
      color: var(--color-gray-5);
      margin-right: var(--spacing-5);
}
.folder-path .current[data-v-661fbe3d] {
    flex-shrink: 0;
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-12);
}
