.set-icon[data-v-73a7bc50] {
  margin-right: var(--spacing-5);
}
.color-dot[data-v-73a7bc50] {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: var(--spacing-15);
  flex-shrink: 0;
}
.admin-badge[data-v-73a7bc50] {
  display: inline-flex;
  margin-left: var(--spacing-5);
}
