.color-select .multiselect__option,
.color-select .multiselect__single {
  display: flex;
  align-items: center;
}
.color-select .option-color {
  width: 14px;
  height: 14px;
  margin-right: 15px;
  border-radius: 100%;
}
