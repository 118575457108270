.indent-spacer[data-v-2181eb4e] {
  height: 1px;
  flex-shrink: 0;
  width: 28px;
}
.button-on-hover > .ecs-button[data-v-2181eb4e] {
  opacity: 0;
  margin: -2px 0 0 0;
}
.button-on-hover:hover > .ecs-button[data-v-2181eb4e] {
  opacity: 1;
}
.break-word[data-v-2181eb4e] {
  word-break: break-all;
}
.dialog-data-list[data-v-2181eb4e] {
  text-align: left;
  background: var(--color-white);
  box-shadow: 0px 0px 0px 1px rgba(71, 75, 96, 0.05), 0px 1px 4px 0px rgba(71, 75, 96, 0.1);
  border-radius: 6px;
  padding: 4px 12px;
  margin: 12px -12px -12px -12px !important;
}
.row-header[data-v-2181eb4e] {
  cursor: pointer;
}
