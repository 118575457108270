.toolbar-search {
  display: flex;
  height: 60px;
  align-items: center;
  overflow: auto;
  flex: 1;
}
.toolbar-search-results {
    padding-left: 10px;
    font-size: 12px;
    text-align: right;
}
.toolbar-search-results > div {
      padding: 4px;
      border-radius: 3px;
}
.toolbar-search-results .count {
      font-weight: 500;
}
.toolbar-search-results .type {
      color: #858E9E;
}
.toolbar-search-results .runtime {
      margin-top: 2px;
}
.toolbar-search-results .warning {
      background: var(--color-yellow-3);
      color: var(--color-yellow-13);
      cursor: help;
}
.toolbar-search-results .warning .type {
        color: var(--color-yellow-11);
}
.toolbar-search-advanced {
    font-size: 12px;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    min-height: 18px;
    margin-top: 4px;
}
.toolbar-search-advanced::-webkit-input-placeholder {
      font-weight: 300;
      font-size: 16px;
      font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif, "Apple Color Emoji";
}
body.platform-win32 .toolbar-search-advanced {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
}
body.platform-win32 .toolbar-search-advanced::-webkit-input-placeholder {
    font-family: "Segoe UI", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
}
.multiselect--notempty input::-webkit-input-placeholder,
.multiselect--notempty input::-moz-placeholder,
.multiselect--notempty input:-ms-input-placeholder,
.multiselect--notempty input:-moz-placeholder {
  height: 0;
  width: 0;
}
.noresults {
  display: none;
}
