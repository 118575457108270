.tag-name[data-v-086884e4] {
  line-height: 20px;
  padding: 0 var(--spacing-10);
}
.favorability-count[data-v-086884e4] {
  font-size: var(--type-scale-3-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--type-scale-3-line-height);
  font-variant-numeric: tabular-nums;
  text-align: right;
}
.type-label[data-v-086884e4] {
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
  color: var(--color-gray-12);
}
.percentage-label[data-v-086884e4] {
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  text-align: right;
  font-variant-numeric: tabular-nums;
  color: var(--color-gray-9);
  width: 44px;
}
