.matter-sidebar[data-v-06fdf0e1] {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.matter-sidebar-header[data-v-06fdf0e1] {
  padding: var(--spacing-15) var(--spacing-15) var(--spacing-10) var(--spacing-15);
  position: relative;
  flex-shrink: 0;
}
.matter-list[data-v-06fdf0e1] {
  height: 100%;
  overflow-y: auto;
  flex-grow: 1;
  padding: var(--spacing-5) 0;
}
