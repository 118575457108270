.date-label[data-v-04fc3414] {
  min-width: 80px;
  text-align: right;
  color: var(--color-gray-9);
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
}
.inner-link[data-v-04fc3414] {
  flex: 1;
}
