<template>
  <div v-if="headline" class="px-5 mb-5">
    <ecs-text-v2 preset="subline">Headline</ecs-text-v2>
    <ecs-formatted :loading="fetching" :skeleton-count="1" :class="['headline mt-1', { ecai: isFieldAiGenerated('headline') }]">
      <div v-html="headline" />
    </ecs-formatted>
  </div>
</template>

<script>
  import EcViewerFieldsMixin from 'ec-common/components/viewer/mixins/viewer-fields.js'
  import EcidToLink          from 'ec-common/helpers/ecid-to-link'

  export default {
    mixins: [EcViewerFieldsMixin],

    props: {
      isAce : Boolean,
      matterId : Number
    },

    computed: {
      headline() {
        if (!this.entry || !this.entry.headline) return ''
        const headline = this.isAce ? this.entry.document.headline : this.entry.headline
        return EcidToLink(headline, this.matterId, 'viewer', '#analysis-info', '_self')
      }
    }
  }
</script>

<style scoped>
  .headline {
    font-weight: var(--font-weight-medium);
  }

  .ecai{
    color: var(--color-purple-14);
  }
</style>
