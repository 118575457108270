.separator[data-v-78b4c31e] {
  height: 24px;
  width: 1px;
  border-radius: 1px;
  background-color: #ECEDF2;
  margin: 0 4px;
}
.cites-card-title[data-v-78b4c31e] {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.cites-card-title .icon[data-v-78b4c31e] {
    margin-right: 4px;
}
.cites-card-title > span[data-v-78b4c31e] {
    display: flex;
    align-items: center;
}
.cites-card-title[data-v-78b4c31e]:hover {
    text-decoration: underline;
}
.scan-button-icon[data-v-78b4c31e] {
  position: absolute;
  border-radius: 100%;
  background: #FFF;
  bottom: 6px;
  right: 5px;
}
.search-hint[data-v-78b4c31e] {
  padding: 12px 16px;
  color: #858E9E;
  font-size: 12px;
}
.search-hint i[data-v-78b4c31e] {
    cursor: pointer;
    font-weight: 500;
    color: #0B71EB;
    font-style: normal;
}
