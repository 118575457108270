.locked-column[data-v-7594eec4] {
  color: var(--color-gray-10);
  cursor: not-allowed;
}
.column-item .show-on-hover[data-v-7594eec4] {
  opacity: 0;
}
.column-item:hover .show-on-hover[data-v-7594eec4] {
  opacity: 1;
}
.drag-lists[data-v-7594eec4] {
  flex: 1;
  padding-bottom: var(--spacing-20);
}
.drag-lists .ecs-tree-view-list[data-v-7594eec4] {
    flex: 1;
}
.drag-lists .tab-fields[data-v-7594eec4],
  .drag-lists .drag-list[data-v-7594eec4] {
    width: 100%;
    height: 100%;
}
.drag-lists .drag-list.has-locked[data-v-7594eec4] {
    height: calc(100% - 60px);
}
.drag-list-wrap[data-v-7594eec4] {
  flex: 1;
}
.drag-list-wrap > .ecs-tree-view-list[data-v-7594eec4] {
    width: 100%;
    flex: 0;
}
.drag-list > div[data-v-7594eec4] {
  border-radius: var(--border-radius-small);
  cursor: grab;
}
.drag-list [draggable=true][data-v-7594eec4] {
  background: #FFF;
  cursor: grabbing;
}
.drag-list .sortable-ghost[data-v-7594eec4] {
  background: var(--color-blue-3);
  color: var(--color-blue-15);
  opacity: .6;
}
.search-column > *[data-v-7594eec4] {
  flex: 1;
}
.no-results[data-v-7594eec4] {
  flex: 1;
}
