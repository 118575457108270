.copy-from-matter[data-v-84507948] {
  padding: 20px 0 0 52px;
  margin-bottom: var(--spacing-25);
}
.copy-from-matter > .ecs-form-group[data-v-84507948] {
    max-width: 488px;
}
.indent[data-v-84507948] {
  padding: 0 0 20px 30px;
  max-width: 488px;
}
