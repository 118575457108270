<template>
  <section class="ecs-viewer-canvas">
    <ecs-empty-state v-if="rederNativeFile" type="custom">
      <div class="unsupported-file-icon">
        <ecs-illustration type="plain-document" />
        <ecs-file-icon :type="fileType" :width="38" :height="38" />
      </div>
      <ecs-flex-row justify="center">
        <ecs-text-v2 preset="headline-4">Native file available</ecs-text-v2>
        <ecs-info-tooltip v-if="matterPermissions['exporting_downloading_data-perform_individual_downloads']" v-ec-tooltip="{ content: 'This file cannot be displayed. Click the link below to download it.'}" />
      </ecs-flex-row>
      <ecs-text-v2 preset="subline" class="mt-2">{{ entry.filename }} ({{ fileSizeInMB }})</ecs-text-v2>

      <template v-slot:actions>
        <ecs-button v-if="matterPermissions['exporting_downloading_data-perform_individual_downloads']" icon="download" :href="entry.download_url" class="mt-2">Download File</ecs-button>
      </template>
    </ecs-empty-state>

    <ecs-empty-state v-else-if="processing" type="empty-file-upload">
      This document is processing and will <span class="nowrap">be available shortly.</span>
      <template v-slot:actions v-if="matterPermissions['exporting_downloading_data-perform_individual_downloads']">
        <ecs-button :href="entry.download_url" icon="download">Download File</ecs-button>
      </template>
    </ecs-empty-state>

    <ecs-empty-state v-else-if="!viewerReady || uploading || fetchingWhileEmpty" type="loading" data-test="document-loader" />

    <div v-else-if="viewerError" class="viewer-error">
      <ecs-text-v2 preset="headline-2" color="negative">An error occurred while rendering this document.</ecs-text-v2>
      <p class="viewer-error-text">Refresh the page or download the native file to view the document.
        <template v-if="showIntercom">To help us track down the issue, please click below to send an error report.</template>
      </p>
      <div class="d-flex justify-content-between">
        <code class="mr-4">
          <pre class="viewer-error-code"></pre>
        </code>
        <ecs-button v-if="showIntercom" @click="openIntercom" type="danger">Send</ecs-button>
      </div>
    </div>

    <div id="document-viewer" class="page-wrapper" data-test="document-viewer">
      <div id="selectionBoxContainer" style="display: none;"></div>
      <div id="viewer" class="pages"></div>
      <ecs-flex-row id="viewer-inline-controls" justify="between">
        <ecs-flex-row :gap="8" class="floating-bar">
          <ecs-button @click="$emit('zoomIn', prizm.getScaleFactor())" :disabled="zoom == '2'" type="secondary" icon="add-plus" icon-only size="sml" aria-label="Zoom in" />
          <ecs-separator type="vertical" width="1px" height="8px" />
          <ecs-select-text :value="zoom" @change="$emit('setZoom', $event.target.value);blurPaneZoom()" size="sml" id="paneZoomSelect">
            <option value="page-width">Fit</option>
            <option value="0.5">50%</option>
            <option value="0.75">75%</option>
            <option value="1">100%</option>
            <option value="1.25">125%</option>
            <option value="1.5">150%</option>
            <option value="1.75">175%</option>
            <option value="2">200%</option>
          </ecs-select-text>
          <ecs-separator type="vertical" width="1px" height="8px" />
          <ecs-button @click="$emit('zoomOut', prizm.getScaleFactor())" :disabled="zoom == '.5'" type="secondary" icon="minus-plain" icon-only size="sml" aria-label="Zoom out" />
        </ecs-flex-row>

        <ecs-flex-row :gap="4" class="floating-bar page-count">
          <ecs-text-v2 preset="label-2" weight="medium" color="var(--color-gray-12)">{{ currentPage }}</ecs-text-v2>
          <ecs-text-v2 preset="label-2" color="var(--color-gray-9)">/{{ pageCount }}</ecs-text-v2>
        </ecs-flex-row>

        <ecs-flex-row :gap="8" class="floating-bar">
          <ecs-button @click="$emit('setRotation', -90)" type="secondary" icon="rotate-ccw" icon-only size="sml" aria-label="Rotate counter clock wise" />
          <ecs-separator type="vertical" width="1px" height="8px" />
          <ecs-button @click="$emit('setRotation', 90)" type="secondary" icon="rotate-cw" icon-only size="sml" aria-label="Rotate clock wise" />
        </ecs-flex-row>
      </ecs-flex-row>
    </div>

  </section>
</template>

<script>
  import PrizmMarkupLayers from 'ec-common/components/viewer/mixins/markup-layers'
  import AnnotationsHelper from 'ec-common/helpers/annotations'
  import PrizmLayerHelper  from 'ec-common/helpers/prizm-layer'
  import Mousetrap         from 'mousetrap'
  import EcSelectionBox    from 'ec-common/components/viewer/tabs/cites/selection-box.vue'
  import PrizmSelectionBox from 'ec-common/helpers/prizm-selection-box'
  import FileTypes         from 'ec-common/helpers/file-types'
  import { fileInfoMixin } from './mixins/file-info'
  import { createApp }     from 'vue'
  import _ from 'lodash'

  export default {
    components: { AnnotationsHelper, EcSelectionBox },
    mixins: [PrizmMarkupLayers, fileInfoMixin],
    emits: ['selectMark', 'setPages', 'setRotation', 'setZoom', 'zoomIn', 'zoomOut', 'setMarks', 'setTab', 'resetCite', 'stripQuerystrings', 'setMarkColor', 'setAccusoftTool', 'setRenderNative', 'setPasswordProtected', 'setNewAnn', 'selectMark', 'fetchMarkIds', 'isSearching', 'noResults', 'searched', 'stringQuerystrings', 'selectCite', 'resetCite', 'addCite', 'highlightCopied', 'editCite'],

    props: {
      me                   : Object,
      zoom                 : String,
      entry                : Object,
      scope                : String,
      search               : String,
      layers               : Array,
      tabSize              : String,
      rotation             : Number,
      matterId             : Number,
      fetching             : Boolean,
      activeTab            : String,
      uploading            : Boolean,
      searching            : Boolean,
      pageCount            : Number,
      currentPage          : Number,
      firstRelevantPage    : Number,
      searchIndex          : Number,
      searchClear          : Boolean,
      searchResults        : Array,
      accusoftTool         : String,
      annotationsToolbar   : Boolean,
      alwaysShowAnnotations : Boolean,
      textSelectionEnabled : Boolean,
      rawActiveMark        : Object,
      refreshNav           : Boolean,
      prizmEvent           : Object,
      markColor            : String,
      newCite              : Boolean,
      activeCite           : Object,
      activeCiteId         : [Number, String],
      citesToTabSelected   : Boolean,
      amInViewer           : Boolean,
      sidebarExpanded      : Boolean,
      paneSizeChanged      : Boolean,
      documentId           : Number,
      matterPermissions: { type: Object, default() { return {} }}
    },

    data() {
      return {
        prizm             : null,
        viewMode          : false,
        ignorePage        : false,
        processing        : false, // processing is never set to true
        isScrolling       : false,
        markupVisible     : false,
        viewingSessionId  : null,
        selectedMarks     : null,
        fetchedEntry      : null,
        viewerError       : null,
        broadcaster       : null,
        uploadUrl         : null,
        searchesAttempted : 0,
        searchRetriesMax  : 9,
        selectionBox      : null,
        initSelectionBox  : false,
        tempRawCite       : null,
        selectBoxApp      : null,
        filtersTriggered  : false
      }
    },

    computed: {
      imageHandlerUrl(){
        return this.$store.getters['viewer/imageHandlerUrl']
      },

      fetchingWhileEmpty(){
        return this.fetching && _.isEmpty(this.entry)
      },

      featureFlags(){
        return this.$store.getters['organization/get'].features
      },

      showIntercom(){
        return this.isOrgAdmin || (!this.featureFlags || !this.featureFlags['intercom_admin_only'])
      },

      isOrgAdmin(){
        return this.$store.getters['me/isOrgAdmin']
      },

      showAnnotations(){
        return this.alwaysShowAnnotations || this.annotationsToolbar
      },

      allIds() {
        return this.$store.getters['annotations/allIds']
      },

      filters() {
        return this.$store.getters['annotations/filters']
      },

      viewerReady(){
        return this.$store.getters['viewer/viewerReady']
      },

      extension(){
        if (!this.entry || !this.entry.filename) { return null }
        return (/(?:\.([^.]+))?$/.exec(this.entry.filename)[1] || '').toLowerCase()
      },

      rederNativeFile(){
       if (this.amInViewer && !this.viewerReady)
         return false
        const mimeType = this.entry && this.entry.mime_type
        if (FileTypes.documentViewerExtensions.includes(this.extension) || FileTypes.audioPlayerMimeTypes.includes(mimeType) || FileTypes.videoPlayerMimeTypes.includes(mimeType))
          return false
        return true
      }
    },

    methods: {
      initialize(entry){
        if (typeof PCCViewer !== 'object' || PCCViewer == null)
          return setTimeout(() => { this.initialize(entry) }, 405)

        if (entry.has_file){
          this.selectionBox = null
          this.$store.commit('viewer/viewerReady', false)
          const data = {
            id         : entry.is_translation ? entry.id : entry.document_id || entry.id,
            matter_id   : entry.matter_id || this.matterId,
            file_path    : entry.file_path,
            isDocument    : this.scope == 'documents',
            is_translation : entry.is_translation || false
          }
          this.$store.dispatch('viewer/requestViewingSession', data)
          .then(resp => {
            if (!resp){
              this.$store.commit('viewer/viewerReady', true)
              if (this.prizm) this.prizm.destroy()
            }
            else {
              this.fetchedEntry = entry.id
              this.viewingSessionId = resp.body.viewingSessionId
              
              window.ecViewer.ecPrizm.prizm = this.prizm = new PCCViewer.ViewerControl(document.getElementById('viewer'), {
                imageHandlerUrl : this.imageHandlerUrl,
                markupVisible   : this.markupVisible,
                documentID      : this.viewingSessionId,
                viewMode        : 'Document',
                searchMethodPageCountThreshold : 5
              })

              window.addEventListener('resize', this.resizeHandler)

              new PCCViewer.Event(this.prizm, 'MarkupModeToggled')

              this.prizm.on('MarkupModeToggled', ({ modeEnabled }) => {
                if (modeEnabled && location.hash.includes('-form'))
                  this.$emit('setTab', location.hash.replace('-form', '-info'))
                this._refreshNav()
              })

              Mousetrap.bind('ctrl', () => {
                this.prizm.setCurrentMouseTool('AccusoftPanAndEdit')
              }, 'keydown')

              Mousetrap.bind('ctrl', () => {
                this.prizm.setCurrentMouseTool('AccusoftSelectText')
              }, 'keyup')

              Mousetrap.bind('mod+down', () => this.handleNextPage())
              Mousetrap.bind('mod+up', () => this.handlePrevPage())

              if (this.amInViewer)
                this.handleMarkColorChanged() // fix #10202

              this.prizm.on('Click', (event) => {
                const isModifierKey = event.originalEvent && event.originalEvent.ctrlKey
                const isHyperlink   = event.targetType === 'documentHyperlink'
                const isTextHyperlinkAnnotation = event.targetType == 'page' || _.isNil(event.mark) ? false : (event.targetType === 'mark' && event.mark.type === 'TextHyperlinkAnnotation') || (event.mark.type === 'TextHyperlinkAnnotation' && event.type == 'markcreated')

                if (isHyperlink && isModifierKey) {
                  if (event.hyperlink.href.startsWith('http')) {
                    this.prizm.setCurrentMouseTool('AccusoftSelectText')
                    return window.open(event.hyperlink.href)
                  }
                }

                if (isTextHyperlinkAnnotation) {
                  this.prizm.deselectMarks([event.mark])
                  if (event.type == 'markcreated' && !event.clientX) return
                  if (this.activeTab == 'cites' && this.matterPermissions['viewer_documents-access_to_cites_tag-manage']){
                    this.setTempRawCiteData(event.mark)
                    this.setSelectionBox(event.mark)
                    return
                  }
                  else {
                    this.prizm.setCurrentMouseTool('AccusoftSelectText')
                    return window.open(`/matters/${ this.matterId }/viewer/document/${ event.mark.getData('document_id') }#analysis-info`)
                  }
                }

                const oldC = this.prizm.getSelectedConversation()
                if (oldC) { oldC.setSessionData('active', undefined) }
                this.prizm.setSelectedConversation(null)
                this.prizm.deselectAllMarks()

                if (event.mark) {
                  const conversation = event.mark.getConversation()
                  conversation.setSessionData('active', 'yes')
                  this.prizm.setSelectedConversation(conversation)
                  if (this.activeTab == 'annotations' && this.matterPermissions['viewer_documents-access_to_annotations_tag-manage']) {
                    this.prizm.selectMarks([event.mark])
                    this.$emit('selectMark', event.mark.uid)
                  }
                }
              })

              this.prizm.on('ViewerReady', (event) => {
                this.setZoom()
                this.$store.commit('viewer/viewerReady', true)
                this.$emit('setRenderNative', false)
                this.$emit('setPasswordProtected', false)
                this.initLayers()
                this.prizm.setCurrentMouseTool('AccusoftSelectText')
                this.prizm.fitType = 'FullWidth'
                if (this.search && this.search != '')
                  setTimeout(() => { this.performSearch() }, 350)
                if (this.$route.query.np && this.$route.query.np == 'true' && this.entry.type == 'annotation_evidence'){
                  setTimeout(() => {
                    this.prizm.setPageNumber(this.entry.annotation.page_start)
                    this.$emit('setPages', { currentPage: this.entry.annotation.page_start })
                  }, 1110)
                }
              })

              this.prizm.on('PageCountReady', () => {
                this.setRotation()
                try {
                  this.entry.pageCount = this.prizm.pageCount
                } catch(err){
                  console.error('set pageCount error', err)
                }
                const currentPage = this.prizm.getPageNumber()
                const info = { firstPage: 1, lastPage: this.prizm.pageCount }
                this.$emit('setPages', { currentPage, pageCount: this.prizm.pageCount, info })

                if (!_.isNil(this.firstRelevantPage) && this.firstRelevantPage > 0 && this.firstRelevantPage <= info.lastPage)
                  this.setPage(this.firstRelevantPage)

                this.setQuerystrings()
              })

              this.prizm.on('PageChanged', () => {
                if (this.ignorePage == true) { return this.ignorePage = false }
                this.$emit('setPages', { currentPage: this.prizm.getPageNumber() })
              })

              this.prizm.on('ScaleChanged', () => {
                this.prizm.setPageNumber(this.currentPage)
                this.prizm.refreshConversations()
              })

              this.prizm.on('PageLoadFailed', (e) => {
                this.processing = false
                this.$store.commit('viewer/viewerReady', true)

                this.$emit('setRenderNative', true)
                this.prizm.destroy()

                if (e.statusCode == 480){
                  this.$emit('setPasswordProtected', true)
                }
                else {
                  this.$emit('setPasswordProtected', false)
                  this.viewerError = `
An error occurred within the Everchron document viewer with the following details:
${ location.href }
Message:  ${ e.errorMessage }
status:   ${ e.statusCode }
Date:     ${ new Date() }
`
                }
              })

              $('.pccPageListContainerWrapper :contains(\'Prizm\')').last().hide()

              this.prizm.saveUserMarkupLayer = _.debounce(layr => {
                this.$store.commit('viewer/savingPrizmLayer', true)
                const layer = !_.isObject(layr) ? this.prizm.getActiveMarkupLayer() : layr
                const that = this
                this.prizm.setActiveMarkupLayer(layer)
                let marks = layer.getMarks()
                marks.map(m => { m.interactionMode = 'Full' })

                setTimeout(() => { this.$emit('fetchMarkIds') }, 800) // for #13207 may want to comment this line out, but seems ok leaving in for now

                this.prizm.saveMarkupLayer(layer.getId()).then(
                  function onSuccess(layerInfo){
                    let idx = -1
                    if (that.layers && that.layers.length > 0)
                      idx = _.findIndex(that.layers, l => l.id === '*')

                    if (idx == -1)
                      idx = _.findIndex(that.layers, l => l.id === layerInfo.layerRecordId)

                    let comments = []
                    if (idx >= 0 && that.layers[idx] && that.layers[idx].marks)
                      comments = _.map(that.layers[idx].marks, m => ({ uid: m.uid, comments: m.annotation.comments }))

                    const converted_layer = PrizmLayerHelper.convertFromPrizmLayerRaw(layer, layerInfo, comments)

                    that.$store.commit('viewer/layer', { idx, layer: converted_layer })
                    that.$store.commit('viewer/savingPrizmLayer', false)
                    that.refreshExcerptView()
                  },
                  function onFailure(reason){
                    console.error('Document Annotation Layer failed to save:', reason.code, reason.message)
                    that.$store.commit('notifications/add', { isError: true, message: 'There was a problem saving the annotation.' })
                    that.$store.commit('viewer/savingPrizmLayer', false)
                  }
                )
              }, 500)

              this.prizm.on('MarkSelectionChanged', (event) => {
                this.selectedMarks = this.prizm.getSelectedMarks()
                _.delay(() => {
                  if (this.selectedMarks.length > 1 ) { return }
                  if (_.isEmpty(this.selectedMarks)) { return }

                  const color = this.selectedMarks[0].getFillColor()
                  if (this.selectedMarks[0].getType() != 'TextHyperlinkAnnotation')
                    this.$emit('setMarkColor', color)
                }, 100)
              })

              this.prizm.on('MarkupModeToggled', (event) => {
                this.handleMarkupModeToggled(event)
              })

              this.prizm.on('MarkChanged', _.debounce((event) => {
                let v = event.getTarget()
                // let c = v.getMarkupLayerCollection()
                // let layers = c.getAll()
                let skippedEvents = ['visible', 'opacity', 'interactionMode']
                if (event.mark.text && event.mark.text.length < 2){
                  this.updateLayers(event)
                  const layer = this.prizm.getActiveMarkupLayer()
                  const marks = layer.getMarks()
                  this._handleMarkupLayerChanged({ type: 'markremoved', mark_id: event.mark.uid, marks })
                  if (!this.annotationTipShown && this.activeTab != 'cites'){
                    this.$store.commit('notifications/add', { message: 'Pro tip: Select at least 2 characters to create an annotation' })
                    this.annotationTipShown = true
                  }
                }
                else if (this._layersReady && _.indexOf(skippedEvents, event.propertyName, 0) === -1)
                  this.updateLayers(event)
                  this._handleMarkupLayerChanged(event)
              }, 300))

              this.prizm.on('MarkRemoved', _.debounce((event) => {
                this._refreshNav()
              }, 300))

              this.prizm.on('MarkCreated', _.debounce((event) => {
                this.updateLayers(event)
                if (event.mark.fillColor == '#FB0404'){ // Ticket #10202
                  const colorLs = localStorage.getItem('annotation-color')
                  const color = !_.isNaN(colorLs) ? '#F9DF00' : colorLs
                  event.mark.fillColor = color
                  event.mark.borderColor = color
                  event.mark.borderThickness = 1
                  event.mark.opacity = 128
                }
                if (this._layersReady) {
                  let addNewMark = true
                  _.each(this.layers, l => {
                    if (_.some(l.marks, ['uid', event.mark.uid]))
                      addNewMark = false
                  })
                  if (addNewMark && ((event.mark.text && event.mark.text.length > 1) || !event.mark.text)){
                    this.$emit('setNewAnn', true)
                    this.$emit('selectMark', event.mark.uid)
                    event.mark.setData("tz_offset", moment().format('Z'))
                    this.prizm.selectMarks([event.mark])
                    if (this.activeTab == 'cites')
                      this.prizm.trigger('Click', event)
                    this._handleMarkupLayerChanged(event)
                  }
                }
              }, 300))

              this.prizm.on('click', _.debounce((e) => {
                if (e && e.originalEvent && e.originalEvent.target && e.originalEvent.target.parentElement
                && e.originalEvent.target.parentElement.closest('svg')
                && e.originalEvent.target.parentElement.closest('svg').id == 'select_box'){
                  return
                }
                this._refreshNav()
              }, 100))

              if (this.activeTab == 'annotations')
                this._refreshNav()
            }
          })
          .catch((err) => {
            if (err.status == 460 || err.status == 461) {
              this.$store.commit('viewer/viewerReady', true)
              this.processing = true
            } else {
              this.$emit('setRenderNative', true)
            }
          })
        }
        else {
          if (!_.isEmpty(this.prizm))
            this.prizm.destroy()
        }
      },

      refreshExcerptView(){
        if (_.isNil(this.broadcaster))
          this.broadcaster = new BroadcastChannel('app-channel')
        this.broadcaster.postMessage({ refreshIndex: 'annotations' })
      },

      updateLayers(event){
        let v = event.getTarget()
        let c = v.getMarkupLayerCollection()
        let layers = c.getAll()
        this.makeLayerLookupTable(layers)
        let activeLayerRecordId = this.matterId+'_'+this.entry.id+'_'+this.me.id
        if ('undefined' != typeof(this.layerLookupTable[activeLayerRecordId])){
          let layer = this.layerLookupTable[activeLayerRecordId]
          this.prizm.setActiveMarkupLayer(layer)
        }
        _.each(layers, layer => {
          let marks = layer.getMarks()
          marks.map(m => { m.interactionMode = 'Full' })
        })
      },

      makeLayerLookupTable(layers){
        this.layerLookupTable = {}
        layers.forEach(l => {
          if ('undefined' != typeof(l.recordId)) {
            this.layerLookupTable[l.recordId] = l
          }
        })
      },

      initLayers(){
        this._initMarkupLayerCollection(this.showAnnotations).then(() => {
          this.handleMarkupModeToggled({ modeEnabled: this.annotationsToolbar })
        })
      },

      findPrizmLayerFromMarkId(markId){
        let layer, marks
        _.each(this._allLayers, l => {
          if (_.isNil(l)){ return }
          const m = l.getMarks()
          const idx = _.findIndex(m, ['uid', markId])
          if (idx >= 0){
            layer = l
            marks = m
          }
        })
        return { layer, marks }
      },

      editDocAnnotAnalysis({ markId, evidence_id, payload, type, activeTab }){
        const { layer, marks } = this.findPrizmLayerFromMarkId(markId)
        if (!layer || !marks) return // add to avoid error when layer or marks are not found
        const mark = marks.find(m => m.uid == markId)
        let conversation = mark.getConversation()
        conversation.setData('favorability', payload.favorability)
        conversation.setData('tags', _.isArray(payload.tags.length) ? payload.tags.join(',') : payload.tags)
        conversation.setData('evidence_id', evidence_id ? evidence_id.toString() : '')
        conversation.setData('date', payload.date)
        conversation.setData('headline', payload.headline)
        conversation.setData('doc_type', payload.doc_type)
        conversation.setData('summary', payload.summary)
        conversation.setData('entries', '')
        conversation.setData('exhibits', '')
        conversation.setData('profiles', _.isArray(payload.profiles) ? payload.profiles.map((p) => p.id).join(',') : payload.profiles)
        conversation.setData('profiles_raw', _.isArray(payload.profiles) ? JSON.stringify(payload.profiles) : payload.profiles)
        this.prizm.saveUserMarkupLayer(layer)
      },

      openIntercom(){
        Intercom('showNewMessage', this.viewerError)
      },

      setZoom(){
        if (this.zoom && !_.isEmpty(this.prizm) && this.viewerReady){
          if (this.prizm){
            if (this.zoom !== 'page-width') {
              this.prizm.setScaleFactor(this.zoom)
            }
            else {
              this.prizm.fitContent('FullWidth')
            }
            if (this.activeTab == 'cites' && !_.isNil(this.selectionBox)) {
              this.removeSelectionBox()
              setTimeout(() => { this.refreshSelectionBox() }, 100)
            }
          }
        }
      },

      blurPaneZoom(){
        $('#paneZoomSelect').blur()
      },

      setRotation(){
        const lsRotation = localStorage.getItem(`rotate-${ this.matterId }-${ this.documentId }`)
        if (!_.isNil(lsRotation))
          this.prizm.rotateDocument(Number(lsRotation))
      },

      rotate(degs){
        this.prizm.rotateDocument(degs)
        localStorage.setItem(`rotate-${ this.matterId }-${ this.documentId }`, this.prizm.getPageRotation(1))
      },

      handleNextPage() {
        if (this.currentPage < this.pageCount){
          this.prizm.setPageNumber(this.currentPage + 1)
          this.$emit('setPages', { currentPage: this.currentPage + 1 })
        }
      },

      handlePrevPage() {
        if (this.currentPage > 1){
          this.prizm.setPageNumber(this.currentPage - 1)
          this.$emit('setPages', { currentPage: this.currentPage - 1 })
        }
      },

      setPage(page){
        if (this.prizm && page && page > 0 && page <= this.prizm.pageCount)
          this.prizm.setPageNumber(page)
      },

      performSearch: _.debounce(function(e, options = {}) {
        this.$emit('noResults', false)
        this.searchesAttempted++

        if (this.prizm && this.viewerReady){
          this.prizm.clearSearch()
          if (_.isEmpty(this.search) || this.searching)
            return

          const searchQuery = {
            searchTerms: [{
              searchTerm: this.search,
              highlightColor: '#FFFB04'
            }]
          }

          const searchRequest = this.prizm.search(searchQuery)
          window.ecViewer.ecPrizm.searchRequest = searchRequest
          this.$emit('isSearching', true)

          searchRequest.on('SearchCompleted', (e) => {
            this.$emit('isSearching', false)
          })

          searchRequest.on('SearchFailed', (e) => {
            if (this.searchesAttempted < this.searchRetriesMax){
              setTimeout(() => { this.performSearch(e) }, 1250)
            }
            else {
              this.searchesAttempted = 0
              this.$store.commit('notifications/add', { isError: true, message: 'An error occurred performing the search.' })
              this.$emit('noResults', true)
            }
          })

          searchRequest.on('SearchResultsAvailable', (e) => {
            const results = e.completedSearchResults
            try {
              if (_.isEmpty(results)) {
                this.$emit('noResults', true)
              }
              else {
                this.$emit('searched', { results, searchIndex: 0 })
                this.prizm.setSelectedSearchResult(results[this.searchIndex], true)
              }
            }
            catch(err) {
              console.error(err, this.searchIndex)
            }
          })
        }
        else {
          setTimeout(() => { this.performSearch(e) }, 250)
        }
      }, 300),

      nextSearchHit() {
        if (this.isScrolling) { return }
        this.isScrolling = true
        const result = this.searchResults[this.searchIndex]
        this.prizm.scrollToAsync(result).then(
          () => { _.delay(() => this.isScrolling = false, 150) },
          () => { _.delay(() => this.isScrolling = false, 150) }
        )
      },

      prevSearchHit() {
        if (this.isScrolling) { return }
        this.isScrolling = true
        const result = this.searchResults[this.searchIndex]
        this.prizm.scrollToAsync(result).then(
          () => { _.delay(() => this.isScrolling = false, 150) },
          () => { _.delay(() => this.isScrolling = false, 150) }
        )
      },

      handleMarkColorChanged(mark) {
        const templateMark = PCCViewer.MouseTools.getMouseTool(this.accusoftTool).templateMark
        const selectedMarks = this.activeTab == 'cites' ? [] : (this.prizm.getSelectedMarks() || [])
        const markColor = this.activeTab == 'cites' ? '#0B71EB' : this.markColor
        _.each([templateMark].concat(selectedMarks), (mark) => {
          if (mark && mark.setBorderThickness) { mark.setBorderThickness(1) }
          if (mark && mark.setBorderColor)     { mark.setBorderColor(markColor) }
          if (mark && mark.setFillColor)       { mark.setFillColor(markColor) }
          if (mark && mark.setOpacity)         { mark.setOpacity(128) }
        })
      },

      handleMarkupModeToggled(event) {
        if (event && event.modeEnabled) {
          if (this.matterPermissions['viewer_documents-access_to_annotations_tag-manage'])
            this.prizm.setCurrentMouseTool(this.accusoftTool)
          this.$emit('setAccusoftTool', 'AccusoftPanAndEdit') // Clear any existing text selection by entering & leaving highlight mode
          if (this.matterPermissions['viewer_documents-access_to_annotations_tag-manage']) {
            if (this.entry.id && !this.textSelectionEnabled){
              this.$emit('setAccusoftTool', 'AccusoftRectangleAnnotation')
            } else {
              this.$emit('setAccusoftTool', 'AccusoftHighlightAnnotation')
            }
          }
          else {
            this.$emit('setAccusoftTool', 'AccusoftSelectText')
          }
          this._showLayers(true)
        }
        else if (this.activeTab == 'cites' && this.citesToTabSelected && this.textSelectionEnabled){
          if (this.matterPermissions['viewer_documents-access_to_cites_tag-manage'])
            this.$emit('setAccusoftTool', 'AccusoftTextHyperlinkAnnotation')
          else
            this.$emit('setAccusoftTool', 'AccusoftSelectText')
        }
        else {
          this.$emit('setAccusoftTool', 'AccusoftSelectText')
          if (!this.alwaysShowAnnotations)
            this._hideLayers(true)
        }
      },

      resizeHandler(e) {
        if (this.prizm && (!this.amInViewer || this.zoom == 'page-width'))
          this.prizm.fitContent('FullWidth')
        if (this.activeTab == 'cites' && !_.isNil(this.selectionBox)) {
          this.removeSelectionBox()
          setTimeout(() => { this.refreshSelectionBox() }, 100)
        }
      },

      setQuerystrings(){
        if (this.$route.query.page)
          this.setPage(this.$route.query.page)

        if (this.$route.query.markId)
          this.$emit('selectMark', this.$route.query.markId)

        setTimeout(() => { this.$emit('stripQuerystrings', true) }, 1500)
      },

      setTempRawCiteData(mark){
        this.tempRawCite = mark
      },

      setSelectionBox(mark, withoutSelect){
        if (!_.isNil(mark.getData('cite_link_id')) && !withoutSelect)
          this.$emit('selectCite', mark.getData('cite_link_id'))

        const box_scale = PrizmSelectionBox.generateScale(this.prizmMatrixRatio())

        const lineGroups = mark.boundingRectangle
        this.selectionBox = {
          x: lineGroups.x,
          y: lineGroups.y + lineGroups.height,
          scale: {
            x: box_scale[0],
            y: box_scale[1]
          }
        }
        this.initSelectionBox = true
        setTimeout(() => { this.initSelectionBox = false }, 250)

        this.removeSelectionBox()
        if (!_.isNil(mark.getData('cite_link_id')))
          mark.setInteractionMode(PCCViewer.Mark.InteractionMode.SelectionDisabled)
        const that = this
        if (!_.isNil(this.selectBoxApp))
          this.selectBoxApp.unmount()
        this.selectBoxApp = createApp(EcSelectionBox, {
          selectionBox: this.selectionBox,
          isAdd: ((_.isNil(mark.getData('cite_link_id'))) ? true : false),
          onAddCite() { that.addCite() },
          onCopy() { that.copyCite() },
          onOpenCite() { that.openCite() },
          onEditCite() { that.editCite() }
        })
        this.selectBoxApp.mount('#selectionBoxContainer')
        this.$nextTick(() => {
          $('g[data-pcc-mark="mark-'+mark.getId()+'"]').parent().append($('#select_box'))
        })
      },

      resetSelectionBox(){
        this.selectionBox = null
        if (!_.isNil(this.tempRawCite) && this.viewerReady){
          if (!_.isNil(this.tempRawCite.getData('cite_document_id'))) {
            this.prizm.deselectAllMarks()
          }
          else {
            this._handleMarkupLayerChanged({ type: 'markremoved', mark_id: this.tempRawCite.uid })
          }
        }
        this.setTempRawCiteData(null)
        this.resetCite()
      },

      resetCite(){
        this.$emit('resetCite')
      },

      addCite(){
        this.$emit('addCite', {
          internal_id: this.tempRawCite.getId(),
          mark_id : this.tempRawCite.uid,
          start_page: this.tempRawCite.pageNumber,
          start_char: this.tempRawCite.position.startIndex,
          reference: this.tempRawCite.text
        })
        setTimeout(() => {
          if (!_.isNil(this.tempRawCite))
            this.prizm.selectMarks([this.tempRawCite])
        }, 250)
      },

      copyCite(){
        var el = document.createElement('textarea')
        let text = this.tempRawCite.text
        let citation = `pg. ${ this.tempRawCite.pageNumber }`
        const copy = `${ text }\n\n${ citation }`

        el.value = copy.trim()
        el.setAttribute('readonly', '')
        el.style = {display: 'none'}
        document.body.appendChild(el)
        el.select()
        document.execCommand('copy')
        document.body.removeChild(el)
        this.$emit('highlightCopied')
      },

      openCite(){
        window.open(`/matters/${ this.matterId }/viewer/document/${ this.tempRawCite.getData('document_id') }#analysis-info`)
      },

      editCite(){
        this.$emit('editCite', {
          internal_id: this.tempRawCite.getId(),
          mark_id : this.tempRawCite.uid,
          id: parseInt(this.tempRawCite.getData('cite_link_id')),
          cite_document_id: parseInt(this.tempRawCite.getData('cite_document_id')),
          cited_document_id: parseInt(this.tempRawCite.getData('document_id')),
          start_page: this.tempRawCite.pageNumber,
          start_char: this.tempRawCite.position.startIndex,
          reference: this.tempRawCite.text
        })
        setTimeout(() => {
          if (!_.isNil(this.tempRawCite)) {
            this.tempRawCite.setInteractionMode(PCCViewer.Mark.InteractionMode.Full)
            this.prizm.selectMarks([this.tempRawCite])
          }
        }, 250)
      },

      refreshActiveTab(){
        this.selectionBox = null
        if (this.activeTab == 'cites' && this.citesToTabSelected && this.textSelectionEnabled && !_.isEmpty(this.prizm) && this.viewerReady){
          if (this.matterPermissions['viewer_documents-access_to_cites_tag-manage'])
            this.$emit('setAccusoftTool', 'AccusoftTextHyperlinkAnnotation')
          else
            this.$emit('setAccusoftTool', 'AccusoftSelectText')
          this._showLayers(this.showAnnotations)
        }
        else if (this.activeTab != 'annotations' && !_.isEmpty(this.prizm) && this.viewerReady){
          this.prizm.trigger('MarkupModeToggled', { modeEnabled: this.annotationsToolbar })
          this._showLayers(this.showAnnotations)
        }
      },

      jumpToCite(data){
        if (_.isEmpty(data.line_groups))
          return
        this.prizm.scrollTo({
          pageNumber: data.line_groups[0].pageNumber,
          x: data.line_groups[0].lines[0].x,
          y: data.line_groups[0].lines[0].y
        })
      },

      removeSelectionBox(){
        $('#viewer #select_box').remove()
      },

      prizmMatrixRatio(){
        let matrix_data = $("#viewer .igAnchor > svg > g").attr("transform")
        matrix_data = _.trim(matrix_data, "matrix(")
        matrix_data = _.trim(matrix_data, ")")
        return parseFloat(Number(_.split( matrix_data, ',')[0]).toFixed(2))
      },

      refreshSelectionBox(){
        this.setSelectionBox(this.tempRawCite, (this.activeCiteId ? true : false))
      },

      refreshPrizm(){
        this.prizm.setCurrentMouseTool('AccusoftPanAndEdit')
        if (this.amInViewer){
          setTimeout(() => {
            this.prizm.setCurrentMouseTool(this.accusoftTool)
          }, 50)
        }
      },

      mouseWheelHandler(e) {
        if (e.ctrlKey){
          e.preventDefault()
          e.stopPropagation()        
        }
      },

      keyUpHandler(e) {
        if (e.key === 'Control') {
          if (this.activeTab == 'cites')
            this.refreshActiveTab()
          else
            this.handleMarkupModeToggled({ modeEnabled: this.annotationsToolbar })
        }
      }
    },

    watch: {
      entry(entry){
        if ((entry.id != this.fetchedEntry) || !this.entry.has_file)
          this.initialize(entry)
      },

      '$route.query'(){
        this.setQuerystrings()
      },

      currentPage(){
        this.setPage(this.currentPage)
      },

      search(){
        if (!!this.search && this.viewerReady)
          this.performSearch()
      },

      searchIndex(n, o){
        if (n > o){
          if (this.searchResults.length > n)
            this.nextSearchHit()
        }
        else if (o > n) {
          if (o > 0)
            this.prevSearchHit()
        }
        else
          console.info('searchIndex is not being rational today')
      },

      searchClear(bool){
        if (bool)
          this.prizm.clearSearch()
      },

      zoom(){
        this.setZoom()
      },

      viewerReady(b){
        if (b)
          this.setZoom()
      },

      rotation(){
        if (this.rotation != 0)
          this.rotate(this.rotation)
      },

      alwaysShowAnnotations:{
        handler() {
          if (!_.isEmpty(this.prizm) && this.viewerReady)
            this._showLayers(this.showAnnotations)
        }, immediate: true
      },

      annotationsToolbar(){
        if (!_.isEmpty(this.prizm) && this.viewerReady)
          this.prizm.trigger('MarkupModeToggled', { modeEnabled: this.annotationsToolbar })
      },

      tabSize(){
        if (this.zoom == 'page-width')
          setTimeout(() => { this.setZoom() }, 351)
      },

      uploadedUrl(){
        this.uploadUrl = this.uploadedUrl
        this.initialize(entry)
      },

      accusoftTool(){
        if (this.prizm && this.entry.has_file){
          this.prizm.setCurrentMouseTool(this.accusoftTool)
          this.handleMarkColorChanged()
        }
      },

      rawActiveMark(){
        if (!this.matterPermissions['viewer_documents-access_to_annotations_tag-manage'])
          return
        this.prizm.deselectAllMarks()
        if (!_.isNil(this.rawActiveMark)){
          const { layer } = this.findPrizmLayerFromMarkId(this.rawActiveMark.uid)
          if (layer) {
            this.prizm.setActiveMarkupLayer(layer)
            this.prizm.selectMarks([this.rawActiveMark])
          }
        }
      },

      markColor(){
        this.handleMarkColorChanged()
      },

      refreshNav(y){
        if (y)
          this._refreshNav()
      },

      prizmEvent(e){
        if (!_.isEmpty(e)){
          if (e.type == 'refreshPrizm')
            this.refreshPrizm()
          else if (e.propertyName == 'annotationTagCreated')
            this.editDocAnnotAnalysis(e)
          else if (e.type == 'reloadcites')
            this._requestCites()
          else
            this._handleMarkupLayerChanged(e)
        }
      },

      activeTab(){
        this.refreshActiveTab()
      },

      citesToTabSelected(){
        this.refreshActiveTab()
      },

      selectionBox(){
        if (_.isNil(this.selectionBox)){
          this.removeSelectionBox()
        }
      },

      sidebarExpanded(){
        if (this.activeTab == 'cites' && !_.isNil(this.selectionBox)) {
          this.removeSelectionBox()
          setTimeout(() => { this.refreshSelectionBox() }, 500)
        }
      },

      allIds:{
        handler() {
          if (!_.isEmpty(this.prizm) && this.viewerReady && (this.filters.length > 0 || this.filtersTriggered)) {
            this.filtersTriggered = true
            this._showLayers(this.showAnnotations)
          }
        },
        deep: true
      },

      paneSizeChanged(bool){
        if (bool && this.zoom == 'page-width')
          this.resizeHandler()
      }
    },

    created() {
      if (_.isNil(window.ecViewer))
        window.ecViewer = {}
      window.ecViewer.ecPrizm = this

      this.$loadScript('/scripts/viewercontrol.js')
      this.refreshExcerptView = _.debounce(this.refreshExcerptView, 10000, { leading: true, trailing: true })
    },

    mounted() {
      this.$bus.$on('jumpToCite', this.jumpToCite)
      this.$bus.$on('editAnalysis', this.editDocAnnotAnalysis)
      this.uploadUrl = this.uploadedUrl
      if (!_.isEmpty(this.entry))
        this.initialize(this.entry)

      window.addEventListener('keyup', this.keyUpHandler)
      document.getElementById('viewer').addEventListener('wheel', this.mouseWheelHandler, { capture: true, passive: false })    
    },

    beforeUnmount() {
      Mousetrap.unbind('ctrl')
      this.$bus.$off('editAnalysis', this.editDocAnnotAnalysis)
      this.$bus.$off('jumpToCite', this.jumpToCite)

      if (this.prizm)
        this.prizm.destroy()

      if (!_.isNil(this.broadcaster))
        this.broadcaster.close()
      if (!_.isNil(this.selectBoxApp))
        this.selectBoxApp.unmount()

      document.getElementById('viewer').removeEventListener('wheel', this.mouseWheelHandler)    
    },

    unmounted() {
      window.removeEventListener('resize', this.resizeHandler)
      window.removeEventListener('keyup', this.keyUpHandler)
    }
  }
</script>

<style>
  .no-clip-on-print {
    visibility: hidden;
  }
</style>
