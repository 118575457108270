.tag-title[data-v-20b9445c] {
  flex: 1;
}
.tag-title .title[data-v-20b9445c] {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
}
.tag-title .path[data-v-20b9445c] {
    color: var(--color-gray-7);
    margin-right: -6px;
}
.color-dot[data-v-20b9445c] {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
