.popover-list-sticky[data-v-89125778] {
  padding: 0;
}
.popover-list-sticky > li[data-v-89125778]:not(.ecs-popover-list-title) {
    padding: 0 var(--spacing-10);
}
.list-group-item[data-v-89125778] {
  background-color: var(--color-white);
}
.list-group-item-header[data-v-89125778] {
    cursor: grab;
    padding-left: var(--spacing-10) !important;
    padding-right: var(--spacing-10) !important;
}
.list-group-item-header .icon[data-v-89125778] {
      color: var(--color-gray-7);
      transition: .2s;
}
.list-group-item-header:hover .icon[data-v-89125778] {
      color: var(--color-gray-10);
}
.list-group-item.sortable-chosen[data-v-89125778] {
    overflow: hidden;
    border-radius: var(--border-radius-medium);
}
.list-group-item.sortable-chosen .list-group-item-header[data-v-89125778] {
      cursor: grabbing;
}
.list-group-item.sortable-chosen .list-group-item-header .icon[data-v-89125778] {
        color: var(--color-gray-10);
}
.list-group-item.sortable-ghost[data-v-89125778] {
    background-color: var(--color-blue-2);
    opacity: .6;
}
.list-group-item.sortable-ghost .list-group-item-header[data-v-89125778] {
      background-color: var(--color-blue-3);
}
.list-group-item.sortable-ghost .list-group-item-header .icon[data-v-89125778] {
        opacity: 0;
}
.group[data-v-89125778] {
  padding: var(--spacing-5) var(--spacing-10);
}
.spacer[data-v-89125778] {
  width: var(--spacing-25);
  height: var(--spacing-25);
  margin-right: var(--spacing-5);
}
