.color-value[data-v-955d9608] {
  display: flex;
  align-items: center;
}
.color-dot[data-v-955d9608] {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  margin-left: 8px;
}
.color-select .multiselect__option[data-v-955d9608],
.color-select .multiselect__single[data-v-955d9608] {
  display: flex;
  align-items: center;
}
.color-select .option-color[data-v-955d9608] {
  width: 14px;
  height: 14px;
  margin-right: 15px;
  border-radius: 100%;
}
.color-button[data-v-955d9608] {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
  padding: 6px 8px;
  border-radius: var(--border-radius-small);
  z-index: 1;
  position: relative;
}
.color-button[data-v-955d9608]:after {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 0.15s ease-in-out, transform 0.3s cubic-bezier(0.3, 0.76, 0.27, 1);
    border-radius: 4px;
    background: rgba(133, 142, 158, 0.07);
    z-index: -1;
    pointer-events: none;
}
.color-button[data-v-955d9608]:hover:after, .color-button[data-v-955d9608]:active:after, .color-button.active[data-v-955d9608]:after {
    opacity: 1;
    transform: scale(1);
}
.color-button .color[data-v-955d9608] {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 100%;
}
.color-button .color.none-selected[data-v-955d9608] {
      border: 1px dashed var(--color-gray-6);
      background: transparent;
}
