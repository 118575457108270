.segment-height[data-v-fc62ea40] {
  padding: 2px 0;
  flex: 1;
}
.tile[data-v-fc62ea40] {
  background: var(--color-gray-1);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-15) var(--spacing-20);
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  font-weight: var(--font-weight-medium);
}
.tile .stat[data-v-fc62ea40] {
    font-size: var(--type-scale-6-font-size);
    line-height: var(--type-scale-6-line-height);
    color: var(--color-gray-15);
}
.tile .description[data-v-fc62ea40] {
    font-size: var(--type-scale-2-font-size);
    line-height: var(--type-scale-2-line-height);
    color: var(--color-gray-10);
}
