.fma-promotion[data-v-5e0095c9] {
  height: 100%;
  padding: var(--spacing-20);
}
.fma-promotion-background[data-v-5e0095c9] {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 12px;
    overflow: hidden;
    background-color: #F6FBFD;
}
.fma-promotion-image[data-v-5e0095c9] {
    max-width: 50%;
    height: 100%;
    object-fit: cover;
    object-position: right center;
}
.fma-promotion-content[data-v-5e0095c9] {
    max-width: 600px;
    max-height: 100%;
    overflow: auto;
    padding: var(--spacing-20) 0;
    position: relative;
    z-index: 1;
}
.fma-promotion-content[data-v-5e0095c9]:after {
      content: '';
      position: absolute;
      top: -40%;
      left: -50px;
      width: 100px;
      height: 100vh;
      background-color: #F6FBFD;
      filter: blur(40px);
      z-index: -1;
}
.teaser[data-v-5e0095c9] {
  font-size: var(--type-scale-5-font-size);
  line-height: var(--type-scale-5-line-height);
  color: var(--color-gray-12);
  margin: var(--spacing-40) 0 0 0;
  text-wrap: balance;
}
.description[data-v-5e0095c9] {
  font-size: var(--type-scale-4-font-size);
  line-height: var(--type-scale-4-line-height);
  color: var(--color-gray-10);
  margin: var(--spacing-20) 0 0 0;
  text-wrap: balance;
}
.ecs-tag[data-v-5e0095c9] {
  line-height: 20px;
  padding: 0 8px;
  mix-blend-mode: multiply;
  border-radius: 32px;
  border: 1px solid var(--color-blue-4);
  margin-bottom: var(--spacing-10);
}
.ecs-button[data-v-5e0095c9] {
  font-size: var(--type-scale-4-font-size);
  border-radius: 6px;
  padding: 0px 16px;
  height: 36px;
}
