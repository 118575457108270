.columns-box[data-v-c410259e] {
  border-radius: var(--border-radius-large);
  padding: var(--spacing-10);
  background: var(--color-gray-2);
}
.column-box[data-v-c410259e] {
  border-radius: var(--border-radius-medium);
  background: #FFF;
  box-shadow: 0px 0px 0px 1px rgba(71, 75, 96, 0.05), 0px 1px 4px rgba(71, 75, 96, 0.1);
  width: 100%;
  padding: var(--spacing-15) var(--spacing-20) var(--spacing-20) var(--spacing-15);
  position: relative;
}
.column-box-form[data-v-c410259e] {
    padding-left: var(--spacing-50);
}
.column-box-form > div[data-v-c410259e] {
      flex: 1;
}
.badges[data-v-c410259e] {
  position: absolute;
  top: 12px;
  right: 12px;
}
