.naming-example[data-v-70a261f7] {
  font-size: 12px;
  color: #858E9E;
}
.naming-example .mono[data-v-70a261f7] {
    color: #65687A;
}
.label-right[data-v-70a261f7] {
  padding-right: 30px;
  text-align: right;
}
.label-right label[data-v-70a261f7] {
    margin-bottom: 0;
    font-weight: 500;
}
.label-right .form-text[data-v-70a261f7] {
    margin-top: 0;
}
[data-v-70a261f7] .transcript-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
  margin: -5px 0 0 0;
}
[data-v-70a261f7] .transcript-grid > .ecs-form-check {
    height: auto !important;
    margin-bottom: 0 !important;
    display: flex !important;
}
[data-v-70a261f7] .transcript-grid > .ecs-form-check .ecs-form-check-label {
      display: flex;
      align-items: center;
      height: auto;
}
[data-v-70a261f7] .transcript-grid > .ecs-form-check .ecs-form-check-label > div {
        margin-left: 5px;
}
.edit-button[data-v-70a261f7] {
  margin: -2px 0 -2px 8px;
}
.back-button[data-v-70a261f7] {
  margin: 0 0 0 -12px;
}

/* REDUNDANT w/ export-bulk-metadata.vue */
.radio-grid[data-v-70a261f7] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: var(--spacing-15);
}
.column-label[data-v-70a261f7] {
  padding: 6px 32px 6px 0;
  text-align: right;
}
.custom-template-selection[data-v-70a261f7] {
  padding: var(--spacing-10) 0 0 30px;
  position: relative;
}
.custom-template-selection .manage[data-v-70a261f7] {
    position: absolute;
    top: -24px;
    right: 0;
}
.preview[data-v-70a261f7] {
  border-radius: var(--border-radius-medium) 0 0 0;
  border-left: 1px solid var(--color-gray-3);
  border-top: 1px solid var(--color-gray-3);
  padding: 2px;
  overflow-x: auto;
  display: flex;
  margin-top: var(--spacing-20);
  position: relative;
}
.preview > span[data-v-70a261f7] {
    flex-shrink: 0;
    padding: var(--spacing-5) var(--spacing-10);
    border-right: 1px solid var(--color-gray-3);
    font-size: var(--type-scale-2-font-size);
    line-height: var(--type-scale-2-line-height);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-10);
}
.edit-button[data-v-70a261f7] {
  margin: -2px 0 -2px 8px;
}
.back-button[data-v-70a261f7] {
  margin: 0 0 0 -12px;
}
.templates-index-header[data-v-70a261f7] {
  padding: 0 var(--spacing-20) var(--spacing-10) var(--spacing-20);
}
.fetching[data-v-70a261f7] {
  padding: 100px;
}
