<template>
  <div class="profile-dashboard">

    <div v-if="showDashboard" class="pb-6">
      <div class="profile-dashboard-headline mb-2">
        <ecs-text-v2 preset="headline-1">{{ isIssueProfiles ? 'Top Issues' : 'Top People & Entities' }}</ecs-text-v2>
        <div class="segments">
          <label>
            <input v-model="chronDocToggle" value="docs" type="radio" name="scope_toggle_top">
            <span class="segment">Documents</span>
          </label>
          <label>
            <input v-model="chronDocToggle" value="chron" type="radio" name="scope_toggle_top">
            <span class="segment">Chronology</span>
          </label>
        </div>
      </div>

      <div id="people" class="profile-table" :class="{ 'more scrollbar scrollbar-sml': topAreaShowMore }">
        <div v-if="!haveProfilesData" class="empty-chart">
          <div class="empty-chart-table mb-3"></div>
          <span>There is not enough data to display this chart.</span>
        </div>
        <template v-else>
          <div class="profile-table-head profile-table-row">
            <div class="profile-table-cell name"></div>
            <div class="profile-table-cell total table-sort">Total Items</div>
          </div>
          <div class="profile-table-body">
            <template v-if="isIssueProfiles">
              <div v-for="t in profilesData" :key="t.name" class="profile-table-row">
                <router-link :to="{ path: `/matters/${ matterId }/profiles/issues/${ t.id }` }" class="profile-table-cell name profile-table-link">
                  <ecs-icon type="tag" color="#AEB1B9" class="mr-2" />
                  <span>{{ t.name }}</span>
                </router-link>
                <div class="profile-table-cell total">
                  {{ t.count }}
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="p in profilesData" :key="p.name" class="profile-table-row">
                <div class="profile-table-cell name profile-table-link">
                  <router-link :to="{ name: 'witnesses', params: { id: p.id, matterId: matterId }}" class="profile-table-link">
                    <ecs-avatar :name="p.name" :image="p.avatar_thumb" :size="42" :backgroundColor="p.color_hex" class="mr-2" />
                    <span>{{ p.name }}</span>
                  </router-link>
                </div>
                <div class="profile-table-cell total">
                  {{ p.count }}
                </div>
              </div>
            </template>
          </div>
        </template>
      </div>
      <ecs-button-more v-if="haveMoreProfilesData" type="expand" @click="peopleMoreToggle" :active="topAreaShowMore">Show {{ peopleMoreButtonText }}</ecs-button-more>

      <template v-if="isIssueProfiles && tagSetsData && tagSetsData.length">
        <div class="profile-dashboard-headline mb-2 mt-6">
          <ecs-text-v2 preset="headline-2">{{ isIssueProfiles ? 'Tag Sets' : 'Groups' }}</ecs-text-v2>
        </div>
        <div>
          <div id="groups"></div>
        </div>
      </template>

      <div class="profile-dashboard-headline mb-2 mt-6">
        <ecs-text-v2 preset="headline-2">{{ isIssueProfiles ? 'Transcript Annotations by Issue' : 'Related Issue Profiles' }}</ecs-text-v2>
        <div class="segments">
          <label>
            <input v-model="relatedDataTab" value="tags" type="radio" autocomplete="off" checked name="scope_toggle_issues">
            <span class="segment">Tags</span>
          </label>
          <label>
            <input v-model="relatedDataTab" value="sets" type="radio" autocomplete="off" name="scope_toggle_issues"><!-- :disabled="!setsHaveData" -->
            <span class="segment">Tag Sets</span>
          </label>
        </div>
      </div>

      <div v-if="!bubblesHaveData" class="empty-chart">
        <div class="empty-chart-bubble mb-3"></div>
        <span>There is not enough data to display this chart.</span>
      </div>

      <div v-else class="row">
        <div class="col-6 pr-2">
          <div id="issueBubbles"></div>
        </div>
        <div class="col-6 pl-2">

          <div id="issues" class="profile-table" :class="{ 'more scrollbar scrollbar-sml': relatedShowMore }">
            <div class="profile-table-head profile-table-row">
              <div class="profile-table-cell name"></div>
              <div class="profile-table-cell count">
                {{ relatedColumnHeader }}
              </div>
              <div class="profile-table-cell percentage" v-ec-tooltip="{ content: relatedPercentTooltip, delay: [750, 5]}">
                %
              </div>
            </div>

            <div class="profile-table-body">
              <div v-for="(item, i) in bubblesData.children" @mouseover="highlightBubble(item, i)" @mouseleave="unhighlightBubble(item, i)" :key="item.name" class="profile-table-row profile-table-row-sml has-hover">
                <div class="profile-table-cell name issue">
                  <!--<a :href="`/matters/${ matterId }/profiles/issues/${ item.id }`" class="profile-table-link">-->
                    <div :style="{ 'background-color': colors[i] }" class="profile-table-dot mr-2"></div>
                    <span :data-bubble-title="item.name">{{ item.name }}</span>
                  <!--</a>-->
                </div>
                <div class="profile-table-cell count">
                  {{ item.count }}
                </div>
                <div class="profile-table-cell percentage">
                  {{ item.percent }}
                </div>
              </div>
            </div>

          </div>
          <ecs-button-more v-if="bubblesHaveMoreData" type="expand" @click="issuesMoreToggle" :active="relatedShowMore">Show {{ relatedMoreButtonText }}</ecs-button-more>
        </div>
      </div>
    </div>

    <ecs-empty-state v-else-if="!directoryLoading && !this.profileLoading && filters.length" type="empty-dashboard">
      The Dashboard requires at least 5 {{ isIssueProfiles ? 'Tags' : 'Profiles' }} in the directory in order to display.
    </ecs-empty-state>

    <ecs-empty-state v-else-if="!directoryLoading && !this.profileLoading" type="empty-dashboard">
      The Dashboard requires at least 5 {{ isIssueProfiles ? 'Tags' : 'Profiles' }} in the directory in order to display.
      <template v-slot:actions>
        <ecs-button v-if="!isIssueProfiles && matterPermissions['profiles-access_to_people_entities-manage']" @click="$router.push({ name: 'witnesses.new', params: { matterId: matterId }, hash: '#profile' })" icon="add-plus">Add a profile</ecs-button>
        <ecs-button v-else-if="isIssueProfiles && matterPermissions['matter_settings-manage_tags-access']" @click="$router.push({ name: 'matters.edit', params: { matterId: matterId }, hash: '#tags' })" icon="add-plus">Add a tag</ecs-button>
      </template>
    </ecs-empty-state>

  </div>
</template>

<script>
  import * as d3 from 'd3'

  export default {
    props: {
      currentId        : [Number, String],
      matterId         : [Number, String],
      directoryLoading : Boolean,
      isIssueProfiles  : Boolean,
      showDashboard    : Boolean,
      matterPermissions: { type: Object, default() { return {} }}
    },

    data() {
      return {
        chronDocToggle  : 'chron', // only other option is 'docs'
        relatedDataTab  : 'tags',  // only other option is 'sets'
        topAreaShowMore : false,
        relatedShowMore : false,
        dashboardShown  : false,
        issuesSvg       : null,
        bubblesArea     : 0,
        peFetched       : false,
        issuesFetched   : false,
        bubblesInitted  : false,
        windowWidth     : 0,
        setsHaveData    : false,

        colors: [
          '#1864ab', '#1c7ed6', '#339af0', '#74c0fc', '#d0ebff',
          '#862e9c', '#ae3ec9', '#cc5de8', '#e599f7', '#f3d9fa',
          '#364fc7', '#4263eb', '#5c7cfa', '#91a7ff', '#dbe4ff',
          '#087f5b', '#0ca678', '#20c997', '#63e6be', '#c3fae8',
          '#0b7285', '#1098ad', '#22b8cf', '#66d9e8', '#c5f6fa',
          '#5f3dc4', '#7048e8', '#845ef7', '#b197fc', '#e5dbff',
          '#5c940d', '#74b816', '#94d82d', '#c0eb75', '#e9fac8',
          '#e67700', '#f59f00', '#fcc419', '#ffe066', '#fff3bf',
          '#2b8a3e', '#37b24d', '#51cf66', '#8ce99a', '#d3f9d8',
          '#d9480f', '#f76707', '#ff922b', '#ffc078', '#ffe8cc',
          '#a61e4d', '#d6336c', '#f06595', '#faa2c1', '#ffdeeb',
          '#c92a2a', '#f03e3e', '#ff6b6b', '#ffa8a8', '#ffe3e3'
        ]
      }
    },

    computed: {
      namespace(){
        return this.isIssueProfiles ? 'tags' : 'witnesses'
      },

      profileLoading() {
        return this.$store.getters['witnesses/fetching']
      },

      filters(){
        return this.$store.getters[`${ this.namespace }/filters`]
      },

      ///////////////////////
      //      CHART 1      //
      ///////////////////////

      profilesDataFetching(){
        return this.$store.getters[`${ this.namespace }/dboardFetchingPro`]
      },

      haveProfilesData(){
        return (this.profilesData && this.profilesData.length) && !this.profilesDataFetching
      },

      haveMoreProfilesData(){
        return this.profilesDataCurrentTab && this.profilesDataCurrentTab.length > 5
      },

      profilesDataAll(){
        return this.$store.getters[`${ this.namespace }/dashboardProfiles`]
      },

      profilesDataCurrentTab(){
        let node
        if (!this.isIssueProfiles)
          node = this.chronDocToggle == 'docs' ?
            'P&E - Documents - Profiles' :
            'P&E - Chronology - Profiles'
        else
          node = this.chronDocToggle == 'docs' ?
            'Issues - Documents - Top Issues' :
            'Issues - Chronology - Top Issues'
        // return _.orderBy(this.profilesDataAll[node], ['count'], ['desc']) // removed for performance, but can be added back need-be
        return this.profilesDataAll[node]
      },

      profilesData(){
        return this.topAreaShowMore ? this.profilesDataCurrentTab : _.take(this.profilesDataCurrentTab, 5)
        // return _.filter(this.profilesDataAll[node], d => d.count != 0) // Can revert to above line if zero-count entries are not returned
      },

      ///////////////////////
      //      CHART 2      //
      ///////////////////////

      barChartData(){
        return this.isIssueProfiles ? this.tagSetsData : this.groupsData
      },

      tagSetsData(){
        const sets =  this.$store.getters[`${ this.namespace }/dashboardGroups`]
        const cnt = 12
        const index = this.chronDocToggle == 'docs' ? 'Issues - Documents - Tag Sets' : 'Issues - Chronology - Tag Sets'
        const data = _.orderBy(sets[index], ['count'], ['desc'])
        const bars = _.take(data, cnt)
        if (data.length > cnt){
          const leftovers = _.slice(data, cnt)
          const otherCount = _.sumBy(leftovers, 'count')
          const otherPercent = _.sumBy(leftovers, 'percent')
          const otherNames = _.map(leftovers, g => g.name).join(', ')
          const others = { name: 'Others', count: otherCount, percent: otherPercent, names: otherNames }
          bars.push(others)
        }
        return bars
      },

      ///////////////////////
      //      CHART 3      //
      ///////////////////////

      relatedDataFetching(){
        return this.$store.getters[`${ this.namespace }/dboardFetchingRel`]
      },

      relatedColumnHeader(){
        return this.isIssueProfiles ? 'Annotations' : 'Total Items'
      },

      relatedDataAll(){
        return this.$store.getters[`${ this.namespace }/dashboardRelated`]
      },

      relatedDataPE(){
        let node
        const isDocs = this.chronDocToggle == 'docs'
        if (this.relatedDataTab == 'tags')
          node = isDocs ? 'P&E - Documents - Related Issue Profiles - Tags' : 'P&E - Chronology - Related Issue Profiles - Tags'
        else
          node = isDocs ? 'P&E - Documents - Related Issue Profiles - Tag Sets' : 'P&E - Chronology - Related Issue Profiles - Tag Sets'
        // return _.orderBy(this.relatedDataAll[node], ['count'], ['desc']) // removed for performance, but can be added back need-be
        return this.relatedDataAll[node]
      },

      relatedDataIssues(){
        return this.relatedDataTab == 'tags' ? this.relatedDataAll['Issues - Transcript Annotations - Tags'] : this.relatedDataAll['Issues - Transcript Annotations - Tag Sets']
      },

      relatedData(){
        return this.isIssueProfiles ? this.relatedDataIssues : this.relatedDataPE
      },

      bubbleDataPE(){
        const data = this.relatedShowMore ? this.relatedDataPE : _.take(this.relatedDataPE, 10)
        return { 'children': data }
      },

      bubbleDataIssues(){
        const data = this.relatedShowMore ? this.relatedDataIssues : _.take(this.relatedDataIssues, 10)
        return { 'children': data }
      },

      bubblesData(){
        return this.isIssueProfiles ? this.bubbleDataIssues : this.bubbleDataPE
      },

      bubblesHaveData(){
        return !!(this.bubblesData && this.bubblesData.children && this.bubblesData.children.length)
      },

      bubblesHaveMoreData(){
        return this.bubblesHaveData && this.relatedData.length > 10
      },

      relatedPercentTooltip(){
        let verbiage
        if (this.isIssueProfiles) {
          verbiage = this.relatedDataTab == 'sets' ? 'annotations in tag set' : 'annotations with tag'
        } else {
          verbiage = this.relatedDataTab == 'sets' ? 'items in tag set' : 'items with tag'
        }
        return `% of all ${ verbiage }` 
      },

      ///////////////////////
      //         UI        //
      ///////////////////////

      peopleMoreButtonText(){
        return this.topAreaShowMore ? 'less' : 'more'
      },

      relatedMoreButtonText(){
        return this.relatedShowMore ? 'less' : 'more'
      }
    },

    methods: {
      peopleMoreToggle(){
        this.topAreaShowMore = !this.topAreaShowMore
      },

      issuesMoreToggle(){
        this.relatedShowMore = !this.relatedShowMore
      },

      groupsBarchart(){
        // ref: https://blog.risingstack.com/d3-js-tutorial-bar-charts-with-javascript/
        $('#groups').html('')
        
        const groupsMargin = { top: 10, right: 0, bottom: 90, left: 30 }
        const totalHeight = 304
        const chartHeight = totalHeight - groupsMargin.top - groupsMargin.bottom
        const groupsWidth = $('#groups').width()

        let svg = d3.select('#groups').append('svg')
          .attr("viewBox", [0, 0, groupsWidth, totalHeight])
          .attr('height', totalHeight)
          .attr('width', '100%')

        const chart = svg.append('g').attr('transform', `translate(${ groupsMargin.left }, ${ groupsMargin.top })`)

        const yRange = d3.max(this.barChartData, g => g.count)
        const yRangeStep = Math.ceil(yRange / 10)
        const yTicks = _.range(0, yRange, yRangeStep)
        const lastTick = yTicks[yTicks.length - 1]
        if (yRange <= 10 || !(lastTick == yRange + 1 || lastTick == yRange - 1))
          yTicks.push(yRange)

        const yScale = d3.scaleLinear()
          .range([totalHeight - groupsMargin.top - groupsMargin.bottom, 0])
          .domain([0, yRange])

        chart.append('g').call(d3.axisLeft(yScale).tickValues(yTicks).tickFormat(d3.format('d')))

        const xScale = d3.scaleBand()
          .range([0, groupsWidth - groupsMargin.left - groupsMargin.right])
          .domain(this.barChartData.map(g => g.name))
          .padding(0.5)

        chart.append('g')
          .attr('transform', `translate(0, ${ totalHeight - groupsMargin.top - groupsMargin.bottom })`)
          .call(d3.axisBottom(xScale))
          .style('color', '#e2e4ea')
          .attr('height', '1px')
          .selectAll('text')
            .attr('transform', 'translate(-1,1)rotate(45)')
            .style('text-anchor', 'start')
            .style('color', '#858E9E')
            .style('font-size', '11px')

        const barGroups = chart.selectAll()
          .data(this.barChartData)
          .enter()
          .append('g')

        const bars = barGroups.append('rect')
          .attr('x', g => xScale(g.name))
          .attr('y', g => yScale(g.count))
          .attr('height', g => chartHeight - yScale(g.count))
          .attr('width', xScale.bandwidth())
          .attr('class', 'bar')
          .style('fill', '#1E7CCD')
          // .attr('title', g => `${ g.count } members in group ${ g.name }`)

        const colors = [...this.colors]
        _.each(bars._groups[0], (g, i) => {
          $(g).css('fill', colors.shift())
        })

        const that = this

        bars.on('mouseenter', function (evt, barData) {
          that.addGroupsTooltip(this, barData)
        })
        bars.on('mouseleave', function (evt) {
          that.removeGroupsTooltip(this)
        })
      },

      addGroupsTooltip(bar, barData){
        const barLeft = bar.x.baseVal.value + 10
        const barTop = bar.y.baseVal.value - 40
        d3.select('#groups')
          .append('div')
          .attr('class', 'tooltips')
          .html(`<div class="tippy-box" data-theme="everchron"><div class="tippy-content">${ barData.count } Issues in the set ${ barData.name } /<br/>${ barData.percent }% of all Issues in the Matter</div></div>`)
          .style('position', 'absolute')
          .style("left", barLeft  + "px")
          .style("top", barTop + "px")

        const tooltip = $('#groups .tippy-content')
        if (barData.names)
          tooltip.html(`<div class="tippy-content">${ barData.count } Issues in the remaining tag sets /<br/>${ barData.percent }% of all Issues in the Matter /<br/>Tag Sets: ${ barData.names }</div>`)
          // tooltip.append(` / <div>Tag Sets: ${ barData.names }</div>`)

      },

      removeGroupsTooltip(bar){
        $('#groups .tooltips').remove()
      },

      issueBubblesInit(){
        $('#issueBubbles').html('')
        if (this.bubblesHaveData){
          this.bubblesArea = $('#issueBubbles').width()

          this.issuesSvg = d3.select('#issueBubbles')
            .append('svg')
            .attr("viewBox", [0, 0, this.bubblesArea, this.bubblesArea])
            .attr('width', this.bubblesArea)
            .attr('height', '530')
            .attr('class', 'bubbleBox')

          this.bubblesInitted = true
          this.issueBubblesUpdate()
        }
      },

      issueBubblesUpdate(){ // ref: https://bl.ocks.org/HarryStevens/54d01f118bc8d1f2c4ccd98235f33848
        const t = d3.transition().duration(450)

        const color = d3.scaleOrdinal(this.colors)

        const nodes = d3.hierarchy(this.bubblesData)
          .sum(d => d.count)

        const pack = d3.pack(this.bubblesData)
          .size([this.bubblesArea, this.bubblesArea])
          .padding(6)

        var node = this.issuesSvg.selectAll('.node') // Could imporoving this selection when trying to re-use existing SVG help, per https://stackoverflow.com/questions/19726553/d3-missing-first-value-in-array
          .remove()
          .data(pack(nodes).leaves(), d => d)

        /* The .remove() 3 lines above is better than the below because of the current implementation of colors.
           The below will add the new 'more' tags in starting with index 1, so the first 10 colors are duplicated.
           The visual effect is better anyway, also.

        node.exit()
          .transition(t)
          .attr('r', 0)
          .style('opacity', 0)
          .remove()
        
        node.transition(t)
          .style('color', 'black')
          .attr('r',  d => d.r)
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)
        */

        const that = this

        const bubbs = node.enter()
          .filter(d => !d.children)
          .append('g')
          .attr('class', 'node')
          .attr('title', d => d.data.name)
          .attr('transform', d => 'translate(' + d.x + ',' + d.y + ')')
          .attr('data-color', (d, i) => color(i))
          .append('circle')
          .style('fill', (d, i) => color(i))
          .on('mouseenter', (evt, d) => { that.issueEmphasis('in', this, d) })
          .on('mouseleave', (evt, d) => { that.issueEmphasis('out', this, d) })
          .transition(t)
          .attr('r', d => d.r)
      },

      issueEmphasis(dir, evt, data){
        const listName = $(`[data-bubble-title="${ data.data.name }"]`)

        if (dir == 'in'){
          listName.closest('.profile-table-row').addClass('hover')
        } else {
          listName.closest('.profile-table-row').removeClass('hover')
        }
      },

      highlightBubble(bubble, i){
        d3.select(`#issueBubbles svg g[title="${ bubble.name }"] circle`)
          .classed('hover', true)
      },

      unhighlightBubble(bubble, i){
        if (bubble){
          const bub = d3.select(`#issueBubbles svg g[title="${ bubble.name }"]`)
          if (bub){
            d3.select(`#issueBubbles svg g[title="${ bubble.name }"] circle`)
              .classed('hover', false)
          }
        }
      },

      fetchIssueProfiles(){
        Promise.all([
          this.$store.dispatch('tags/dashboardProfiles', { matter_id: this.matterId, initialLoad: true }),
          this.$store.dispatch('tags/dashboardGroups', { matter_id: this.matterId, initialLoad: true }),
          this.$store.dispatch('tags/dashboardRelated', { matter_id: this.matterId, initialLoad: true })
        ]).finally(() => { this.issuesFetched = true })
      },

      fetchPeProfiles(){
        Promise.all([
          this.$store.dispatch('witnesses/dashboardProfiles', { matter_id: this.matterId, initialLoad: true }),
          this.$store.dispatch('witnesses/dashboardRelated', { matter_id: this.matterId, initialLoad: true })
        ]).finally(() => { this.peFetched = true })
      },

      loadGraphs(){
        $('#groups').html('')
        if (this.bubblesHaveData)
          setTimeout(() => { this.issueBubblesInit() }, 100)
        if (this.isIssueProfiles)
          this.groupsBarchart()
        this.dashboardShown = true
      },

      detectWindowWidth(){
        const wid = $(window).width()
        if (wid != this.windowWidth)
          this.renderDashboard()
        this.windowWidth = wid
      },

      renderDashboard(){
        if (this.showDashboard)
          setTimeout(() => { this.loadGraphs() }, 10)
      },

      setDefaultTab(is){
        if (!is){
          if (this.isIssueProfiles){
            if ((!this.profilesDataAll['Issues - Chronology - Top Issues'] || !this.profilesDataAll['Issues - Chronology - Top Issues'].length) && (this.profilesDataAll['Issues - Documents - Top Issues'] && this.profilesDataAll['Issues - Documents - Top Issues'].length)){
              this.chronDocToggle = 'docs'
            }
          }
          else {
            if ((!this.profilesDataAll['P&E - Chronology - Profiles'] || !this.profilesDataAll['P&E - Chronology - Profiles'].length) && (this.profilesDataAll['P&E - Documents - Profiles'] && this.profilesDataAll['P&E - Documents - Profiles'].length)){
              this.chronDocToggle = 'docs'
            }
          }
        }
      },

      fetchGraphData(){
        if (this.isIssueProfiles){
          this.fetchIssueProfiles()
        }
        else {
          this.fetchPeProfiles()
        }
      }
    },

    watch: {
      currentId(id){
        if (!id && this.showDashboard)
          this.loadGraphs()
      },

      directoryLoading(is){
        if (!is && this.showDashboard)
          this.loadGraphs()
      },

      showDashboard(sho){
        if (sho && !this.dashboardShown)
          this.loadGraphs()
      },

      barChartData(bcd){
        if (!_.isEmpty(bcd))
          setTimeout(() => { this.groupsBarchart() }, 200)
      },

      bubblesData(){
        if (this.bubblesHaveData){
          setTimeout(() => {
            // if (!this.bubblesInitted){ // First node is empty when attempt to re-use existing SVG
              this.issueBubblesInit()
            // } else {
            //   this.issueBubblesUpdate()
            // }
          }, 100)
        } else {
          this.bubblesInitted = false
          $('#issueBubbles').html('')
        }
      },

      isIssueProfiles(is){
        if (is && !this.issuesFetched)
          this.fetchIssueProfiles()
        else if (!this.peFetched)
          this.fetchPeProfiles()
      },

      profilesDataFetching(is){
        this.setDefaultTab(is)
      },

      filters:{
        deep: true, immediate: true, handler(){
          this.fetchGraphData()
        }
      },

      chronDocToggle(){
        localStorage.setItem('profile-dashboard-index-tab', this.chronDocToggle)
      },

      relatedDataTab(){
        localStorage.setItem('profile-dashboard-related-tab', this.relatedDataTab)
      }
    },

    created(){
      this.chronDocToggle = localStorage.getItem('profile-dashboard-index-tab') || 'chron'
      this.relatedDataTab = localStorage.getItem('profile-dashboard-related-tab') || 'tags'
    },

    mounted(){
      this.windowWidth = $(window).width()
      window.addEventListener('resize', this.detectWindowWidth)
      this.loadGraphs()
    }
  }
</script>

<style type="scss">
  #groups {
    height: 310px;
  }

  g.tick{
    color: #858E9E;
  }

  path.domain{
    color: #E2E4EA;
  }
</style>
