@charset "UTF-8";
.import-report-card .ecs-alert ul {
  list-style-type: disc;
  padding-left: 18px;
}
.tiny-collapse-button {
  padding: 0;
  line-height: 2em;
  color: currentColor;
  font-weight: 500;
}
.tiny-collapse-button:after {
    content: "›";
    transition: .2s;
    margin-left: 5px;
    display: inline-block;
}
.tiny-collapse-button:not(.collapsed):after {
    transform: rotate(90deg);
    opacity: .7;
}
