<template>
  <div>
    <ec-popover persistent>
      <ecs-button :disabled="disabled" icon="parties" data-test="bulk-action-group">Group</ecs-button>

      <template v-slot:content>
        <div class="width-360">
          <component id="bulkGroup" :is="action" :matter="matter" @apply="apply" @toggle="toggle" />
        </div>
      </template>     
    </ec-popover>

    <ecs-dialog :show="show" @cancel="cancel">
      <ecs-dialog-header icon="user" icon-color="vibrant">{{ heading }} Groups</ecs-dialog-header>
      <p v-if="action=='ec-bulk-del'">Are you sure you want to remove {{ groupsText }} from the selected Profiles?</p>
      <p v-else>Are you sure you want to assign {{ groupsText }} to the selected Profiles?</p>
      <template v-slot:actions>
        <ecs-button-dialog @click="cancel" data-test="dialog-cancel-button">Cancel</ecs-button-dialog>
        <ecs-button-dialog @click="confirm" type="confirm" icon="parties" data-test="dialog-confirm-button">Yes, Proceed</ecs-button-dialog>
      </template>
    </ecs-dialog>
  </div>
</template>

<script>
  import EcBulkAdd from './bulk-add'
  import EcBulkDel from './bulk-del'
  import EcPopover from 'ec-common/components/utility/popover'

  export default {
    components: { EcBulkAdd, EcBulkDel, EcPopover },

    props: {
      disabled: Boolean
    },

    data() {
      return {
        action : 'ec-bulk-add',
        tags   : []
      }
    },

    computed: {
      selected() {
        return this.$store.getters['witnesses/selected']
      },

      matter() {
        return this.$store.getters['matters/active']
      },

      show() {
        return this.tags.length > 0
      },

      heading() {
        return this.action == 'ec-bulk-del' ? 'Remove' : 'Add'
      },

      groupsText(){
        return this.tags.length > 1 ? 'these Groups' : 'this Group'
      }
    },

    methods: {
      confirm() {
        this.$store.commit('activities/add', {
          action : this.action,
          scope  : 'witnesses',
          type   : 'ec-bulk-group',
          noun   : 'Profile',
          verb   : 'Editing Groups',
          ids    : this.selected,
          data : {
            matter_id : this.matter.id,
            tags      : this.tags
          }
        })

        this.action = 'ec-bulk-add'
        this.tags   = []
      },

      toggle(action) {
        this.action = action === 'add' ? 'ec-bulk-add' : 'ec-bulk-del'
      },

      apply(tags) {
        this.tags = tags
      },

      cancel() {
        this.tags = []
      }
    }
  }
</script>

<style>
  #bulkGroup .popover-content input[type="text"],
  #bulkGroup span[placeholder="Groups to be applied to selected Profiles"],
  #bulkGroup span[placeholder="Groups to be removed from selected Profiles"] {
    width: 100%;
  }
</style>
