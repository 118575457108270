.card-export[data-v-0efccaf5] {
  font-size: 14px;
}
.card-export .download[data-v-0efccaf5] {
    opacity: 0;
}
.card-export:hover .download[data-v-0efccaf5],
  .card-export.ecs-card-expanded .download[data-v-0efccaf5] {
    opacity: 1;
}
.card-export .collapse[data-v-0efccaf5] {
    cursor: default;
}
.location[data-v-0efccaf5],
.source[data-v-0efccaf5],
.type[data-v-0efccaf5],
.meta[data-v-0efccaf5],
.metrics[data-v-0efccaf5] {
  display: flex;
  align-items: center;
}
.separator[data-v-0efccaf5] {
  background: rgba(32, 33, 39, 0.15);
  height: 24px;
  width: 1px;
  margin: 0 16px 0 20px;
}
.source[data-v-0efccaf5] {
  color: #858E9E;
}
.source[data-v-0efccaf5]:after {
    width: 6px;
    height: 16px;
    content: "";
    margin: 0 10px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='16' viewBox='0 0 6 16'%3E%3Cpolyline fill='none' stroke='%23D7DAE1' stroke-linecap='round' stroke-linejoin='round' points='1 1 5.375 8 1 15'/%3E%3C/svg%3E");
}
.multiple[data-v-0efccaf5] {
  color: #858E9E;
}
.meta[data-v-0efccaf5] {
  margin-left: auto;
}
.user[data-v-0efccaf5] {
  text-align: right;
  color: #0961EB;
  margin-right: 30px;
}
.user .suspended[data-v-0efccaf5] {
    color: #C3C6CD;
}
.metrics > div[data-v-0efccaf5] {
  background: #F3F4F7;
  color: #65687A;
  font-size: 12px;
  padding: 6px 8px;
  position: relative;
}
.metrics > div[data-v-0efccaf5]:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.metrics > div[data-v-0efccaf5]:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.metrics > div[data-v-0efccaf5]:nth-child(2):before {
    content: "";
    width: 1px;
    height: 18px;
    background: #D7DAE1;
    border-radius: 1px;
    position: absolute;
    left: 0;
    top: 4px;
}
.metrics .number[data-v-0efccaf5] {
  font-weight: 500;
}
.export-details[data-v-0efccaf5] {
  border-top: 1px solid #ECEDF2;
}
