.profile-issue-tag[data-v-89efc862] {
  margin: 4px 0;
  font-size: var(--type-scale-4-font-size);
  border: 1px solid transparent;
}
.profile-issue-tag.plain[data-v-89efc862] {
    border-color: var(--color-gray-3);
    background: var(--color-gray-1);
    color: var(--color-gray-13);
}
