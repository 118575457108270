.summary[data-v-554a32c2] {
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-gray-4);
}
.designation-block .ecs-text[data-v-554a32c2] {
  padding: var(--spacing-10) var(--spacing-10) var(--spacing-5) var(--spacing-10);
  color: var(--color-gray-10);
  border-top: 1px solid var(--color-gray-3);
  margin-top: var(--spacing-5);
}
.designation-block:first-child .ecs-text[data-v-554a32c2] {
  border: none;
  padding-top: 0;
  margin-top: 0;
}
.party[data-v-554a32c2] {
  border-bottom: 1px solid var(--color-gray-3);
  padding: 10px var(--spacing-25);
}
.party-label[data-v-554a32c2] {
    flex: 1;
}
.count[data-v-554a32c2] {
  width: 120px;
  text-align: right;
  color: var(--color-gray-10);
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
}
.count span[data-v-554a32c2] {
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-15);
}
.status[data-v-554a32c2] {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 4px;
  width: 100px;
}
.indicator[data-v-554a32c2] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: help;
}
