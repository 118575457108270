.case-entry-form[data-v-b47bd688] {
  padding: var(--spacing-20);
  border: 1px solid var(--color-gray-4);
  border-radius: var(--border-radius-medium);
  margin-bottom: var(--spacing-30);
}
.case-caption[data-v-b47bd688] {
  font-weight: 600;
  font-style: italic;
}
.subtle[data-v-b47bd688] {
  color: var(--color-gray-10);
}
dl[data-v-b47bd688] {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
}
dt[data-v-b47bd688] {
  color: var(--color-gray-10);
}
dd[data-v-b47bd688] {
  color: var(--color-gray-12);
  margin: 0 40px 0 6px;
}
