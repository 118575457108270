.characters[data-v-cc6fff22] {
  background: var(--color-white);
  padding: var(--spacing-10) var(--spacing-15);
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-gray-4);
  letter-spacing: -.5px;
  margin: var(--spacing-5) 0;
  user-select: none;
}
.characters span[data-v-cc6fff22] {
    display: inline-block;
    font-family: var(--font-family-monospace);
    font-size: 13px;
    line-height: var(--type-scale-3-line-height);
    cursor: pointer;
    transition: background .14s ease-in-out;
    border-radius: var(--border-radius-small);
}
.character[data-v-cc6fff22]:hover {
  background: var(--color-blue-4);
}
.character.active[data-v-cc6fff22] {
  background: var(--color-blue-9);
  color: var(--color-white);
}
.space[data-v-cc6fff22] {
  color: var(--color-gray-5);
}
.space[data-v-cc6fff22]:hover {
    background: var(--color-green-4);
    color: var(--color-green-8);
}
.space.active[data-v-cc6fff22] {
    background: var(--color-green-9);
    color: var(--color-white);
}
.adjusted-output[data-v-cc6fff22] {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 2px;
  margin-bottom: var(--spacing-10);
}
.adjusted-output-item[data-v-cc6fff22] {
  font-family: var(--font-family-monospace);
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
  background: var(--color-gray-3);
  color: var(--color-gray-10);
  padding: 2px var(--spacing-5);
  border-radius: var(--border-radius-small);
}
