.automation-conflict[data-v-7b3f0f5d] {
  font-size: 12px;
  border-radius: 4px;
  padding: 2px;
  transition: .2s;
  cursor: default;
  margin-bottom: 2px;
}
.automation-conflict[data-v-7b3f0f5d]:hover, .automation-conflict.expanded[data-v-7b3f0f5d] {
    background-color: rgba(169, 64, 174, 0.07);
}
.automation-conflict-inner[data-v-7b3f0f5d] {
    display: flex;
    align-items: center;
    min-height: 24px;
}
.automation-conflict-inner .actions[data-v-7b3f0f5d] {
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-left: 8px;
}
.automation-conflict-expand[data-v-7b3f0f5d] {
    padding: 8px 8px 8px 25px;
}
.automation-conflict .cite[data-v-7b3f0f5d] {
    color: #3C4051;
    min-width: 48px;
    margin-right: 4px;
}
.automation-conflict .conflict[data-v-7b3f0f5d] {
    color: #75798F;
    flex: 1;
}
