<template>
  <span>
  <ecs-empty-state v-if="!autoPopulationComplete" type="loading" />

  <ec-custom-table v-else-if="filteredItems" :columns="tableFields" :items="paginatedFilteredItems" :sort-by="sortBy" overflow :class="hasBorder ? `mt-5` : ''">
    <template v-slot:header_fileProfile_prefix>
      <ecs-text-v2 v-if="selectAllPercent != 0" preset="form">{{ selectAllPercent }}%</ecs-text-v2>

      <ecs-checkbox v-else-if="!not" inline :value="isAllSelected" :indeterminate="isIndeterminate" @input="value => toggleSelectAll(value)" class="mr-1" />
    </template>

    <template v-slot:header_fileProfile_suffix>
      <ecs-button v-if="!not" :type="isHideSkip ? 'primary' : 'secondary'" size="sml" icon-only icon="hide-unchecked" :active="isHideSkip" @click.prevent="hideSkip" :disabled="isHideChecked || isAllSelected && !isHideSkip" :title="isHideSkip ? 'Show unchecked rows' : 'Hide unchecked rows'" />

      <ecs-button v-if="!not" :type="isHideChecked ? 'primary' : 'secondary'" size="sml" icon-only icon="hide-checked" :active="isHideChecked" @click.prevent="hideChecked" :disabled="isHideSkip || isAllSelected && !isHideChecked" :title="isHideChecked ? 'Show checked rows' : 'Hide checked rows'" />

      <ecs-button v-if="!not" :type="isHideRight ? 'primary' : 'secondary'" size="sml" icon-only icon="warning-error" :active="isHideRight" @click.prevent="hideRight" :title="isHideRight ? 'Show all rows' : 'Show errors only'" />
    </template>

    <template v-slot:header_everchronProfile_suffix>
      <ecs-button v-if="defaultsBackup && tableType == 'MIS'" @click="resetProfileData" type="secondary" size="sml" icon-only icon="rotate-ccw" title="Reset Profile Data" />

      <ecs-text-v2 v-if="nameSwapPercent != 0" preset="form">{{ nameSwapPercent }}%</ecs-text-v2>

      <ecs-button v-else-if="tableType == 'MIS'" @click="nameOrderSwap" type="secondary" size="sml" icon-only icon="swap" :title="nameSwapOrderTo == 'firstLast' ? 'Swap applicable names to First Last format' : 'Swap applicable names to Last, First format'" />
    </template>

    <template v-slot:cell_fileProfile="data">
      <ecs-checkbox v-if="!not" :value="data[1].isChecked" @input="changed(data, 'isChecked', !data[1].isChecked)" :disabled="!!data[1].replacedBy" inline class="m-0 mono" :style="!!data[1].replacedBy ? 'text-decoration: line-through' : ''">{{ data.text || data[0] }}</ecs-checkbox>
      <span v-else class="mono">{{ data.text || data[0] }}</span>

      <ecs-button v-if="!not && !data[1].replacedBy && tableType != 'REM'" @click="initRemediateData(data)" icon-only icon="patch" type="secondary" size="sml" aria-label="Remediate data" v-ec-tooltip="{ content: 'Remediate data' }" class="remediate-button" />

      <ecs-button v-else-if="!not && data[1].replacedBy" disabled icon-only icon="patch" type="primary" size="sml" class="remediate-button" />
      
      <!--
      <ecs-button v-else-if="data[1].replacedBy" @click="undoRemediation(data)" type="primary" size="sml" icon="undo" icon-only v-ec-tooltip="{ content: 'Revert' }" aria-label="Revert" class="remediate-button visible" />
      -->
    </template>

    <template v-slot:cell_everchronProfile="data">
      <VueMultiselect v-if="!isParty && !data[1].replacedBy"
        @tag="profile => addProfile(data, profile)"
        @update:model-value="value => changed(data, 'everchronProfile', value)"
        placeholder="Select profile to map to, or input name to create a new profile."
        tag-placeholder="Add new profile"
        :options="options"
        :taggable="true"
        :multiple="false"
        :close-on-select="true"
        :hideSelected="true"
        :max-height="250"
        :model-value="getValue(data)"
        :disabled="not || !!data[1].replacedBy"
        class="standard invisible"
        selectLabel="" selectedLabel="" deselectLabel=""
      >
        <template v-slot:singleLabel="props">
          <ecs-icon v-if="props.option.modified" type="edit" color="#5A6166" title="New profile (modified)" />
          <ecs-icon v-else-if="props.option.new" type="user-add" color="#5A6166" title="New profile" />
          <span>{{ props.option.name }}</span>
        </template>
      </VueMultiselect>

      <VueMultiselect v-else-if="isParty"
        placeholder="Select party to map to..."
        selectLabel="" selectedLabel="" deselectLabel=""
        :options="options"
        :multiple="false"
        class="standard invisible"
        :close-on-select="true"
        :hideSelected="true"
        @update:model-value="val => changed(data, 'everchronParty', val)"
        :model-value="getValue(data).name"
        :max-height="250"
        :disabled="not"
      />
    </template>

    <template v-slot:cell_relevanceField="data">
      <ecs-button v-if="tableType == 'MIS' && !isParty && data[1].new && !data[1].replacedBy" @click="setRelevance(data)" :active="data[1].relevant" type="secondary" :icon="data[1].relevant ? 'user-relevance-active' : 'user-relevance'" icon-only aria-label="Set profile relevance" title="Set profile relevance" />

      <span v-if="!isRowValid(data)" class="row-status warning" v-ec-tooltip="{ content: 'Please select a profile to map.' }"></span>
    </template>

    <template v-slot:pagination>
      <ecs-pagination v-if="filteredItems.length > perPage"
        v-bind="{ currentPage, totalPages: Math.ceil(filteredItems.length / perPage), totalItems: filteredItems.length, 
          itemsPerPageSelected: perPage, type: 'paginated', showItemsPerPage: false }"
        @updatePage="e => currentPage = Number(e)" @prevPage="() => currentPage -= 1" 
        @nextPage="() => currentPage += 1" @firstPage="() => currentPage = 1" 
        @lastPage="() => currentPage = Math.ceil(filteredItems.length / perPage)" theme="bordered" />
    </template>
  </ec-custom-table>

  <ec-remediate-data v-if="remediateDataShow" @confirm="remediateComplete" @cancel="remediateDataShow = false" 
    v-bind="{ profile: remediateProfile, show: remediateDataShow, existingProfiles, commonMaps, dataType: 'profiles' }" />
    
</span>
</template>

<script>
  import VueMultiselect  from 'vue-multiselect'
  import NameParser      from 'another-name-parser'
  import EcCustomTable   from 'ec-common/components/utility/custom-table'
  import EcRemediateData from './remediate-data'
  import Remediate       from 'ec-common/helpers/remediate-profiles.js'

  export default {
    components: { VueMultiselect, EcCustomTable, EcRemediateData },
    emits: ['showProfilesMis', 'showPartiesMis', 'mappingChanged'],

    props: {
      id: String,
      items: Array,
      hasBorder: {
        type: Boolean,
        default: true
      },
      isParty: Boolean,
      parent: Object,
      tableType: String,
      not: Boolean
    },

    data() {
      return {
        perPage: 100,
        currentPage: 1,
        isHideSkip: false,
        isHideRight: false,
        isHideChecked : false,
        checkedProfiles: {},
        autoPopulationComplete: false,
        matchedEverchronProfiles: {},
        profilesCreatedHere: [],
        selectAllPercent: 0,
        nameSwapOrderTo: 'firstLast',
        nameSwapPercent: 0,
        defaultsBackup: null,
        sortBy: 'fileProfile',
        fields: [
          {
            key: 'fileProfile',
            sortable: true,
            label: this.tableType == 'REM' ? 'Remediated Profiles' : this.profilesLabel
          },
          {
            key: 'everchronProfile',
            sortable: true,
            label: this.isParty ? 'Parties in Everchron' : 'Profiles in Everchron',
            padding: '2px'
          },
          {
            key: 'relevanceField',
            sortable: false,
            label: '',
            width: 40,
            padding: '2px'
          }
        ],
        remediateProfile : null,
        remediateDataShow : false,
        origProfilesFromFile : []
      }
    },

    computed: {
      matter() {
        return this.$store.getters['matters/active']
      },

      amEverchronUser() {
        return this.$store.getters['me/amEverchronUser']
      },

      isAllSelected() {
        return !this.isIndeterminate && !this.not && !!this.selectedItems.length && (this.selectedItems.length >= this.selectableItems.length)
      },

      selectedItems() {
        return this.filteredItems.filter(profile => profile[1] ? profile[1].isChecked : null)
      },

      selectableItems(){
        return _.filter(this.filteredItems, fi => !fi[1].replacedBy)
      },

      erroredItems() {
        return this.filteredItems.filter(profile => !this.isRowValid(profile))
      },

      unCheckedItems() {
        return this.filteredItems.filter(profile => profile[1] ? !profile[1].isChecked : null)
      },

      isIndeterminate() {
        return (this.selectedItems.length < this.selectableItems.length) && this.selectedItems.length > 0
      },

      defaults(){ // this.items is plain objects, this.defaults is a two-part array with [0]=name & [1]=object
        if (this.tableType == 'CM'){
          if (!this.isParty)
            return Object.entries(this.$store.getters['imports/profileChangesCM'])
          else
            return Object.entries(this.$store.getters['imports/partiesChangesCM'])
        }
        else if (this.tableType == 'MIS'){
          if (!this.isParty){
            return Object.entries(this.$store.getters['imports/profileChangesMIS'])
          }
          else
            return Object.entries(this.$store.getters['imports/partiesChangesMIS'])
        }
        else if (this.tableType == 'REM'){
          return Object.entries(this.$store.getters['imports/profileChangesREM'])
        }
        else if (this.tableType == 'EXT'){
          return Object.entries(this.$store.getters['imports/profileChangesEXT'])
        }
      },

      commonMaps(){
        return this.$store.getters['imports/profilesExistedInSettings']
      },

      tableFields() {
        return this.fields
      },

      filteredItems() {
        if (!this.not && this.defaults){
          let items = _.filter(this.defaults, d => !_.isNil(d[1]))

          if (this.isHideSkip)
            items = items.filter(this.hideSkippedFilter)
          if (this.isHideRight)
            items = items.filter(this.hideRightFilter)
          if (this.isHideChecked)
            items = items.filter(this.hideCheckedFilter)

          return items
        }
        else {
          return this.items
        }
      },

      paginatedFilteredItems() {
        if (this.not)
          return this.items

        const filteredItems = this.filteredItems
        const perPage = this.perPage
        let endRange
        const noun = this.tableType === 'REM' ? 'Remediated Profiles' : (this.items.length > 1 ? this.termPlural : this.term)
        if (filteredItems.length > perPage) {
          if ((this.currentPage-1) * perPage + perPage < this.filteredItems.length){
            endRange = (this.currentPage-1) * perPage + perPage
          } else {
            endRange = this.filteredItems.length
          }
          this.fields[0].label = (((this.currentPage - 1) * perPage) + 1) + " - " + endRange + " of " + this.defaults.length + " " + noun + (this.tableType !== 'REM' ? " in" +  ((this.not) ? " Previous " : " ") + "Load File" : "")
          return filteredItems.slice((this.currentPage - 1) * perPage, (this.currentPage-1) * perPage + perPage)
        } else {
          this.fields[0].label = "1 - " + this.defaults.length + " of " + this.defaults.length + " " + noun + (this.tableType !== 'REM' ? " in" +  ((this.not) ? " Previous " : " ") + "Load File" : "")
          return filteredItems
        }
      },

      witnesses(){
        return this.$store.getters['witnesses/visible'].map(w => w.name)
      },

      parties(){
        return this.$store.getters['witnesses/parties'].map(w => w.name)
      },

      partiesFromFile() {
        return this.$store.getters['imports/partiesFromFile']
      },

      profilesFromFile(){
        return this.$store.getters['imports/profilesFromFile']
      },

      localCreatedUsers(){
        return this.$store.getters['imports/localCreatedUsers']
      },

      profilesCM() {
        return this.$store.getters['imports/profilesExistedInSettings']
      },

      profilesMIS() {
        return this.$store.getters['imports/profilesMissedInSettings']
      },

      profilesREM() {
        return this.$store.getters['imports/profileChangesREM']
      },

      profileFields(){
        return this.$store.getters['imports/profileFields']
      },

      dataFieldsAssigned() {
        return this.$store.getters['imports/dataFieldsAssigned']
      },

      existingProfiles(){
        return _.unionBy(this.profilesMIS, this.profilesCM, 'text')
      },

      csv(){
        return this.$store.getters['imports/csv']
      },

      options() {
        if (!this.isParty) {
          return _.union(this.witnesses, this.profilesCreatedHere).sort()
        } else {
          return this.parties
        }
      },

      profilesLabel() {
        return (this.not) ? `${ this.termPlural } not in Load File` : this.items.length + `${ this.termPlural } in Load File`
      },

      termPlural(){
        return this.isParty ? 'Parties' : 'Profiles'
      },

      term(){
        return this.isParty ? 'Party' : 'Profile'
      }
    },

    methods: {
      getValue(v){
        if (v)
          if (v.match_profile)
            return { name: v.match_profile, new: v.new, modified: v.modified }
          else
            return { name: v[1].match_profile, new: v[1].new, modified: v[1].modified }
        else
          return { name: v[0], new: v[1].new, modified: v[1].modified }
      },

      checkableProfile(str) {
        if (str.length > 0 && str != " ") return true
        if ( -1 != str.search("@") && -1 != str.search(".") ) return true
        return false
      },

      capitalize(str) {
        return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
      },

      hideSkippedFilter(item) {
        return !this.isHideSkip || item[1].isChecked
      },

      hideRightFilter(item){
        return !this.isHideRight || !this.isRowValid(item)
      },

      hideCheckedFilter(item) {
        return !this.isHideChecked || !item[1].isChecked
      },

      isRowValid(fileProfile) {
        if (fileProfile[1].isChecked){
          return fileProfile[1].match_profile != null
        }
        return true
      },

      nameOrderSwap(){
        if (!this.defaultsBackup)
          this.defaultsBackup = JSON.parse(JSON.stringify(this.defaults))
        const len = this.defaults ? this.defaults.length : 0
        this.defaultsBackup.forEach((profile, idx) => {
          setTimeout(() => {
            this.nameSwapPercent = (((idx + 1) / len) * 100).toFixed()
            if (this.nameSwapPercent >= 100) this.nameSwapPercent = 0

            if (profile[1] && profile[1].isChecked && !profile[1].modified && profile[1].new){
              const name = NameParser(profile[1].match_profile)

              let commaBeforeSuffix = false
              if (name.suffix){
                const i = name.original.indexOf(name.suffix)
                const c = name.original.charAt(i - 2)
                if (c == ',')
                  commaBeforeSuffix = true
              }

              let newName = ''
              if (this.nameSwapOrderTo == 'lastFirst'){
                if (name.last)         newName += name.last
                if (commaBeforeSuffix) newName += ','
                if (name.suffix)       newName += ' ' + name.suffix
                if (name.last && (name.first || name.prefix || name.middle)) newName += ', '
                if (name.prefix)       newName += name.prefix + ' '
                if (name.first)        newName += name.first
                if (name.middle)       newName += ' ' + name.middle
              } else {
                if (name.prefix)       newName += name.prefix + ' '
                if (name.first)        newName += name.first
                if (name.middle)       newName += ' ' + name.middle
                if (name.last)         newName += ' ' + name.last
                if (commaBeforeSuffix) newName += ','
                if (name.suffix)       newName += ' ' + name.suffix
              }

              if (newName != '')
                this.changed(profile, 'everchronProfile', newName, true)
            }
          }, 1)
        })
        this.nameSwapOrderTo = this.nameSwapOrderTo == 'lastFirst' ? 'firstLast' : 'lastFirst'
      },

      resetProfileData(){
        this.defaultsBackup.forEach(profile => {
          setTimeout(() => {
            this.changed(profile, 'everchronProfile', profile[1].match_profile, true)
          }, 1)
        })
        this.defaultsBackup = null
        this.nameSwapOrderTo = 'firstLast'
      },

      toggleSelectAll(value) {
        const newValue = value || !this.isAllSelected
        let localCheckedProfiles = {}
        let localStateProfiles = {}

        this.defaults.forEach(profile => {
          let newValue2 = newValue
          if (this.id != 'profilesREM'){
            newValue2 = profile[1].replacedWith && !_.isNil(profile[1].replacedWith) ? false : newValue
          }
          localCheckedProfiles[profile[0]] = newValue2
          if (newValue2){
            localStateProfiles[profile[0]] = { match_profile: profile[1].match_profile, isChecked: true, new: profile[1].new, relevant: profile[1].relevant, relevanceSetBy: profile[1].relevanceSetBy }
          } else {
            localStateProfiles[profile[0]] = { match_profile: null, isChecked: false, new: false, new: profile[1].new, relevant: profile[1].relevant, relevanceSetBy: profile[1].relevanceSetBy, orig: profile[1].orig, replaced: profile[1].replaced, replacedBy: profile[1].replacedBy, replacedWith: profile[1].replacedWith }
          }
        })
        
        this.checkedProfiles = _.assign({}, localCheckedProfiles)

        if (!this.isParty) {
          this.$store.commit('imports/resetProfileChanges', localStateProfiles)
          if (this.tableType === 'CM')
            this.$store.commit('imports/resetProfileChangesCM', localStateProfiles)
          else if (this.tableType === 'MIS')
            this.$store.commit('imports/resetProfileChangesMIS', localStateProfiles)
          else if (this.tableType === 'REM')
            this.$store.commit('imports/resetProfileChangesREM', localStateProfiles)
          else if (this.tableType === 'EXT')
            this.$store.commit('imports/resetProfileChangesEXT', localStateProfiles)
        } else {
          this.$store.commit('imports/resetPartiesChanges', localStateProfiles)
          if (this.tableType === 'CM')
            this.$store.commit('imports/resetPartiesChangesCM', localStateProfiles)
          else if (this.tableType === 'MIS')
            this.$store.commit('imports/resetPartiesChangesMIS', localStateProfiles)
        }

        this.anyErrors()
      },

      hideSkip() {
        this.isHideSkip = !this.isHideSkip
      },

      hideChecked() {
        this.isHideChecked = !this.isHideChecked
      },

      hideRight() {
        this.isHideRight = !this.isHideRight
      },

      setRelevance(profile){
        let relevance = profile[1].relevant ? !profile[1].relevant : true
        const matchProfile = profile[1].match_profile
        _.each(this.defaults, p => {
          if (p[1].match_profile == matchProfile)
            this.$store.commit('imports/setProfileRelevance', { text: p[0], withRelevance: _.assign(p[1], { relevant: relevance, relevanceSetBy: 'individual' }) })
        })
      },

      addProfile(fileProfile, profileName, replace) {
        this.$store.commit('imports/addLocalCreatedUser', profileName)
        if (!replace)
          this.changed(fileProfile, 'everchronProfile', profileName)
      },

      changed(fileProfile, property, value, namesSwapped, skipValidation, replace) {
        fileProfile[0] = fileProfile[0].trim()
        fileProfile[1].match_profile = fileProfile[1].match_profile
        if (fileProfile[1].match_profile)
          fileProfile[1].match_profile = fileProfile[1].match_profile.trim()

        if (!namesSwapped){
          const i = _.indexOf(this.profilesCreatedHere, value)
          if (i >= 0)
            this.profilesCreatedHere.splice(i, 1)

          if (_.indexOf(this.defaults, value) < 0 && !_.isBoolean(value))
            this.profilesCreatedHere.push(value)
        }

        if (property == 'isChecked'){
          this.checkedProfiles[fileProfile[0]] = value
          if (!value)
            delete this.matchedEverchronProfiles[fileProfile[0]]

          if (!this.isParty) {
            if (value){
              this.$store.dispatch('imports/setProfilesMatch', {text: fileProfile[0], match_data: {match_profile: fileProfile[1].match_profile, isChecked: value, key: this.tableType }})
            } else {
              this.$store.dispatch('imports/setProfilesMatch', {text: fileProfile[0], match_data: {match_profile: null, isChecked: value, key: this.tableType }})
            }
          } else {
            if (value){
              this.$store.dispatch('imports/setPartiesMatch', {text: fileProfile[0], match_data: {match_profile: fileProfile[1].match_profile, isChecked: value, key: this.tableType }})
            } else {
              this.$store.dispatch('imports/setPartiesMatch', {text: fileProfile[0], match_data: {match_profile: null, isChecked: value, key: this.tableType }})
            }
          }
          this.checkedProfiles = _.assign({}, this.checkedProfiles)
        } else {
          this.$emit('mappingChanged', { changed: fileProfile[0], to: value }) // For AI Extracted Metadata workflow #14491
          this.matchedEverchronProfiles[fileProfile[0]] = value
          this.checkedProfiles[fileProfile[0]] = !_.isEmpty(value)
          if (!this.isParty) {
            const new_status = !_.includes(this.witnesses, value)
            this.$store.dispatch('imports/setProfilesMatch', {text: replace ? value : fileProfile[0], match_data: {match_profile: value, isChecked: !_.isEmpty(value), key: this.tableType, new: new_status, modified: !namesSwapped } })
          } else {
            this.$store.dispatch('imports/setPartiesMatch', {text: fileProfile[0], match_data: {match_profile: value, isChecked: !_.isEmpty(value), key: this.tableType, modified: !namesSwapped }})
          }
          this.matchedEverchronProfiles = _.assign({}, this.matchedEverchronProfiles)
        }

        if (!skipValidation) // Not currently ever passed as true
          this.anyErrors()
      },

      anyErrors(){
        let err = false
        _.map(this.checkedProfiles, (value, key) => {
          if (this.checkedProfiles[key]){
            if (_.isEmpty(this.matchedEverchronProfiles[key])){
              return err = true
            }
          }
        })
        if (this.id == 'profilesMIS')
          setTimeout(() => { this.$store.commit('imports/profilesHasErrorsMIS', err) })
        else if (this.id == 'profilesCM')
          setTimeout(() => { this.$store.commit('imports/profilesHasErrorsCM', err) })
        else if (this.id == 'profilesREM')
          setTimeout(() => { this.$store.commit('imports/profilesHasErrorsREM', err) })
        else if (this.id == 'partiesMIS')
          setTimeout(() => { this.$store.commit('imports/partiesHasErrorsMIS', err) })
        else if (this.id == 'partiesCM')
          setTimeout(() => { this.$store.commit('imports/partiesHasErrorsCM', err) })
      },

      /*
      sortProfileNameText(a, b){
        if (a[0].toLowerCase() < b[0].toLowerCase()) {
          return -1
        }
        else if (a[0].toLowerCase() > b[0].toLowerCase()) {
          return 1
        }
        else {
          return 0
        }
      },
      */

      initiateAutoPopulation(){
        if (!this.not && !this.isParty){
          this.$store.dispatch('imports/autoPopulateProfiles', { matter_id: this.matter.id, profiles: this.items, key: this.tableType })
            .then(() => { this.completeAutoPopulation() })
        } else if (!this.not) {
          this.$store.dispatch('imports/autoPopulateParties', { matter_id: this.matter.id, parties: this.items, key: this.tableType })
            .then(() => { this.completeAutoPopulation() })
        }
      },

      completeAutoPopulation(){
        let worker = new Worker('/scripts/imports-validation-init-profiles.worker.js')
        const that = this
        const cloneFilteredItems = JSON.parse(JSON.stringify(this.filteredItems))
        const cloneCheckedProfiles = JSON.parse(JSON.stringify(this.checkedProfiles))
        const cloneMatchedEverchronProfiles = JSON.parse(JSON.stringify(this.matchedEverchronProfiles))
        worker.postMessage({ 
          table: this.id, 
          profiles: cloneFilteredItems,
          checked: cloneCheckedProfiles,
          matched: cloneMatchedEverchronProfiles
        })
        worker.onmessage = function (e){
          that.checkedProfiles = _.assign({}, e.data.checked)
          that.matchedEverchronProfiles = _.assign({}, e.data.matched)
          that.anyErrors()
          that.autoPopulationComplete = true
        }
        worker.onerror = function(e) {
          console.error(['ERROR: Line ', e.lineno, ' in ', e.filename, ': ', e.message].join(''))
        }
      },

      calculateNumMapped(){
        let num = 0
        if (this.id == 'profilesREM'){
          num = Object.keys(this.defaults).length
        }
        else {
          _.map(this.defaults, p => {
            if (p[1].isChecked == true && !_.isNull(p[1].match_profile) && !p[1].replacedBy)
              num++
          })
        }
        this.$store.commit('imports/numProfileMaps', { table: this.id, num })
      },

      initRemediateData(profile) {
        const orig = _.cloneDeep(_.find(this.defaults, item => item[0] == profile[0]))
        /*
        profile[0] = profile[0].replaceAll(',', ', ')
        profile[0] = profile[0].replaceAll('.', '. ')
        profile[0] = profile[0].replaceAll(':', ': ')
        profile[0] = profile[0].replace(/\s+/g, ' ')
        */
        profile[1]['orig'] = orig
        /*
        this.$store.dispatch('imports/setProfilesMatch', { text: orig[0], match_data: {
          match_profile: profile[0], isChecked: true, key: this.tableType,
          new: true, modified: false }, replacing: orig[0], orig
        })

        const p = this.profilesFromFile
        const i = _.findIndex(p, ['profile', orig[0]])
        const template = _.clone(p[i])
        let newProfile = _.clone(template)
        newProfile.profile = profile[0]
        p.push(newProfile)
        this.$store.commit('imports/profilesFromFile', p)
        */

        this.remediateProfile = profile
        this.remediateDataShow = true
      },

      remediateComplete(parsedItems, activeCharacters, activeSpaces, outputExists, outputValid){
        const orig = this.remediateProfile[1].orig // _.cloneDeep(_.find(this.defaults, item => item[0] == this.remediateProfile[0]))
        const p = this.profilesFromFile
        const i = _.findIndex(p, ['profile', this.remediateProfile[0]])
        let csv = _.cloneDeep(this.csv)
        let replacedWith
        /*
        let remediatedItems = []
        parsedItems = _.each(outputValid, v => {
          remediatedItems.push([v.trim()])
        })
        */

        /* For Mocha test updates
        console.info('remediate:orig', orig)
        */

        if (i >= 0){
          ({ csv, replacedWith } = Remediate.RemediateProfiles(csv, activeCharacters, activeSpaces, orig, this.dataFieldsAssigned, this.profileFields))

          /* For Mocha test updates
          console.info('remediate : csv : now', csv)
          console.info('replacedWith : now', replacedWith)
          console.info('remediate:remediateProfile[1].orig', this.remediateProfile[1].orig)
          */

          this.$store.commit('imports/csvContent', csv)

          const template = _.clone(p[i])
          const newProfiles = []
          _.each(parsedItems, pi => {
            _.each(pi, parsedName => {
              let newProfile = _.clone(template)
              parsedName = parsedName.trim()
              if (!_.some(this.existingProfiles, ['text', parsedName.trim()]) && parsedName != ''){
                newProfile.profile = parsedName
                newProfile.orig = orig
                newProfiles.push(newProfile)
                this.addProfile(orig, parsedName, true)
                this.$store.dispatch('imports/setProfilesMatch', { text: parsedName, match_data: {
                  match_profile: parsedName, isChecked: true, key: 'REM',
                  new: true, modified: false }, replacing: orig[0], orig, replacedWith
                })
              }
              else {
                this.$store.commit('notifications/clear')
                this.$store.commit('notifications/add', { message: `The following adjusted profiles skipped as duplicative: ${ outputExists.join(', ') }` })
              }
            })
          })
          p.splice(i, 0, ...newProfiles)
          this.$store.commit('imports/profilesFromFile', p)
          this.remediateCleanup()
        }
        else {
          console.error('not found in array', this.remediateProfile, p)
        }

        const new_status = !_.includes(this.witnesses, orig[0])
        this.$store.dispatch('imports/setProfilesMatch', { text: orig[0], match_data: {
          match_profile: orig[1].match_profile, isChecked: false, key: this.tableType, new: new_status, 
          modified: false }, replacing: orig[0], orig, replacedWith, replacedBy: replacedWith
        })
      },

      /*
      undoRemediation(profile){
        const replacedBy = profile[1].replacedBy
        csv = Remediate.UndoRemediateProfiles(csv, profile, replacedBy, this.dataFieldsAssigned, this.profileFields)
        this.$store.commit('imports/csvContent', csv)

        // For Mocha test Updates
        console.info('undo:csv:now', csv)
        //

        // Remove relevant rows from profilesREM
        const toRemoveFromREM = replacedBy.split(';')
        const rem = this.profilesREM
        _.each(toRemoveFromREM, rmv => {
          delete rem[rmv.trim()]
        })
        this.$store.commit('imports/resetProfileChangesREM', rem)

        // Remove relevant rows from profilesFromFile
        const pff = _.cloneDeep(this.profilesFromFile)
        _.each(toRemoveFromREM, rmv => {
          const io = _.findIndex(this.origProfilesFromFile, ['profile', rmv.trim()])
          if (io >= 0)
            return

          const i = _.findIndex(pff, ['profile', rmv.trim()])
          if (i >= 0)
            pff.splice(i, 1)
        })
        this.$store.commit('imports/profilesFromFile', pff)
        
        // Restore relevant row to table
        profile[1].replacedBy = null
        profile[1].isChecked = true
        const new_status = !_.includes(this.witnesses, profile[0])
        this.$store.dispatch('imports/setProfilesMatch', { text: profile[0], match_data: {
          match_profile: profile[0], isChecked: true, key: this.tableType, new: new_status, modified: false
        }, replacing:null, orig: null, replacedWith: null })

        this.anyErrors()
      },
      */

      remediateCleanup(){
        this.remediateProfile = null
        this.remediateDataShow = false
      }
    },

    mounted(){
      this.initiateAutoPopulation()
      this.calculateNumMapped()
      this.origProfilesFromFile = _.cloneDeep(this.profilesFromFile)
    },
    
    watch: {
      /* Unsure what this accomplished, but caused issues for #14942, so have removed, hoping to see if any ill-consequences arise
      items: {
        handler(n, old){
          if (n.length != old.length)
            this.initiateAutoPopulation()
        },
        deep: true
      },
      */

      defaults: {
        handler(n, old){
          this.calculateNumMapped()
          if (this.tableType == 'MIS' && this.autoPopulationComplete){
            if (this.isParty)
              this.$emit('showPartiesMis', !!(this.defaults && this.defaults.length))
            else
              this.$emit('showProfilesMis', !!(this.defaults && this.defaults.length))
          }
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  .ecs-button:hover:disabled {
    opacity: .5;
  }
  .ecs-data-grid-cell-inner:hover .ecs-button:disabled  {
    opacity: .5;
  }
</style>
