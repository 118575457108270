.comment {
  margin-bottom: 20px;
}
.comment:last-child {
    margin: 0;
}
.ecs-comment-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFF;
  border-radius: 3px;
  transition: .2s;
  position: relative;
}
.ecs-comment-form .medium-editor-element {
    flex: 1;
    overflow: auto;
    border-radius: 4px 4px 0 0;
    position: relative;
    z-index: 1;
    max-height: 260px;
}
.ecs-comment-form-bar {
    border: 1px solid #CED2D8;
    border-radius: 0 0 4px 4px;
    margin-top: -1px;
    display: flex;
    justify-content: space-between;
    padding: 3px;
}
