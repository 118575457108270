.segment-height[data-v-32161cb2] {
  padding: 2px 0;
  flex: 1;
}
.filter-link[data-v-32161cb2] {
  color: var(--color-gray-9);
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  cursor: pointer;
}
