<template>
  <div class="import-tab">
    <ecs-flex-row>
      <ecs-flex-col grow>
        <ecs-text-v2 preset="label-1" weight="medium">Map People &amp; Entity Profiles</ecs-text-v2>
        <ecs-text-v2 preset="form" class="mt-1">Map people and entity references extracted from the load file to Everchron Profiles.</ecs-text-v2>
      </ecs-flex-col>
    </ecs-flex-row>

    <ecs-empty-state v-if="!profilesReady" type="custom" style="height: 320px;">
      <img src="/scripts/loader.gif" class="loader-gif" />
      Processing data&hellip;
    </ecs-empty-state>

    <div v-show="profilesReady">
      <div>
        <ecs-empty-state v-if="noProfiles" type="empty-input-profiles">
          There are no values in this load file mapped to Profile Fields. Click "{{ proceedText }}" below to proceed.
        </ecs-empty-state>

        <div v-else>
          <ec-profiles-table v-if="profilesRemLength" :items="profilesREM" bordered tableType="REM" id="profilesREM" class="mb-6" />

          <ec-profiles-table v-if="profilesMIS.length && profilesMisShow" :items="profilesMIS" @showProfilesMis="showProfilesMis" bordered tableType="MIS" id="profilesMIS" class="mb-6" />

          <ec-profiles-table v-if="partiesMIS.length && partiesMisShow" :items="partiesMIS" @showPartiesMis="showPartiesMis" :isParty="true" bordered tableType="MIS" id="partiesMIS" class="mb-6" />

          <div v-if="profilesCM.length || partiesCM.length" class="import-expand">
            <ecs-collapse
              v-if="profilesCM.length"
              content-padding="none" headline-indent="sml" id="profileFieldsCommon"
              :persist="false" :border-bottom="partiesCM.length > 0" :title="profileChangesCmLen + ` Previously-Mapped Profiles in Load File`"
            >
              <ec-profiles-table :items="profilesCM" tableType="CM" :parent="this" id="profilesCM" :hasBorder="false" />
            </ecs-collapse>
            <ecs-collapse
              v-if="partiesCM.length"
              content-padding="none" headline-indent="sml" id="partyFieldsCommon"
              :persist="false" :title="partiesCM.length + ` Previously-Mapped Parties in Load File`"
            >
              <ec-profiles-table :items="partiesCM" :isParty="true" tableType="CM" :parent="this" id="partiesCM" :hasBorder="false" />
            </ecs-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import EcProfilesTable from './profiles-table'

  export default {
    components: { EcProfilesTable },

    props: {
      isLastStep : Boolean
    },

    data(){
      return {
        percentAutoPopulateProfilesComplete: 0,
        profilesMisShow : true,
        partiesMisShow : true
      }
    },

    computed: {
      profilesCM() {
        return this.$store.getters['imports/profilesExistedInSettings']
      },

      profilesMIS() {
        return this.$store.getters['imports/profilesMissedInSettings']
      },

      profilesREM() {
        return this.$store.getters['imports/profileChangesREM']
      },

      profilesRemLength(){
        if (!this.profilesREM) return false
        return Object.keys(this.profilesREM).length
      },

      profileChangesCmLen(){
        const pccm = this.$store.getters['imports/profileChangesCM']
        return Object.keys(pccm).length
      },

      partiesCM() {
        return this.$store.getters['imports/partiesExistedInSettings']
      },

      partiesMIS() {
        return this.$store.getters['imports/partiesMissedInSettings']
      },

      noProfiles(){
        return _.isEmpty(this.profilesCM) && _.isEmpty(this.profilesMIS) && _.isEmpty(this.profilesREM) && _.isEmpty(this.partiesCM) && _.isEmpty(this.partiesMIS)
      },

      percentMapped(){
        return this.$store.getters['imports/percentProfilesAutoPopulated']
      },

      profilesReady(){
        return this.profilesLoaded && this.profilesAutopopulatedMIS && this.profilesAutopopulatedCM
      },

      profilesLoaded(){
        return this.$store.getters['imports/profilesLoaded']
      },

      profilesAutopopulatedCM(){
        return this.$store.getters['imports/profilesAutopopulatedCM']
      },

      profilesAutopopulatedMIS(){
        return this.$store.getters['imports/profilesAutopopulatedMIS']
      },

      proceedText(){
        return this.isLastStep ? 'Initiate Import' : 'Continue'
      }
    },

    methods:{
      showProfilesMis(bool){
        this.profilesMisShow = bool
      },

      showPartiesMis(bool){
        this.partiesMisShow = bool
      }
    },

    watch: {
      profilesMIS: {
        handler(){
          if (this.profilesMIS.length == 0)
            this.$store.commit('imports/numProfileMaps', { table: 'profilesMIS', num: 0 })
        },
        deep: true
      },

      partiesMIS: {
        handler(){
          if (this.partiesMIS.length == 0)
            this.$store.commit('imports/numProfileMaps', { table: 'partiesMIS', num: 0 })
        },
        deep: true
      },

      profilesCM: {
        handler(){
          if (this.profilesCM.length == 0)
            this.$store.commit('imports/numProfileMaps', { table: 'profilesCM', num: 0 })
        },
        deep: true
      },

      partiesCM: {
        handler(){
          if (this.partiesCM.length == 0)
            this.$store.commit('imports/numProfileMaps', { table: 'partiesCM', num: 0 })
        },
        deep: true
      },

      profilesREM: {
        handler(){
          if (this.profilesREM.length == 0)
            this.$store.commit('imports/numProfileMaps', { table: 'profilesREM', num: 0 })
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  .file-list {
    margin-top: 20px;
  }
</style>
