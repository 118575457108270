#excerpts-form-check-label[data-v-5fe8a263] {
  padding-left: 30px;
}
.form-container[data-v-5fe8a263] {
  background: #FFF;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-10);
  margin: 0 -10px 12px;
}
