.step[data-v-33af2841] {
  padding: 30px 0;
  min-height: 100%;
}
.step-flex[data-v-33af2841] {
    display: flex;
    flex-direction: column;
}
.citations-list[data-v-33af2841] {
  border-radius: 4px;
  border: 1px solid #D7DAE1;
  padding: 8px;
  height: 100%;
  flex: 1;
}
.citation[data-v-33af2841] {
  font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 10px;
  letter-spacing: -.03em;
  white-space: pre-wrap;
  color: #65687a;
}
.citation-line[data-v-33af2841] {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  /* The order of the .warning, .error, .edited, and .skipped definitions here matters */
  /*
  &.warning{
    .cite{
      background-color: #FFF9E4;
    }

    &:hover .cite,
    .cite:focus{
      background-color: #FAECD7;
    }
  }

  &.edited{
    .cite{
      background-color: #F3F4F7;
    }

    &:hover .cite,
    .cite:focus{
      background-color: #ECEDF2;
    }
  }
  */
}
.citation-line[data-v-33af2841]:last-child {
    margin-bottom: 0;
}
.citation-line .cite[data-v-33af2841] {
    flex: 1;
    border-radius: 3px;
    font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 14px;
    padding: 4px;
    transition: .2s;
}
.citation-line > .icon-wrap[data-v-33af2841],
  .citation-line .popover-button[data-v-33af2841] {
    margin-left: 8px;
}
.citation-line.skipped[data-v-33af2841] {
    /*
    &:hover .cite,
    .cite:focus{
      background-color: #ECEDF2;
    }
    */
}
.citation-line.skipped .cite[data-v-33af2841] {
      /* background-color: #F3F4F7; */
      color: rgba(47, 51, 60, 0.6);
}
.citation-line.error .cite[data-v-33af2841] {
    background-color: #FFF3F5;
}
.citation-line.error:hover .cite[data-v-33af2841],
  .citation-line.error .cite[data-v-33af2841]:focus {
    background-color: #FFE9EC;
}
.hide[data-v-33af2841] {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
