<template>
  <div class="toolbar">
    <ec-search v-bind="{ loading, namespace, matterId, totalCount: count, totalLabel: searchLabel, placeholder }" class="col-9 pr-3">
      <template v-slot:buttons>
        <ecs-button-toolbar @click="toggleImportant" :active-highlight="importantSearch" 
          icon="star" icon-size="18" icon-color="#858E9E" 
          v-ec-tooltip="{ content: 'Filter for Important Profiles', delay: [750, 5], placement: 'right' }"
          data-test="important-search-button" />
      </template>

      <template v-slot:type>
        Profiles
      </template>
    </ec-search>

    <div class="col-3 pl-3">
      <div class="toolbar-right-section">
        <ecs-button-toolbar-icon v-if="showToggleActionBar" @click="toggleActionbar" :icon="bulkButtonIcon" :active="showActions" v-ec-tooltip="{ content: 'Bulk actions', delay: [750, 5] }" class="mr-3" data-test="bulk-actions-button" />
      </div>

      <div class="toolbar-right-section">
        <ec-popover v-if="!isIssueProfiles" class="ml-3">
          <ecs-button-toolbar-icon icon="toolbar-settings" v-ec-tooltip="{ content: 'Settings', delay: [750, 5]}" data-test="settings-button" />
          <template v-slot:content>
            <div style="width:300px" data-test="settings-popover">
              <ecs-popover-list>
                <ecs-popover-list-headline>Directory Settings</ecs-popover-list-headline>
                <ecs-popover-list-item @input="showNonRelevantToggle" :value="showNonRelevant" type="switch" data-test="show-non-relevant-switch">
                  Show Non-Relevant Profiles
                </ecs-popover-list-item>
                <ecs-popover-list-item @input="showDeponentBadgeToggle" :value="showDeponentBadge" type="switch" data-test="show-deponent-badge-switch">
                  Show Deponent Badge
                  <ecs-icon type="transcript" width="20" height="20" color="#A1A6B0" class="ml-1" />
                </ecs-popover-list-item>
              </ecs-popover-list>
            </div>
          </template>
        </ec-popover>

        <ec-popover v-else class="ml-3">
          <ecs-button-toolbar-icon icon="toolbar-settings" v-ec-tooltip="{ content: 'Settings', delay: [750, 5]}" data-test="settings-button" />
          <template v-slot:content>
            <div style="width:300px" data-test="settings-popover">
              <ecs-popover-list>
                <ecs-popover-list-headline>Directory Settings</ecs-popover-list-headline>
                <ecs-popover-list-item @input="showTagColorsToggle" :value="showTagColors" type="switch" data-test="show-tag-colors-switch">
                  Show Tag Colors
                </ecs-popover-list-item>
              </ecs-popover-list>
            </div>
          </template>
        </ec-popover>
        
        <ec-popover>
          <ecs-button-toolbar-icon icon="toolbar-sort" v-ec-tooltip="{ content: 'Sort by', delay: [750, 5]}" data-test="sort-button" class="ml-3" />
          <template v-slot:content>
            <div class="width-240" data-test="sort-popover">
              <ecs-popover-list v-for="sortGroup in sortGroups" :key="sortGroup.id">
                <ecs-popover-list-item v-for="option in sortGroup.options" @click="toggleSortField(option)" 
                  :key="option.field" type="sort" :sort="optionStyles(option)" :disabled="loading">
                  {{ option.name }}
                </ecs-popover-list-item>
              </ecs-popover-list>
            </div>
          </template>
        </ec-popover>

        <ecs-button v-if="!isIssueProfiles && matterPermissions['profiles-access_to_people_entities-manage']"
          @click="addNewProfile" :disabled="disableAddNew" type="create" icon="add-plus" class="ml-4" 
          v-ec-tooltip="{ content: 'Add a new profile', delay: [750, 5] }" data-test="add-profile-button">Add</ecs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import EcPopover     from 'ec-common/components/utility/popover'
  import EcSearch      from 'ec-common/components/utility/search'
  import ClosePopovers from 'ec-common/helpers/close-popovers'
  import ToolbarsMixin from 'ec-common/mixins/index-toolbars'

  export default {
    components: { EcPopover, EcSearch },
    mixins: [ToolbarsMixin],
    emits: ['toggleActionbar', 'toggleShowDeponentBadge', 'toggleShowNonRelevant', 'wipeProfile', 'toggleShowTagColors'],

    props: {
      totalCountProfiles : [Number, String],
      totalCountTags     : [Number, String],
      matterId           : [Number, String],
      loading            : Boolean,
      showActions        : Boolean,
      showDeponentBadge  : Boolean,
      matterPermissions  : { type: Object, default() { return {} }},
      showToggleActionBar: Boolean,
      showNonRelevant    : Boolean,
      showTagColors      : Boolean
    },

    data(){
      return {
        lastFieldSorted : null,
        order           : '',
        middle          : '',
        suffix          : '',
        createProfileUrl : { name: 'witnesses.new', params: { matterId: this.matterId }, hash: '#profile' }
      }
    },

    computed: {
      importantSearch() {
        return _.findIndex(this.filters, ['id', 'important|true']) >= 0
      },

      selected() {
        return this.$store.getters[`${ this.namespace }/selected`]
      },

      sort() {
        return this.isIssueProfiles ? this.$store.getters['tags/sort'] : this.$store.getters['witnesses/sort']
      },

      filters() {
        return this.$store.getters[`${ this.namespace }/filters`]
      },

      count(){
        return this.isIssueProfiles ? this.totalCountTags : this.totalCountProfiles // - 1 // (omit Court from count)
      },

      isIssueProfiles() {
        return this.$store.getters['witnesses/isIssueProfiles']
      },

      bulkButtonIcon(){
        return this.isIssueProfiles ? 'toolbar-bulk-issues' : 'toolbar-bulk-profiles'
      },

      namespace(){
        return this.isIssueProfiles ? 'tags' : 'witnesses'
      },

      placeholder(){
        return this.isIssueProfiles ? 'Filter by name, importance, and tag sets...' : 'Filter by name, groups, side, and keywords...'
      },

      localStorageSortKey(){
        return this.isIssueProfiles ? 'index-sort-issues' : 'index-sort-profiles'
      },

      sortGroups(){
        return this.isIssueProfiles ? [
          {
            options: [
              {
                name  : 'Name',
                field : 'name.raw',
                dir   : 'asc'
              }
            ]
          }] : [{
            options: [
              {
                name  : 'Name',
                field : 'name.raw',
                dir   : 'asc'
              }, {
                name  : 'Date of Birth/Formation',
                field : 'dob',
                dir   : 'asc'
              }
            ]
          }
        ]
      },

      searchLabel(){
        return this.isIssueProfiles ? 'Issues' : 'Profiles'
      },

      disableAddNew(){
        return this.$store.getters['witnesses/disableAddNew']
      }
    },

    methods: {
      toggleImportant() {
        const action = this.importantSearch ? 'delFilter' : 'setFilter'
        this.$store.commit(`${ this.namespace }/${ action }`, {
          id    : 'important|true',
          field : 'important',
          key   : 'true',
          text  : 'Important'
        })
      },

      toggleActionbar() {
        if (!_.isEmpty(this.selected)) this.$store.commit(`${ this.namespace }/clearSelected`)
        this.$emit('toggleActionbar')
        ClosePopovers(this)
      },

      toggleSortField(option) {
        let data = {}
        if (this.lastFieldSorted == option.field){
          data = _(option).pick(['field', 'dir']).merge({
            dir: (this.sort.dir === 'asc' ? 'desc' : 'asc')
          }).value()
        }
        else {
          data = _(option).pick(['field', 'dir']).value()
        }
        this.lastFieldSorted = option.field
        localStorage.setItem(this.localStorageSortKey, JSON.stringify(data))
        this.$store.commit(`${ this.namespace }/sort`, data)
      },

      isActiveSortGroup(sortGroup) {
        return !!sortGroup.options.find(o => o.field === this.sort.field)
      },

      optionStyles(option) {
        if (this.sort.dir === 'desc' && this.sort.field === option.field)
          return 'descending'
        else if (this.sort.dir === 'asc' && this.sort.field === option.field)
          return 'ascending'
        else
          return null
      },

      refreshIndex(){
        this.$bus.$emit('refresh', 'witnesses')
        this.$store.commit(`${ this.namespace }/clearSelected`)
      },

      getLocalStorageSortOption(){
        let sortAuthority
        if (localStorage.getItem(this.localStorageSortKey)){
          sortAuthority = JSON.parse(localStorage.getItem(this.localStorageSortKey))
          if (!sortAuthority || !sortAuthority.field || !_.includes(this.validSortOptions, sortAuthority.field))
            sortAuthority = { field: 'date', dir: 'asc' }
        }
        else {
          sortAuthority = { field : 'name.raw', dir : 'asc' }
          localStorage.setItem(this.localStorageSortKey, JSON.stringify(sortAuthority))
        }
        this.lastFieldSorted = sortAuthority.field
        this.$store.commit(`${ this.namespace }/sort`, sortAuthority)
      },

      showDeponentBadgeToggle(bool){
        this.$emit('toggleShowDeponentBadge', bool)
      },

      showNonRelevantToggle(bool) {
        localStorage.setItem(`witnesses-non-relevant-show-${ this.matterId }`, bool)
        this.$emit('toggleShowNonRelevant', bool)
      },

      showTagColorsToggle(bool) {
        localStorage.setItem(`witnesses-tag-colors-show-${ this.matterId }`, bool)
        this.$emit('toggleShowTagColors', bool)
      },

      addNewProfile(){
        this.$emit('wipeProfile')
        this.$router.push(this.createProfileUrl)
      }
    },

    watch: {
      isIssueProfiles(){
        this.getLocalStorageSortOption()
      }
    },

    created() {
      this.getLocalStorageSortOption()
    },

    mounted() {
      if (this.matterPermissions['profiles-access_to_people_entities-manage']){
        Mousetrap.bind('shift+n', (e) => {
          if (!this.isIssueProfiles)
            this.$router.push(this.createProfileUrl)
        })
      }

      const snr = localStorage.getItem(`witnesses-non-relevant-show-${ this.matterId }`)
      if (!_.isNil(snr))
        this.showNonRelevantToggle(snr == 'true')

      const tagColors = localStorage.getItem(`witnesses-tag-colors-show-${ this.matterId }`)
      if (!_.isNil(tagColors))
        this.showTagColorsToggle(tagColors == 'true')
    },

    beforeUnmount() {
      if (this.matterPermissions['profiles-access_to_people_entities-manage'])
        Mousetrap.unbind(['shift+n'])
    }
  }
</script>

<style scoped>
  /* Hiding the bulk action icon from Issue Profiles until we introduce bulk actions into the section. */
  .tags-bulk {
    display: none !important;
  }
</style>
