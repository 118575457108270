.slide-panel-enter-active[data-v-4d4d3e2e],
.slide-panel-leave-active[data-v-4d4d3e2e] {
  flex: 1;
}
.slide-panel-enter[data-v-4d4d3e2e],
.slide-panel-leave-to[data-v-4d4d3e2e] {
  flex: 0 !important;
}
.slide-panel[data-v-4d4d3e2e] {
  box-shadow: 0 0 0 1px rgba(90, 97, 102, 0.08), 0 0 40px 0 rgba(90, 97, 102, 0.15), 0 2px 10px 0 rgba(90, 97, 102, 0.1), 0 1px 2px 0 rgba(90, 97, 102, 0.1);
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: column;
}
.connectors[data-v-4d4d3e2e] {
  background: #FFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--spacing-10);
  font-size: 12px;
}
.connectors .ecs-toolbar-button[data-v-4d4d3e2e] {
    font-family: var(--font-family-monospace);
    font-weight: var(--font-weight-medium);
    font-size: var(--type-scale-2-font-size);
}
.connectors .ecs-toolbar-button.not[data-v-4d4d3e2e] {
      color: var(--color-red-12);
}
.connectors .ecs-toolbar-button.not[data-v-4d4d3e2e]:hover {
        border-color: var(--color-red-5);
}
.filters-button[data-v-4d4d3e2e] {
  position: absolute !important;
  top: 1px;
  right: 8px;
}
.reference[data-v-4d4d3e2e] {
  overflow: auto;
  flex: 1;
  font-size: 12px;
}
.reference p[data-v-4d4d3e2e], .reference ul[data-v-4d4d3e2e], .reference ol[data-v-4d4d3e2e] {
    margin: 10px 0;
}
.reference h4[data-v-4d4d3e2e] {
    font-weight: 600;
    margin: 20px 0 10px 0;
}
.reference .subtle[data-v-4d4d3e2e] {
    color: #858e9e;
}
.reference .ecs-collapsable-content > *[data-v-4d4d3e2e]:first-child {
    margin-top: 0;
}
.reference .ecs-collapsable-content > *[data-v-4d4d3e2e]:last-child {
    margin-bottom: 0;
}
