.list-draggable li[data-v-c38b7100] {
  background-color: var(--color-white);
}
.list-draggable li.sortable-chosen[data-v-c38b7100] {
    border-radius: var(--border-radius-medium);
    cursor: grabbing;
}
.list-draggable li.sortable-ghost[data-v-c38b7100] {
    background-color: var(--color-blue-3);
    opacity: .6;
}
