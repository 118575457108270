.scroller[data-v-a3230366] {
  height: 100%;
}
.profiles-sidebar[data-v-a3230366] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.profiles-sidebar-list[data-v-a3230366] {
    flex: 1;
}
.profiles-sidebar-footer[data-v-a3230366] {
    padding: var(--spacing-5) var(--spacing-10);
    background: var(--color-white);
    flex-shrink: 0;
}
.profiles-sidebar-header[data-v-a3230366] {
  padding-bottom: var(--spacing-5);
  background: var(--color-white);
  flex-shrink: 0;
}
.profiles-sidebar-header-segment[data-v-a3230366] {
    padding: var(--spacing-10) var(--spacing-15);
}
