.folder-selector[data-v-557f4628] {
  margin-right: 8px;
}
.folder-edit[data-v-557f4628] {
  border-radius: 4px;
  margin: 0 8px 0 -4px;
  padding: 0 4px;
  transition: .2s;
  cursor: pointer;
}
.folder-edit[data-v-557f4628]:hover {
    background: #F3F4F7;
}
.tree-indent[data-v-557f4628] {
  padding-left: 32px;
}
