.exhibit-link-mark[data-v-00359ecb] {
  position: absolute;
  cursor: pointer;
}
.exhibit-link-mark-background[data-v-00359ecb] {
    background: #0B71EB;
    mix-blend-mode: screen;
    position: absolute;
    inset: 0;
}
.exhibit-link-mark-border[data-v-00359ecb] {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #A7CFFF;
}
