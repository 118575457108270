.objection-cell[data-v-457fe456] {
  row-gap: 8px;
  width: 100%;
}
.objection-cell.reduced-padding[data-v-457fe456] {
    margin: -3px 0;
}
.objection-cell .ecs-form-check[data-v-457fe456] {
    flex: 1;
}
.ecs-button[data-v-457fe456]:hover:disabled {
  opacity: .5;
}
.ecs-data-grid-cell-inner:hover .ecs-button[data-v-457fe456]:disabled {
  opacity: .5;
}
[data-v-457fe456]::deep(.objection-cell .ecs-form-check-label) {
  white-space: nowrap;
}
