.membership-sidebar[data-v-257b3dbc] {
  position: relative;
}
.membership-sidebar > .ecs-button[data-v-257b3dbc] {
    position: absolute;
    left: 4px;
    top: 4px;
}
.invite-form[data-v-257b3dbc] {
  padding: var(--spacing-20);
}
.everchron-member[data-v-257b3dbc] {
  display: none;
  color: #FFF;
  padding: 0 2px;
  border-radius: 16px;
  background: var(--color-blue-10);
}
.table-warning .everchron-member[data-v-257b3dbc] {
  display: inline-flex;
}
