<template>
  <div class="matter-fields">
    <div class="matter-fields-header">
      <div class="left">
        <ecs-text-v2 preset="headline-5">{{ tabHeadline }}</ecs-text-v2>
      </div>
      <ecs-tab-bar type="segment">
        <ecs-tab-button @click="setFieldsTab(0)" :show="fieldsActiveTab == 0">Custom</ecs-tab-button>
        <ecs-tab-button @click="setFieldsTab(1)" :show="fieldsActiveTab == 1">Values</ecs-tab-button>
      </ecs-tab-bar>
      <div class="right">
        <ecs-icon v-if="loading || waiting" :spinning="loading" type="loading" color="#929AA9" />
      </div>
    </div>

    <ecs-tabs fill>
      <ecs-tab :show="fieldsActiveTab == 0" flex>
        <div class="px-3 pt-4 d-flex flex-column flex-1">
          <ecs-empty-state v-if="(loading || waiting) && !refreshing" type="loading" />
          <ecs-empty-state v-else-if="noCustomFields" type="empty-custom-fields">
            No custom fields have been set up for this matter.
            <template v-slot:actions>
              <ecs-button v-if="isAdmin" @click="$router.push({ name: 'organization.settings', hash: '#fields' })">Add Custom Fields</ecs-button>
            </template>
          </ecs-empty-state>
          <template v-else>
            <span class="subline mb-4">
              Add fields to this matter by dragging them into the left column.
              <span v-if="isAdmin">To create new fields, navigate to the Fields tab of your <router-link to="/settings#fields">{{ label }}</router-link> page.</span>
            </span>
            <Form ref="fields_form" as="div" class="row flex-1 matter-fields-drag-wrapper">
              <div class="col-6 matter-fields-drag scrollbar scrollbar-sml">
                <h3 class="headline-section matter-fields-drag-header">Active Custom Fields</h3>
                <div class="tree-view-list">
                  <div>
                    <draggable id="activatedDropArea" tag="div" v-model="chronCustomFields" item-key="field" v-bind="optionsActive" @change="saveActiveFields($event)" :disabled="refreshing">
                      <template #item="{ element }">
                        <div class="tree-view-entry tree-view-entry-lg state-hover draggable">
                          <span class="title" :class="{ 'subtle': refreshing }">{{ element.name }}</span>

                          <!--<ecs-button @click="fieldChronologyInclusionToggle(field)" :type="fieldButtonType(field.chron_active)" icon-only icon="table" v-ec-tooltip="{ content: tooltip(field.enabled_custom_field_id)}" :disabled="refreshing || unclickable(field.enabled_custom_field_id)" class="ml-auto" />-->
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </div>
              <div class="matter-fields-separator"></div>
              <div class="col-6 matter-fields-drag scrollbar scrollbar-sml">
                <h3 class="headline-section matter-fields-drag-header">Available Custom Fields</h3>
                <div class="tree-view-list">
                  <div>
                    <draggable id="inactiveDropArea" element="div" v-model="availableCustomFields" item-key="custom_field_definition_id" v-bind="optionsInactive" :disabled="refreshing">
                      <template #item="{ element }">
                        <div class="tree-view-entry tree-view-entry-lg state-hover draggable">
                          <span class="title">{{ element.name }}</span>
                        </div>
                      </template>
                    </draggable>
                  </div>
                </div>
              </div>
            </Form>
          </template>
        </div>
      </ecs-tab>
      <ecs-tab :show="fieldsActiveTab == 1" flex>
        <aside class="field-types-sidebar scrollbar scrollbar-sml">
          <ecs-tree-list>
            <ecs-tree-list-item state="active" large icon="colored-field" icon-color="#157EFB">Objection Basis</ecs-tree-list-item>
          </ecs-tree-list>
        </aside>

        <section class="fields-list">
          <header class="fields-list-header">
            <div class="left">
              <ecs-checkbox @input="toggleAll" :value="allBasesChecked" :indeterminate="basesIndeterminate" class="mb-0" ref="basisSelect">{{ filteredObjectionBases.length }} Objection Bas{{ filteredObjectionBases.length == 1 ? 'i' : 'e' }}s</ecs-checkbox>
            </div>
            <div class="search" :class="searchActive ? 'active' : ''">
              <ecs-input-search :model-value="valueFilter" @update:modelValue="e => valueFilter = e" :show-clear="showSearchClear" type="subtle" placeholder="Type here to filter the list" />
            </div>
            <div class="right">
              <ecs-button @click="searchActive = !searchActive" :active="searchActive" :title="`${ searchActive ? 'Hide' : 'Show' } Filter Bar`" type="secondary" icon-only icon="search" />
              <ecs-button v-if="!creatingNewValue" @click="addNewValueInit" type="primary" icon="add-plus" class="ml-2">Add Value</ecs-button>
            </div>
          </header>

          <div class="fields-list-scroll scrollbar scrollbar-sml">
            <ecs-empty-state v-if="fetchingBases" type="loading" />
            <ecs-tree-list v-else-if="filteredObjectionBases.length">
              <ecs-tree-list-item v-for="basis in filteredObjectionBases" @click="selectBasis($event)" :key="basis.id" selectable large :after-label="basis.shorthand" :state="basisEditing && basisEditing.id == basis.id ? 'active' : 'default'">
                <template v-slot:control>
                  <ecs-checkbox @input="(checked, id) => { selectBasisCheck(checked, id) }" :value="isBasisChecked(basis.id)" :id="`basis-${ basis.id }`" />
                </template>
                {{ basis.value }}
              </ecs-tree-list-item>
            </ecs-tree-list>
            <ecs-empty-state v-else-if="valueFilter != ''" type="empty-custom-fields">
              No values match your search.
            </ecs-empty-state>
            <ecs-empty-state v-else type="empty-custom-fields">
              There are no values for Objection Bases. Get started and create or import values now.
              <template v-slot:actions>
                <ecs-button @click="creatingNewValue = true" icon="add-plus">Add Value</ecs-button>
              </template>
            </ecs-empty-state>
          </div>
        </section>

        <transition name="slide-panel">
          <div v-if="editingValue || creatingNewValue || basesSelected.length > 1" class="fields-edit-panel">
            <div class="fields-edit-panel-inner scrollbar scrollbar-sml scroll">
              <div class="fields-edit-panel-header">
                <h4>{{ multipleBasesSelected ? `${ basesSelected.length } Selected` : editingValue ? 'Edit Value' : 'Add Value' }}</h4>
                <div class="d-flex align-items-center">
                  <ecs-button @click="closeForm" type="secondary">Cancel</ecs-button>
                  <ecs-button @click="doneClick" :loading="savingBasis" :disabled="basisDisableSave" type="primary" class="ml-1">{{ creatingNewValue ? 'Create' : 'Done' }}</ecs-button>
                </div>
              </div>
              <div class="p-3">
                <template v-if="editingValue || creatingNewValue">
                  <ecs-form-group :error="missingValue" :error-text="missingValueText" class="mb-4">
                    <label for="objection_basis">Objection Basis</label>
                    <ecs-input @update:modelValue="modifyBasis" :model-value="editedBasis" id="objection_basis" maxlength="70" placeholder="Enter objection basis" subtle />
                  </ecs-form-group>
                  <ecs-form-group :error="invalidShorthand" :error-text="shorthandError" class="mb-4">
                    <label for="shorthand_value">Shorthand Value</label>
                    <ecs-input @update:modelValue="modifyShorthand" :model-value="editedShorthand" id="shorthand_value" placeholder="Enter shorthand code" subtle /><!-- @keyup="e => verifyShorthandLength(e)" -->
                  </ecs-form-group>
                </template>
                <div>
                  <ecs-button v-if="editingValue || multipleBasesSelected" @click="showDeleteValueDialog = true" :loading="deletingBasis" type="danger" icon="delete">Delete Value{{ multipleBasesSelected ? 's' : ''}}</ecs-button>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <ecs-dialog :show="showDeleteValueDialog" @cancel="showDeleteValueDialog = false">
          <ecs-dialog-header icon="field" icon-color="danger">Delete Field {{ values }}</ecs-dialog-header>
          <p>Are you sure you want to delete <span class="nowrap">{{ deleteText }}?</span></p>
          <!--
          <p>Are you sure that you want to delete this value? It has been applied to 5 Entries in this Matter. You can choose to change the value to a different, or Continue to blank the value.</p>
          <ecs-checkbox class="align-left mb-2">Change selected value to:</ecs-checkbox>
          <ecs-select type="subtle" class="mb-2">
            <option value="speculation">Speculation</option>
            <option value="other">Other</option>
          </ecs-select>
          -->
          <template v-slot:actions>
            <ecs-button-dialog @click="showDeleteValueDialog = false">Cancel</ecs-button-dialog>
            <ecs-button-dialog @click="destroy" type="danger" icon="delete">Delete {{ values }}</ecs-button-dialog>
          </template>
        </ecs-dialog>
      </ecs-tab>
    </ecs-tabs>
  </div>
</template>

<script>
  import draggable      from 'vuedraggable'
  import serverErrors   from 'ec-common/helpers/server-errors'
  import EcViewerCommon from 'ec-common/components/viewer/mixins/viewer-common'
  import axios          from 'axios'
  import { Form } from 'vee-validate'

  export default {
    components: { draggable, Form },
    mixins: [EcViewerCommon],

    data: () => ({
      loading               : false,
      waiting               : true,
      refreshing            : false,
      noFields              : false,
      maxNumChronActivated  : 4,
      chronCustomFields     : [],
      availableCustomFields : [],
      fieldsActiveTab       : 0,
      searchActive          : false,
      showDeleteValueDialog : false,
      objectionBases        : [],
      fetchingBases         : false,
      savingBasis           : false,
      deletingBasis         : false,
      basesSelected         : [],
      basisEditing          : {},
      creatingNewValue      : false,
      newBasis : { value: '', shorthand: null },
      missingValue          : false,
      invalidShorthand      : false,
      invalidShorthandTimer : null,
      duplicateShorthand    : false,
      valueFilter           : '',
      broadcaster           : null
    }),

    computed: {
      label () {
        return this.$store.getters['organization/orgSpelling'] + " Settings"
      },

      optionsInactive() {
        return {
          group: "customFields",
          ghostClass: "ghost",
          sort: false
        }
      },

      optionsActive() {
        return {
          group: "customFields",
          ghostClass: "ghost"
        }
      },

      user() {
        return this.$store.getters['me/profile']
      },

      isAdmin() {
        return this.user.role == "admin"
      },

      /*
      maxReached(){
        const activated = _.sumBy(this.chronCustomFields, (f) => f.chron_active)
        return activated >= 4
      },
      */

      tabHeadline() {
        return this.fieldsActiveTab == 0 ? "Custom Fields" : "Field Values"
      },

      editingValue(){
        return !_.isEmpty(this.basisEditing)
      },

      values(){
        return this.multipleBasesSelected ? 'Values' : 'Value'
      },

      numSelected(){
        return !!this.basesSelected.length ? this.basesSelected.length : ''
      },

      multipleBasesSelected(){
        return this.basesSelected && this.basesSelected.length > 1
      },

      multipleSelectedIncludeDefault(){
        const customs = []
        _.map(this.basesSelected, basisId => {
          const b = _.find(this.objectionBases, ['id', basisId])
          if (!_.isNil(b) && b.default)
            customs.push(basisId)
        })
        return !!customs.length
      },

      basesIndeterminate(){
        return this.basesSelected.length < this.objectionBases.length && !!this.basesSelected.length
      },

      allBasesChecked(){
        return !!this.objectionBases.length && (this.basesSelected && this.basesSelected.length == this.objectionBases.length)
      },

      editedBasis(){
        return this.editingValue ? this.basisEditing.value : this.creatingNewValue ? this.newBasis.value : ''
      },

      editedShorthand(){
        return this.editingValue ? this.basisEditing.shorthand : this.creatingNewValue ? this.newBasis.shorthand : null
      },

      missingValueText(){
        if (this.creatingNewValue){
          if (this.newBasis && this.newBasis.value != '' && !_.isNil(this.newBasis.value)){
            if (_.findIndex(this.objectionBases, ob => ob.value.toLowerCase() == this.newBasis.value.toLowerCase().trim()) > -1){
              this.missingValue = true
              return 'This value already exists'
            }
          }
        }
        else if (this.editingValue){
          const basis = _.find(this.objectionBases, ob => ob.value.toLowerCase() == this.basisEditing.value.toLowerCase().trim())
          if (!_.isEmpty(basis) && (basis.id != this.basisEditing.id)){
            this.missingValue = true
            return 'This value already exists'
          }
        }
        return this.missingValue ? 'Objection Basis cannot be blank' : ''
      },

      filteredObjectionBases(){
        if (this.valueFilter == '' || _.isNil(this.valueFilter))
          return this.objectionBases
        return _.filter(this.objectionBases, ob => _.includes(ob.value.toLowerCase(), this.valueFilter.toLowerCase()))
      },

      shorthandError(){
        return this.duplicateShorthand ? 'This shorthand value is already in use' : this.invalidShorthand ? 'The shorthand value must be unique and may only contain letters, numbers or parentheses, and a maximum of 8 characters.' : ''
      },

      deleteText(){
        if (this.multipleBasesSelected)
          return `the ${ this.numSelected } selected values`
        return 'this value'
      },

      noCustomFields(){
        if (_.isNil(this.matter.activated_custom_fields))
          return true
        return !this.matter.activated_custom_fields.length && !this.availableCustomFields.length && !this.chronCustomFields.length
      },

      basisDisableSave(){
        const obj = this.editingValue ? this.basisEditing : this.newBasis
        return this.missingValue || this.invalidShorthand || obj.value == ''
      },

      showSearchClear() {
        return this.valueFilter.length > 0;
      }
    },

    methods: {
      saveActiveFields(evt){
        if (evt.removed){
          this.deactivateField(evt.removed.element.enabled_custom_field_id)
        }
        else {
          this.loading = this.refreshing = true
          const activeFieldsArray = _.map(this.chronCustomFields, 'custom_field_definition_id')
          this.$store.dispatch('fieldsMatter/update', { matterId: this.matter.id, ids: activeFieldsArray })
          .then(resp => {
            this.$store.commit('notifications/add', { message: 'Custom Fields update saved successfully.' })
            this.$store.dispatch('matters/fetch', { id: this.matter.id })
            .then(resp => {
              this.refreshChronCustomFields()
              if (evt.added){
                // Update this.chronCustomFields to include the enabled_custom_field_id
                const activatedField = _.find(this.chronCustomFields, ['custom_field_definition_id', evt.added.element.custom_field_definition_id])
                const newlyActivatedField = _.find(resp.body.activated_custom_fields, ['custom_field_definition_id', evt.added.element.custom_field_definition_id])
                const updatedActivatedField = _.merge(activatedField, newlyActivatedField)
                const idx = _.findIndex(this.chronCustomFields, ['custom_field_definition_id', evt.added.element.custom_field_definition_id])
                this.chronCustomFields.splice(idx, 1, updatedActivatedField)
              }
            })
          })
          .catch(err => serverErrors(this.$refs.fields_form, err))
          .finally(() => { this.loading = false })
        }
      },

      deactivateField(enabled_custom_field_id){
        this.loading = this.refreshing = true
        this.$store.dispatch('matters/deactivateCustomField', { matterId: this.matter.id, enabled_custom_field_id })
        .then(resp => { this.$store.dispatch('matters/fetch', { id: this.matter.id })
          .then(() => {
            this.refreshChronCustomFields()
          }).finally(() => { this.loading = false })
        })
        .catch(err => serverErrors(this.$refs.fields_form, err))
        .finally(() => { this.loading = false })
      },

      refreshChronCustomFields(){
        this.chronCustomFields = _.cloneDeep(this.matter.activated_custom_fields)
        setTimeout(() => { this.refreshing = false }, 20)
      },

      /*
      fieldChronologyInclusionToggle(field){
        if (this.fieldChronActive(field.enabled_custom_field_id)){
          this.setChronActive(field, false)
          this.saveChronActive(field.enabled_custom_field_id)
        } else {
          if (this.maxReached) {
            this.$store.commit('notifications/add', {
              message: `The maximum number of Custom Fields that can be chosen for inclusion in the Chronology is ${this.maxNumChronActivated}.`,
              isError: true
            })
          } else {
            this.setChronActive(field, true)
            this.saveChronActive(field.enabled_custom_field_id)
          }
        }
      },

      setChronActive(field, bool){
        this.chronCustomFields.find(fld => fld.enabled_custom_field_id == field.enabled_custom_field_id).chron_active = bool
      },

      saveChronActive(enabled_custom_field_id){
        this.$store.dispatch('matters/customFieldChronActiveToggle', { matterId: this.matter.id, enabled_custom_field_id })
        .then(resp => { this.$store.dispatch('matters/fetch', { id: this.matter.id } )})
        .catch(err => serverErrors(this.$validator, err))
      },

      tooltip(enabled_custom_field_id){
        if (this.fieldChronActive(enabled_custom_field_id))
          return 'This field is reflected in the Chronology Table. Click to remove.'
        else if (this.maxReached)
          return 'The Chronology Table is displaying the max number of custom fields (4). Remove an active field if you wish to add this one.'
        else
          return 'Click to add this field to the Chronology Table.'
      },

      unclickable(enabled_custom_field_id){
        return !this.fieldChronActive(enabled_custom_field_id) && this.maxReached
      },

      fieldChronActive(enabled_custom_field_id){
        const fld = _.find(this.chronCustomFields, { 'enabled_custom_field_id': enabled_custom_field_id })
        return fld ? fld.chron_active : false
      },

      fieldButtonType(chron_active){
        return chron_active ? 'primary' : 'secondary'
      },
      */

      setFieldsTab(idx){
        this.fieldsActiveTab = idx
        localStorage.setItem('matter-fields-tab', idx == 1 ? 'values' : 'custom')
      },

      toggleAll(){
        if (this.basesSelected.length == this.objectionBases.length)
          this.basesSelected = []
        else
          this.basesSelected = _.map(this.objectionBases, 'id')
      },

      closeForm(){
        this.resetNewBasis()
        this.basisEditing = {}
        this.creatingNewValue = false
        this.missingValue = false
        this.invalidShorthand = false
        this.duplicateShorthand = false
      },

      addNewValueInit(){
        this.basisEditing = {}
        this.creatingNewValue = true
        this.basesSelected = []
      },

      doneClick(){
        this.editingValue ? this.saveBasisChanges() : this.createNewBasis()
      },

      fetchObjectionBases(){
        this.fetchingBases = true
        axios.get(`${ process.env.API_ENDPOINT }/api/matters/${ this.matter.id }/objection_basis`)
        .then(resp => {
          this.objectionBases = _.orderBy(resp.body.data, ['value'], ['asc'])
        })
        .catch(err => {
          console.error('err', err)
          // this.errored = 'An error occurred fetching the import history. Please try again and contact support if the issue persists.'
        })
        .finally(() => {
          this.fetchingBases = false
        })
      },

      saveBasisChanges(){
        if (this.basisEditing.value == '' || _.isNil(this.basisEditing.value))
          return this.missingValue = true

        const basis = _.find(this.objectionBases, ['id', this.basisEditing.id])
        if (_.isEqual(basis, this.basisEditing)){
          this.closeForm()
        }
        else {
          if (!_.isEmpty(this.basisEditing.shorthand)){
            if (!this.validateShorthand(this.basisEditing.shorthand, this.basisEditing.id)){
              this.invalidShorthand = true
              return this.duplicateShorthand = true
            }
          }
          const data = {
            value     : this.basisEditing.value.trim(),
            shorthand : this.basisEditing.shorthand,
            default   : this.basisEditing.default,
            matter_id : this.matter.id
          }
          this.savingBasis = true
          axios.put(`${ process.env.API_ENDPOINT }/api/matters/${ this.matter.id }/objection_basis/${ this.basisEditing.id }`, data)
          .then(resp => {
            const idx = _.findIndex(this.objectionBases, ['id', resp.body.id])
            if (idx >= 0)
              this.objectionBases.splice(idx, 1, resp.body)
            this.closeForm()
            this.emitBasesReindex()
          })
          .catch(err => {
            if (err.body.error.message == 'Validation failed: Value has already been taken.'){
              this.$store.commit('notifications/add', {
                isError: true,
                message: `An objection basis with the name ${ data.value } already exists.`
              })
            }
            else {
              this.$store.commit('notifications/add', {
                isError: true,
                message: 'An error occurred editing this value. Please try again or contact support if the issue persists.'
              })
            }
          })
          .finally(() => {
            this.savingBasis = false
          })
        }
      },

      createNewBasis(){
        if (this.newBasis.value == '' || _.isNil(this.newBasis.value))
          return this.missingValue = true

        if (!_.isEmpty(this.newBasis.shorthand)){
          if (!this.validateShorthand(this.newBasis.shorthand, null)){
            this.invalidShorthand = true
            return this.duplicateShorthand = true
          }
        }

        const data = {
          value     : this.newBasis.value.trim(),
          shorthand : this.newBasis.shorthand,
          default   : false
        }
        this.savingBasis = true
        axios.post(`${ process.env.API_ENDPOINT }/api/matters/${ this.matter.id }/objection_basis`, data)
        .then(resp => {
          this.objectionBases.push(resp.body)
          this.objectionBases = _.orderBy(this.objectionBases, ['value'], ['asc'])
          this.valueFilter = ''
          this.closeForm()
          this.emitBasesReindex()
        })
        .catch(err => {
          console.error('err', err)
          this.$store.commit('notifications/add', {
            isError: true,
            message: 'An error occurred creating this value. Please try again or contact support if the issue persists.'
          })
        })
        .finally(() => {
          this.savingBasis = false
        })
      },

      selectBasis(e){
        const basis = _.find(this.objectionBases, ['value', e.target.innerText])
        this.basisEditing = _.cloneDeep(basis)
      },

      selectBasisCheck(checked, basesId){
        const id = parseInt(basesId.replace('basis-', ''))
        this.basisEditing = {}
        if (!checked){
          const idx = _.indexOf(this.basesSelected, id)
          if (idx >= 0)
            this.basesSelected.splice(idx, 1)
        }
        else {
          this.basesSelected.push(id)
          this.basesSelected = _.uniq(this.basesSelected)
        }
        if (this.basesSelected.length == 1){
          const basis = _.find(this.objectionBases, ['id', this.basesSelected[0]])
          this.basisEditing = _.cloneDeep(basis)
        }
      },

      isBasisChecked(id){
        return _.includes(this.basesSelected, id)
      },

      modifyBasis(input){
        const obj = this.editingValue ? this.basisEditing : this.newBasis
        obj.value = input || ''
        obj.value = obj.value.replace(/\s\s+/g, ' ')
        if (obj.value != '' && !_.isNil(obj.value))
          this.missingValue = false
      },

      modifyShorthand(input){
        const obj = this.editingValue ? this.basisEditing : this.newBasis
        const bak = obj.shorthand
        clearTimeout(this.invalidShorthandTimer)
        obj.shorthand = null
        this.invalidShorthand = false
        this.duplicateShorthand = false
        const regex = new RegExp(/^[\dA-Za-z()]+$/)
        const valid = regex.test(input)
        if (!valid || input.length > 8){
          this.$nextTick(() => {
            obj.shorthand = (!input ? input : bak)
          })
          this.invalidShorthandTimer = setTimeout(() => this.invalidShorthand = false, 5500)
          return this.invalidShorthand = true
        }
        else {
          this.invalidShorthand = false
          return obj.shorthand = input || null
        }
      },

      verifyShorthandLength(input){
        console.info('verifyShorthandLength', input)
      },

      validateShorthand(sh, disregardId){
        if (_.isNil(sh)) return true

        const shorthands = _.without(_.map(this.objectionBases, ob => {
          if (_.isNil(disregardId) || disregardId != ob.id)
            return _.isNil(ob.shorthand) ? null : ob.shorthand.toLowerCase()
        }), null, '', undefined)
        return !shorthands.includes(sh.toLowerCase())
      },

      destroy(){
        const ids = this.editingValue ? [this.basisEditing.id] : this.basesSelected
        _.each(ids, id => { this.destroyObjectionBasis(id) })
        this.emitBasesReindex()
      },

      destroyObjectionBasis(id){
        this.deletingBasis = true
        axios.delete(`${ process.env.API_ENDPOINT }/api/matters/${ this.matter.id }/objection_basis/${ id }`)
        .then(resp => {
          const idx = _.indexOf(this.basesSelected, id)
          if (idx >= 0)
            this.basesSelected.splice(idx, 1)
          const idxx = _.findIndex(this.objectionBases, ['id', id])
          if (idxx >= 0)
            this.objectionBases.splice(idxx, 1)
          this.closeForm()
        })
        .catch(err => {
          console.error('err', err)
          this.$store.commit('notifications/add', {
            isError: true,
            message: 'An error occurred deleting this value. Please try again or contact support if the issue persists.'
          })
        })
        .finally(() => {
          this.showDeleteValueDialog = false
          this.deletingBasis = false
        })
      },

      emitBasesReindex(){
        if (_.isNil(this.broadcaster))
          this.broadcaster = new BroadcastChannel('app-channel')
        this.broadcaster.postMessage({ 'reindexObjectionBases': true })
      },

      resetNewBasis(){
        this.newBasis = { value: '', shorthand: null }
      },

      fetchActiveCustomFields(){
        const custom_ids = _.map(this.matter.activated_custom_fields, 'custom_field_definition_id')
        this.$store.dispatch('viewer/fetchCustomFields', { scope: 'documents', custom_ids, matterId: this.matter.id })
        .then(result => {
          this.customFields = this.formatCustomFields(result)
        })
      },

      initialize(count){
        count = count + 1
        this.waiting = true
        if (_.isNil(this.matter.deactivated_custom_fields))
          return setTimeout(() => { this.initialize(count) }, 650)

        let inactiveFields = [...this.matter.deactivated_custom_fields]
        this.refreshChronCustomFields()
        this.availableCustomFields = inactiveFields.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))

        if (this.objectionBases.length == 0)
          this.fetchObjectionBases()

        this.fetchActiveCustomFields()
        setTimeout(() => { this.waiting = false }, 200)
      }
    },

    watch: {
      searchActive(is){
        if (!is) this.valueFilter = ''
      },

      editingValue(is){
        if (is) this.creatingNewValue = false
      },

      creatingNewValue(is){
        if (is){
          this.basisEditing = {}
          this.resetNewBasis()
        }
      }
    },

    created(){
      if (this.$route.query && this.$route.query.tab == 'objectionbases')
        this.fieldsActiveTab = 1
      else if (localStorage.getItem('matter-fields-tab') == 'values')
        this.fieldsActiveTab = 1
    },

    mounted() {
      this.initialize(0)
    }
  }
</script>

<style scoped>
  .ghost {
    opacity: 0.5;
    background: #DCECFF;
  }
  .tree-view-entry.draggable{
    cursor: move;
  }
  #activatedDropArea, #inactiveDropArea{
    min-height:200px
  }
</style>
