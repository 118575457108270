.items[data-v-42e2260d] {
  position: relative;
  border-radius: 0.25rem;
  background: white;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 0.9rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.item[data-v-42e2260d] {
  display: block;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 0.2rem 0.5rem;
}
.item.is-selected[data-v-42e2260d], .item[data-v-42e2260d]:hover {
    color: #0961EB;
    background: #E9F3FF;
}
