.keys-table[data-v-569e1750] {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.warning[data-v-569e1750] {
  cursor: help;
  display: flex;
}
.status[data-v-569e1750]:before {
  content: "";
  display: inline-block;
  width: var(--spacing-10);
  height: var(--spacing-10);
  border-radius: 50%;
}
.status-active[data-v-569e1750] {
  color: var(--color-green-12);
}
.status-active[data-v-569e1750]:before {
    background-color: var(--color-green-10);
}
.status-inactive[data-v-569e1750] {
  color: var(--color-red-12);
}
.status-inactive[data-v-569e1750]:before {
    background-color: var(--color-red-10);
}
.button-on-hover > .ecs-button[data-v-569e1750] {
  opacity: 0;
  margin: -2px 0 0 0;
}
.button-on-hover:hover > .ecs-button[data-v-569e1750] {
  opacity: 1;
}
.break-word[data-v-569e1750] {
  word-break: break-all;
}
.box[data-v-569e1750] {
  box-shadow: 0px 2px 4px 0px rgba(71, 75, 96, 0.15), 0px 0px 0px 1px rgba(71, 75, 96, 0.05);
  color: var(--color-gray-15);
  background: var(--color-white);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-10) var(--spacing-15);
  margin-bottom: var(--spacing-10);
  margin-left: -8px;
  margin-right: -8px;
  text-align: left;
}
.box.escape[data-v-569e1750] {
    max-height: 180px;
    overflow-y: auto;
}
.key[data-v-569e1750] {
  font-family: var(--font-family-monospace);
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
  font-weight: var(--font-weight-medium);
  word-break: break-word;
}
.key.small[data-v-569e1750] {
    font-size: var(--type-scale-2-font-size);
    line-height: var(--type-scale-2-line-height);
}
.key-headline[data-v-569e1750] {
  width: 100%;
}
