.designation-type[data-v-6e1d4e63] {
  padding: 2px 4px 2px 2px;
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  border-radius: var(--border-radius-medium);
}
.tags[data-v-6e1d4e63] {
  position: relative;
}
.player-frame[data-v-6e1d4e63] {
  max-width: 100%;
  border-radius: var(--border-radius-medium);
}
