.date-filters[data-v-b3cfc5e0] {
  border-radius: 4px;
  background: #f3f4f7;
}
.date-filters-inner[data-v-b3cfc5e0] {
    padding: 10px;
    box-shadow: 0 -1px 0 #f3f4f7;
}
.date-filters .ecs-collapsable-border-bottom[data-v-b3cfc5e0]:last-child {
    border-bottom: none;
    border-radius: 0 0 4px 4px;
}
.ecs-collapsable-content > .date-filters-inner[data-v-b3cfc5e0] {
  margin-top: -14px;
}
.ecs-button.add-filter[data-v-b3cfc5e0] {
  background: #ecedf2;
  border-radius: 0 0 4px 4px;
}
