<template>
  <div class="p-5">
    <div v-if="incomplete" class="error form-group"><small class="form-text">Please complete all fields before saving.</small></div>
    <Form ref="field_form" :validation-schema="fieldSchema" v-slot="{ errors }" as="div">
      <div class="form-group mb-4" :class="{ error: (incomplete && fieldName == '') }">
        <label for="field_name" class="required">Field Name<sup>*</sup></label>
        <Field v-model="fieldName" type="text" name="name" :validateOnBlur="false" :validateOnInput="true" v-slot="{ field }">
          <input v-bind="field" @keyup="valueChange('name', $event.target.value)" type="text" class="form-control form-control-subtle form-control-sml" placeholder="Enter the field name" id="field_name" ref="field_name" maxlength="255" />
          <small v-if="errors.name" class="form-text error-color">{{ errors.name }}</small>
          <small v-if="customErrorNameExists" class="form-text error-color">A field with this name already exists.<br/>Field names must be unique.</small>
        </Field>
      </div>

      <div class="form-group mb-5" :class="{ error: (incomplete && fieldSearchRestrictor == '') || errors.restrictor }">
        <label for="search_restrictor" class="required">Search Restrictor<sup>*</sup></label>
        <Field v-model="fieldSearchRestrictor" type="text" name="restrictor" :validateOnBlur="false" :validateOnInput="true" v-slot="{ field }">
          <input v-bind="field" @keyup="valueChange('search_syntax', $event.target.value.toLowerCase())" class="form-control form-control-subtle form-control-sml" placeholder="Enter the search restrictor" id="search_restrictor" maxlength="255" />
          <small v-if="errors.restrictor" class="form-text error-color">{{ errors.restrictor }}</small>
          <small v-if="customErrorRestrictorExists" class="form-text error-color">This search restrictor is in use by another field.<br/>Please enter a unique restrictor.</small>     
        </Field>
      </div>

      <div class="form-group mb-4">
        <label class="switch switch-sml switch-has-label">
          <input v-model="fieldMatterDefault" @change="valueChange('matter_default', $event.target.checked)" :disabled="!newField" type="checkbox" id="matter_default" />
          <div class="slide-switch" :class="{ disabled: !newField }"></div>
        </label>
        <label for="matter_default" :class="{ disabled: !newField }">Matter Default</label>
      </div>

      <div class="form-group mb-2 hidden">
        <label>Available On</label>
        <div>
          <label class="switch switch-sml switch-has-label disabled">
            <input :disabled="true" v-model="fieldDocTypeDocument" @change="valueChange('type_doc', $event.target.checked)" type="checkbox" id="type_doc" />
            <div class="slide-switch"></div>
          </label>
          <label for="type_doc" class="disabled">Documents</label>
        </div>
        <div>
          <label class="switch switch-sml switch-has-label">
            <input v-model="fieldDocTypeEvidence" @change="valueChange('type_evidence', $event.target.checked)" type="checkbox" id="type_evidence" />
            <div class="slide-switch"></div>
          </label>
          <label for="type_evidence">Chronology</label>
        </div>
        <div>
          <label class="switch switch-sml switch-has-label">
            <input v-model="fieldDocTypeMasterfile" @change="valueChange('type_masterfile', $event.target.checked)" type="checkbox" id="type_masterfile" />
            <div class="slide-switch"></div>
          </label>
          <label for="type_masterfile">Master File</label>
        </div>
      </div>
    </Form>

    <ecs-separator v-if="field.used || !newField" :margin="20" />
    <div v-if="field.used" v-ec-tooltip="{ content: 'This custom field is in use and cannot be deleted' }" style="display:inline-flex;">
      <ecs-button disabled type="danger" icon="delete">Delete Field</ecs-button>
    </div>
    <ecs-button v-else-if="!newField" @click="deleteField()" type="danger" icon="delete">Delete Field</ecs-button>
  </div>
</template>

<script>
  import { Form, Field } from 'vee-validate'
  import * as Yup from 'yup'
  import _ from 'lodash'

  const field_schema = Yup.object({
    name: Yup.string().trim().required('The name field is required'),
    restrictor: Yup.string().matches(/^[a-zA-Z0-9-_]+$/, 'The search restrictor field may only contain letters, numbers, dashes and underscores.')
  })

  export default {
    components    : { Form, Field },
    emits: ['field-edit', 'field-delete'],

    props: {
      field: {
        required : true,
        type     : Object
      },
      newField: Boolean,                   // indicates if this is a new field being added, or existing field being edited
      incomplete: Boolean,                 // allows form to persist values upon validation errors from the parent component
      inProgress: Boolean,                 // allows the form to know that entered values already exist
      customErrorNameExists: Boolean,      // parent tells component if any existing fields already have this name
      customErrorRestrictorExists: Boolean // parent tells component if any existing fields already have this restrictor
    },

    data: function () {
      return {
        fieldName: this.field.name || '',
        fieldSearchRestrictor: this.field.search_syntax.toLowerCase() || '',
        fieldMatterDefault: this.field.matter_default,
        fieldDocTypeDocument: true,
        fieldDocTypeEvidence: this.inArray('Evidence', this.field.record_type),
        fieldDocTypeMasterfile: this.inArray('MasterFile', this.field.record_type),
        fieldSchema : field_schema
      }
    },

    methods: {
      valueChange(field, value) {
        const validatedFields = { 'name': 'name', 'search_syntax': 'restrictor'}
        if (_.has(validatedFields, field)) {
          this.$refs.field_form.validateField(validatedFields[field]).then(data => {
            this.$emit('field-edit', field, value, data.valid)
          })
        }
        else {
          this.$refs.field_form.validate().then(data => {
            this.$emit('field-edit', field, value, data.valid)
          })
        }
      },

      deleteField(){
        this.$emit('field-delete', this.field.id)
      },

      inArray: function(needle, haystack) {
        var length = haystack.length
        for (var i = 0; i < length; i++) {
          if (haystack[i] == needle) return true
        }
        return false
      }
    },

    beforeMount() {
      if (this.newField && !this.inProgress){
        this.fieldName = '',
        this.fieldSearchRestrictor = '',
        this.fieldMatterDefault = false,
        this.fieldDocTypeDocument = true,
        this.fieldDocTypeEvidence = false,
        this.fieldDocTypeMasterfile = false
      }
    },

    mounted(){
      this.$refs.field_name.focus()
    },

    watch: {
      fieldSearchRestrictor(){
        this.fieldSearchRestrictor = this.fieldSearchRestrictor.toLowerCase()
      }
    }
  }
</script>

<style scoped>
  .error-color {
    color: #FF3750
  }
  .form-control-subtle, .form-control-subtle:focus {
    border: solid 1px rgba(90, 97, 102, 0.15);
  }
  .form-group.error {
    margin-bottom:18px;
  }
  .form-check {
    margin-bottom:8px;
  }
  .disabled {
    opacity: 0.35
  }
</style>
