.fix-box[data-v-8adc9e02] {
  border: 1px solid var(--color-gray-3);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-10) var(--spacing-15);
  width: 100%;
}
.fix-box-content[data-v-8adc9e02] {
    flex: 1;
}
.fix-box.disabled[data-v-8adc9e02] {
    background-color: var(--color-gray-1);
}
.fix-box.disabled .fix-box-content[data-v-8adc9e02] {
      opacity: 0.6;
}
