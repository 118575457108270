.prompt-input[data-v-08229155] {
  position: relative;
  margin: 0;
}
.ecs-form-control[data-v-08229155] {
  field-sizing: content;
  padding-bottom: 24px;
  max-height: 540px;
}
.length-counter[data-v-08229155] {
  position: absolute;
  right: 4px;
  bottom: 4px;
  border-radius: var(--border-radius-md);
  padding: var(--spacing-5);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(12px);
  z-index: 1;
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  pointer-events: none;
  color: var(--color-gray-8);
}
.length-counter > span[data-v-08229155]:first-child {
    color: var(--color-gray-10);
}
.length-counter.error[data-v-08229155] {
    color: var(--color-red-9);
}
.length-counter.error > span[data-v-08229155]:first-child {
      color: var(--color-red-12);
}
