.upload-progress[data-v-2f89a852] {
  font-size: 14px;
  padding: 16px 0 8px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid #ECEDF2;
  font-variant-numeric: tabular-nums;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.data-list-button[data-v-2f89a852] {
  margin: -4px 0;
}
.missing-file-indicator[data-v-2f89a852] {
  margin: -4px 4px -4px -4px;
  cursor: help;
}
