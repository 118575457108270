.capture[data-v-0176f3de] {
  padding: 24px;
}
.capture-optional[data-v-0176f3de] {
    padding: 0 24px 24px 24px;
}
.capture-optional .ecs-form-set[data-v-0176f3de]:not(:first-child) {
      margin-top: 24px;
}
label[data-v-0176f3de] {
  display: flex;
  line-height: var(--type-scale-3-line-height);
}
label.required[data-v-0176f3de]:after {
    content: "*";
    font-size: var(--type-scale-4-font-size);
    color: var(--color-gray-6);
    margin-left: 2px;
}
