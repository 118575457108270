.manage-members-card[data-v-1ef22cfa] {
  padding: var(--spacing-20);
}
.manage-members-card .member[data-v-1ef22cfa] {
    text-align: center;
    margin-bottom: var(--spacing-25);
}
.manage-members-card-avatar[data-v-1ef22cfa] {
    margin: 0 auto var(--spacing-20) auto;
}
.manage-members-card .name[data-v-1ef22cfa] {
    font-size: var(--type-scale-4-font-size);
    line-height: var(--type-scale-4-line-height);
    font-weight: var(--font-weight-medium);
}
.manage-members-card .title[data-v-1ef22cfa] {
    font-size: var(--type-scale-3-font-size);
    line-height: var(--type-scale-3-line-height);
    color: var(--color-gray-10);
    display: block;
    margin-top: var(--spacing-10);
}
.dialog-user[data-v-1ef22cfa] {
  background: #FFF;
  border-radius: var(--border-radius-medium);
  border: 1px solid var(--color-gray-3);
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.075);
}
.dialog-user-avatar[data-v-1ef22cfa] {
    margin: 0;
}
.dialog-user-name[data-v-1ef22cfa] {
    color: var(--color-gray-14);
}
.dialog-user-email[data-v-1ef22cfa] {
    color: var(--color-gray-10);
}
