.profile-transcript-table-head {
  font-size: var(--type-scale-2-font-size);
  font-weight: var(--font-weight-medium);
  line-height: var(--type-scale-2-line-height);
  padding: var(--spacing-5) var(--spacing-10);
  color: var(--color-gray-10);
}
.profile-transcript-table-row {
  flex: 1;
}
.profile-transcript-table-date {
  font-variant-numeric: tabular-nums;
  width: 80px;
}
.profile-transcript-table-headline {
  flex: 2;
  min-width: 0;
}
.profile-transcript-table-headline .type {
    flex-shrink: 0;
}
.profile-transcript-table-headline .headline {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.profile-transcript-table-deponent {
  flex: 1;
}
.profile-transcript-table-annotations {
  width: 72px;
}
.profile-transcript-table-item .ecs-structured-content-left {
  flex: 1;
}
.profile-transcript-table-item .profile-transcript-table-date {
  font-variant-numeric: tabular-nums;
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  color: var(--color-gray-9);
}
