.color-dot[data-v-91f45492] {
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
.ecs-button[data-v-91f45492]:hover:disabled {
  opacity: .5;
}
.ecs-data-grid-cell-inner:hover .ecs-button[data-v-91f45492]:disabled {
  opacity: .5;
}
