.profile-container[data-v-c703e868] {
  min-height: 100%;
}
.profile[data-v-c703e868] {
  flex: 1;
}
.profile-header[data-v-c703e868] {
    padding: var(--spacing-30) var(--spacing-40);
}
.profile-name[data-v-c703e868] {
    flex: 1;
}
.profile-issue-tag[data-v-c703e868] {
  font-size: var(--type-scale-6-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--type-scale-6-line-height);
}
.avatar-wrap[data-v-c703e868] {
  position: relative;
}
.deponent-badge[data-v-c703e868] {
  display: flex;
  position: absolute;
  bottom: -4px;
  right: -4px;
  padding: 2px;
  border-radius: var(--border-radius-small);
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(16px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
