.definition-label[data-v-783a97b4] {
  font-weight: 500;
}
.definition-label-subtle[data-v-783a97b4] {
  color: var(--color-gray-11);
}
.matter-number[data-v-783a97b4] {
  font-family: var(--font-family-monospace);
}
.subtle[data-v-783a97b4] {
  color: var(--color-gray-10);
}
