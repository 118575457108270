.popover-button[data-v-794e1d5c] {
  width: 100%;
}
.swatches[data-v-794e1d5c] {
  padding: 8px;
}
.color[data-v-794e1d5c] {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin: 0 8px 0 -4px;
  flex-shrink: 0;
}
