.sticky-button[data-v-2d50b312] {
  position: sticky;
  top: -8px;
  z-index: 2;
  backdrop-filter: blur(16px);
  margin-left: auto;
}
.sticky-button + .ecs-formatted[data-v-2d50b312] {
    margin-top: -24px;
}
.sticky-button + .ecs-formatted[data-v-2d50b312]:before {
      content: "";
      float: right;
      width: 70px;
      height: 24px;
}
