.wrapper[data-v-2d490c9e] {
  max-width: 100%;
  width: 100%;
}
.chron-summary-formatted[data-v-2d490c9e] {
  padding: 0 4px;
}
.source[data-v-2d490c9e] {
  padding: 0 0 0 4px;
}
.source span[data-v-2d490c9e] {
    color: var(--color-gray-10);
}
