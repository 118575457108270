<template>
  <ecs-card :progress="percent" class="mb-2">
    <ecs-flex-row justify="between" class="import-card-main">
      <div class="import-card-title">
        <ecs-flex-row  :gap="16">
          <ec-date-time :value="detail.created_at" format="informal" :withTime="true" />
          <ecs-separator type="vertical" height="24px" />
          <span>
            Transcript Annotation Import
            <span v-if="detail.user">by {{ detail.user.full_name }} [ID {{ formatted_id }}]</span>
          </span>
        </ecs-flex-row>
      </div>
      <ecs-flex-row :gap="16" class="import-card-meta">
        <ecs-flex-row :gap="4" class="import-card-status">
          <ecs-icon v-if="jobStatus == 'Completed'" type="all-check" size="24" color="#858E9E" />
          <ecs-icon v-else-if="jobStatus == 'Completed (Exceptions)' || jobStatus == 'Expunged (Virus Detected)'" type="warning" size="24" color="#FF3750" />
          <ecs-icon v-else-if="isActiveImport" type="loading" spinning size="24" color="#858E9E" />
          <span :style="{ color: statusLabelColor }">{{ jobStatus }}</span>
        </ecs-flex-row>

        <ec-actions @refresh="refresh" v-bind="{ matterId, importId: detail.id, status: detail.status }" :class="{ hide: isActiveImport }" />
      </ecs-flex-row>
    </ecs-flex-row>
  </ecs-card>
</template>

<script>
  import EcDateTime from 'ec-common/components/utility/datetime'
  import EcPopover  from 'ec-common/components/utility/popover'
  import EcActions  from './actions'

  export default {
    components: { EcDateTime, EcPopover, EcActions },
    emits: ['refresh'],

    props: {
      matterId: [String, Number],
      detail: Object,
      importsIndexVisible: Boolean
    },

    data() {
      return {
        percent: 0,
        jobStatus: 'Pending',
        jobPercent: '',
        sameResponseCount: 0,
        task: null
      }
    },

    computed: {
      formatted_id(){
        return String(this.detail.id).padStart(4, '0')
      },

      isProcessing() {
        return this.percent > 0 && this.percent < 101 && this.jobStatus.slice(-10) == '% Complete'
      },

      isActiveImport(){
        return this.jobStatus=='Pending' || this.jobStatus=='Processing' || this.jobStatus.slice(-10) == '% Complete'
      },

      statusLabelColor(){
        return this.jobStatus=='Expunged (Virus Detected)' ? '#ED223C' : '#75798F'
      }
    },

    methods: {
      capitalize(str) {
        return _.capitalize(str)
      },

      refresh(){
        this.$emit('refresh')
      },

      syncInitiated(){
        if (this.importsIndexVisible){
          let sameResponseCount = 0
          let lastPercentCompleteResponse = 0

          const payload = { matter_id: this.matterId, id: this.detail.id, from_index: true }
          this.$store.dispatch('importAnnotations/fetch', payload).then(resp => {

            const status = resp.body.status ? resp.body.status.toLowerCase() : 'none'
            this.percent = parseInt(resp.body.percent_complete)
            this.jobPercent = this.percent
            let interval = 6000

            if (lastPercentCompleteResponse == this.percent){
              sameResponseCount++
              if (sameResponseCount > 10)
                interval = interval * 2
              if (sameResponseCount > 20)
                interval = interval * 2
            } else {
              sameResponseCount = 0
            }
            lastPercentCompleteResponse = this.percent

            if (status == 'virus_detected'){
              this.$store.commit('notifications/add', { isError: true, message: 'Virus detected in import: archive quarantined and expunged.' })
              return this.jobStatus = "Expunged (Virus Detected)"
            }
            else if (this.percent > 0 && this.percent < 100) {
              this.jobStatus = `${ resp.body.percent_complete }% Complete`
              return this.task = setTimeout(() => { this.syncInitiated() }, 5000)
            }
            else if (this.percent == 100){
              if (status == 'complete_with_errors') {
                return this.jobStatus = "Completed (Exceptions)"
              }
              else {
                return this.jobStatus = "Completed"
              }
            }
            else if (status == 'max_retries_reached' || status == 'system_error' || status == 'failure'){
              return this.jobStatus = 'Failure'
            }
            else if (this.percent == 0 && status == 'processing'){
              this.jobStatus = 'Processing'
              return this.task = setTimeout(() => { this.syncInitiated() }, 6000)
            }
            else {
              this.jobStatus = "Pending"
              return this.task = setTimeout(() => { this.syncInitiated() }, 6000)
            }
          }).catch(() => {
            this.jobStatus == 'Not Found'
          })
        }
      },
    },

    watch: {
      importsIndexVisible(itIs){
        if (itIs && this.jobPercent != 100)
          this.syncInitiated()
      },

      percent(n, old){
        if (this.percent == 100 && n !== old){
          let element = document.querySelector('.ecs-card .progress')
          if (!_.isNil(element)){
            let op = 1
            let timer = setInterval(() => {
              if (op <= 0.1){
                clearInterval(timer)
                element.style.display = 'none'
              }
              element.style.opacity = op
              element.style.filter = 'alpha(opacity=' + op * 100 + ")"
              op -= op * 0.1
            }, 50)
          }
        }
      }
    },

    mounted(){
      this.jobPercent = this.detail.percent_complete

      let stopPollingStatuses = ['complete', 'complete_with_errors', 'failure']
      if ((this.detail.status && !stopPollingStatuses.includes(this.detail.status.toLowerCase())) || this.detail.status == null) { // job percent may be 100 but status is not yet updated
        this.syncInitiated()
      }
      else {
        if (this.detail.status == 'virus_detected'){
          this.jobStatus = 'Expunged (Virus Detected)'
        } else if (this.detail.status == 'complete'){
          this.jobStatus = 'Completed'
        } else if (this.detail.status == 'complete_with_errors'){
          this.jobStatus = 'Completed (Exceptions)'
        } else if ((this.detail.status == 'system_error' || this.detail.status == 'max_retries_reached') && this.detail.percent_complete == '100'){
          this.jobStatus = 'Completed (Exceptions)'
        } else if (this.detail.status == 'processing'){
          this.jobStatus = 'Processing'
        } else {
          this.jobStatus = 'Failure'
        }
      }
    },

    beforeUnmount(){
      window.clearTimeout(this.task)
    },

    unmounted(){
      window.clearTimeout(this.task)
    }
  }
</script>

<style scoped>
  .hide {
    visibility: hidden;
  }
</style>
