<template>
  <div class="mf-breadcrumb">

    <transition name="transition-attach-bar" mode="out-in">
      <div v-if="isLifted" class="lift-drop-bar master-file" :class="{ 'is-root': !rootUrl }" data-test="masterfile-drop-bar">
        <ec-lift-drop-detach namespace="masterFile" v-bind="{ resource, matterId: this.matter.id }" />
        <ec-lift-drop-attach namespace="masterFile" v-if="resource" v-bind="{ resource }" is-root />
        <ec-lift-drop-cancel namespace="masterFile" />
      </div>
    </transition>

    <div v-if="hasSearch && !suppressRefresh" class="mf-breadcrumb-title" data-test="mf-search-results-header">
      <span>Search Results</span>
    </div>

    <div v-else :class="{ blurr: loading }" class="mf-breadcrumb-navigation">
      <router-link v-if="rootUrl" :to="rootUrl" class="mf-breadcrumb-button mf-breadcrumb-button-root" title="Back to Home" data-test="masterfile-root-link">
        <ecs-icon type="home" width="26" height="26" />
      </router-link>

      <template v-if="breadcrumbsExist">
        <ec-crumb v-for="crumb in firstCrumbs" v-bind="{ crumb }" v-bind:key="crumb.id" :class="{ blurred: isLifted }" />

        <ec-popover v-if="ellipsesTree.length" class="mf-breadcrumb-button mf-breadcrumb-button-more" :class="{ blurred: isLifted }" data-test="masterfile-breadcrumb-more-button">
          <ecs-button-more type="ellipsis" />
          <template v-slot:content>
            <div v-html="ellipsesTree" class="width-360 popover-content mf-breadcrumb-popover popover-scroll scrollbar scrollbar-sml" />
          </template>
        </ec-popover>

        <ec-crumb v-if="lastCrumb" :crumb="lastCrumb" :class="{ blurred: isLifted }" />
      </template>

      <div class="mf-breadcrumb-title" :class="{ blurred: isLifted }" data-test="mf-page-header">
        <span v-if="exists" class="ml-3" data-test="masterfile-folder-name">
          <span class="mf-party" :class="styles">
            {{ resource.doc_type }}
          </span>
          {{ resource.headline }}
        </span>

        <span v-else :class="{ blurr: loading }">
          Master File
        </span>
      </div>
    </div>

    <div v-if="exists && !hasSearch && matterPermissions['other_index_actions-move_items-master_file']" class="mf-breadcrumb-actions" :class="{ blurred: isLifted }">
      <ecs-button @click="$emit('setShowAddModal', `editFolder-${ resource.id }`)" icon="edit" title="Edit folder" data-test="edit-folder-button">Edit</ecs-button>
    </div>

  </div>
</template>

<script>
  import EcLiftDropDetach from 'ec-common/components/utility/lift-drop/detach'
  import EcLiftDropAttach from 'ec-common/components/utility/lift-drop/attach'
  import EcLiftDropCancel from 'ec-common/components/utility/lift-drop/cancel'
  import EcPopover        from 'ec-common/components/utility/popover'
  import EcCrumb          from './breadcrumb'

  export default {
    components: { EcLiftDropDetach, EcLiftDropAttach, EcLiftDropCancel, EcPopover, EcCrumb },
    emits: ['setShowAddModal'],

    props: {
      resource        : Object,
      query           : String,
      loading         : Boolean,
      suppressRefresh : Boolean,
      matterPermissions: { type: Object, default() { return {} }}
    },

    data() {
      return {
        ellipsesTree : '',
        numFoldersBeforeEllipses : 6
      }
    },

    computed: {
      isLifted() {
        return !_.isEmpty(this.$store.getters['masterFile/lifted'])
      },

      toUrl() {
        if (!this.exists) return

        return {
          name: 'masterFile',
          params: {
            matter_id : this.matter.id,
            id        : this.resource.parent_id
          }
        }
      },

      rootUrl() {
        if (!this.exists) return

        return {
          name: 'masterFile',
          params: {
            matter_id : this.resource.matter_id
          }
        }
      },

      styles() {
        return {
          [this.resource.side] : this.resource.side,
          [this.resource.tier] : this.resource.tier,
          shared : this.resource.guest_access
        }
      },

      matter() {
        return this.$store.getters['matters/active']
      },

      exists() {
        return !!(this.resource && this.resource.id)
      },

      breadcrumbsExist() {
        return !!(this.resource && this.resource.breadcrumbs && this.resource.breadcrumbs.length)
      },

      firstCrumbs(){
        if (!this.resource) return
        return _.take(this.resource.breadcrumbs, 5)
      },

      lastCrumb(){
        if (!this.resource || !this.resource.breadcrumbs.length || this.resource.breadcrumbs.length < 6) return
        return this.resource.breadcrumbs[this.resource.breadcrumbs.length - 1]
      },

      hasSearch(){
        return !_.isEmpty(this.query)
      }
    },

    methods: {
      loadEllipsesTree(){
        if (this.resource){
          const lineage = this.resource.breadcrumbs
          if (!lineage || !lineage.length || lineage.length <= this.numFoldersBeforeEllipses)
            return this.ellipsesTree = ''

          this.ellipsesTree = ''
          const len = lineage.length
          for (let i = 0; i < len; i++){
            if (len > this.numFoldersBeforeEllipses){
              this.ellipsesTree += this.createTree(lineage[i])
            }
          }

          this.ellipsesTree += this.createSelfTreeEntry()

          if (len > this.numFoldersBeforeEllipses){
            for (let i = 0; i == len; i++){
              this.ellipsesTree += '</div>'
            }
          }
        }
      },

      createTree(ancestor){
        return `<div class="tree-view-list"><div class="tree-view-entry selectable"><div class="tree-view-entry-inner"><span class="mf-party ${ ancestor.side } folder">Folder</span><a href="/matters/${ this.matter.id }/master-file/${ ancestor.id }"><span class="title">${ ancestor.name }</span></a></div></div>`
      },

      createSelfTreeEntry(){
        return `<div class="tree-view-list"><div class="tree-view-entry state-active"><div class="tree-view-entry-inner"><span class="mf-party ${ this.resource.side } folder">Folder</span><span class="title">${ this.resource.headline }</span></div></div>`
      }
    },

    watch: {
      resource(){
        this.loadEllipsesTree()
      }
    },

    created(){
      this.loadEllipsesTree()
    },

    mounted() {
      $(function(){ // Prevents Ctrl/Command+shift+h from sending you to the browser's home screen
        $(document).keydown(function(e) {
          if (e.ctrlKey || e.metaKey) {
            if (e.shiftKey) {
              if (e.keyCode == 72) {
                return false
              }
            }
          }
        })
      })

      Mousetrap.bind('mod+shift+up', (e) => {
        this.$router.push(this.backUrl)
      })
      Mousetrap.bind('mod+shift+h', (e) => {
        this.$router.push(this.rootUrl)
      })
    },

    beforeUnmount() {
      Mousetrap.unbind(['mod+shift+up'])
      Mousetrap.unbind(['mod+shift+h'])
    }
  }
</script>

<style scoped>
  .blurr {
    filter: blur(5px)
  }
</style>
