<template>
  <div>
    <!-- v-if="isReady || viewerShown" -->
    <ec-viewer
      v-bind="{ entry, scope, isNew, indexId, fetching, saving, matterId, customFields,
        isTranscript, suggestConvertToTranscript, uploading, matterPermissions, amInViewer }"
      @save="saveEntry" @setIsNew="setIsNew" @setBatesOrAttachDirty="setBatesOrAttachDirty"
        @fetchAnnotations="fetchAnnotations" @fetchEntry="fetchViewerEntry" @setTagCreated="setTagCreated"
        @refreshExhibitLinks="fetchExhibitLinks" @refreshExcerpts="refreshExcerpts"
        @convertToTranscript="convertToTranscript" @clearSuggestConvertToTranscript="clearSuggestConvertToTranscript"
        @fetchMatterDetails="fetchMatterDetails" @setUploading="setUploading"
        @setTranscriptHealthStatus="setTranscriptHealthStatus" @wipeEntry="wipeEntry" @mutateDirectly="mutateDirectly"
    />

    <ecs-dialog :show="confirmAbandonVid" @cancel="proceedToAbandonVid">
      <ecs-dialog-header icon="video" icon-color="danger">Cancel Video Upload</ecs-dialog-header>
      <p>Deposition video files are currently uploading for this transcript. To prevent disruption, open this item in a new browser tab.</p>
      <template v-slot:actions>
        <ecs-button-dialog @click="openInNewWin" data-test="dialog-cancel-button">Open New Tab</ecs-button-dialog>
        <ecs-button-dialog @click="proceedToAbandonVid" type="danger" icon="nope" data-test="dialog-confirm-button">Cancel Upload</ecs-button-dialog>
      </template>
    </ecs-dialog>

    <ecs-dialog :show="confirmAbandonDoc" @cancel="proceedToAbandonDoc">
      <ecs-dialog-header icon="video" icon-color="danger">Cancel File Upload</ecs-dialog-header>
      <p>A file is currently uploading to this item. If you navigate away, the upload will be cancelled.</p>
      <template v-slot:actions>
        <ecs-button-dialog @click="openInNewWin" data-test="dialog-cancel-button">Open New Tab</ecs-button-dialog>
        <ecs-button-dialog @click="proceedToAbandonDoc" type="danger" icon="nope" data-test="dialog-confirm-button">Cancel Upload</ecs-button-dialog>
      </template>
    </ecs-dialog>
  </div>
</template>

<script>
  import EcViewer       from './viewer'
  import EcViewerCommon from './mixins/viewer-common'
  import EcPaneViewerIndex from 'ec-common/mixins/pane-viewer-index-shared'

  export default {
    components: { EcViewer },

    mixins: [EcViewerCommon, EcPaneViewerIndex],

    data() {
      return {
        entry : {},
        isNew : false,
        fetching : true,
        hadFile  : false,
        uploading : false,
        amInViewer : true,
        savedRoute : null,
        lastViewer : null,
        broadcaster : null,
        viewerShown : false,
        fieldsToOmit : ['tags', 'linked_documents'],
        customFields : [],
        wasTranscript : false,
        hadCustomFields : false,
        confirmAbandonVid : false,
        confirmAbandonDoc : false,
        // batesOrAttachDirty : false,
        abandonVidConfirmed : false,
        abandonDocConfirmed : false,
        fetchExhibitsCountAttempt : 0,
        fetchAnnotationsCountAttempt : 0,
        suggestConvertToTranscript : false
      }
    },

    computed: {
      isTranscript(){
        return this.entry && this.entry.transcript == true
      },

      indexId(){
        const pathParts = this.$route.path.split('/')
        return pathParts.pop()
      },

      syncObject(){
        return this.$store.getters['viewer/syncObject']
      },

      vidDepoInProgress(){
        if (_.isEmpty(this.syncObject)) return false
        return !_.every(this.syncObject.video_deposition_media, ['file_status', '100.00'])
      },

      hasMatterRole(){
        const roles = this.$store.getters['me/matterRoles']
        return _.some(roles, ['matter_id', this.matterId])
      },

      isReady(){
        return !_.isEmpty(this.matterPermissions) && this.hasMatterRole
      },

      matterPermissions(){
        return this.$store.getters['me/matterPermissions']
      },

      newTagCreated() {
        return this.$store.getters['tags/created']
      },

      matterId(){
        return this.matter.id
      }
    },

    methods: {
      fetchViewerEntry(silently){
        if (_.isNil(this.matter) || _.isNil(this.matter.activated_custom_fields)) // matter details endpoint has not yet been received, so need to wait or else the formatCustomFields calls will error
          return setTimeout(() => { this.fetchViewerEntry(silently) }, 250)

        const id = this.indexId
        const data = { id, matter_id: this.matterId }
        if (id == 'add') {
          this.setIsNew(true)
          this.entry = {}
          this.initIndexSync(null)
          this.checkForActiveCustomFields(0)
        }
        else {
          if (!silently)
            this.fetching = true
          const scope = this.scope == 'master-file' ? 'masterFile' : this.scope
          this.$store.dispatch(`${ scope }/fetch`, data)
          .then(resp => {
            this.entry = resp.body
            this.hadFile = this.entry.has_file
            const mergedCustomFields = [...this.formatCustomFields(this.entry.custom_field_values_filled), ...this.formatCustomFields(this.entry.custom_field_values_empty)]
            this.customFields = _.orderBy(mergedCustomFields, ['enabled_custom_field_sort_order'], ['asc'])
            this.hadCustomFields = !!this.entry.custom_field_values_filled.length
            if (localStorage.getItem('new-transcript-file-added') == 'true'){
              this.suggestConvertToTranscript = true
              localStorage.removeItem('new-transcript-file-added')
            }
            this.$nextTick(() => { this.wasTranscript = this.entry.transcript })
          })
          .then(() => {
            this.$nextTick(() => { this.fetching = false })
            if (this.hadFile) {
              this.fetchAnnotations(_.assign(data, { designationsFirst: this.$route.hash == '#designations' }))
              this.fetchExhibitLinks(data)
            }
            this.initIndexSync(this.entry.id)
          })
          .catch(err => {
            console.error('caught in fetchViewerEntry', err)
          })
        }
      },

      fetchAnnotations(data){
        if ((!this.matterPermissions || _.isEmpty(this.matterPermissions)) && this.fetchAnnotationsCountAttempt < 199){
          this.fetchAnnotationsCountAttempt = this.fetchAnnotationsCountAttempt + 1
          console.log('fetchAnnotationsCountAttempt', this.fetchAnnotationsCountAttempt)
          return setTimeout(() => { this.fetchAnnotations(data) }, 199)
        }
        this.fetchAnnotationsCountAttempt = 0

        if (this.isTranscript) {
          if (!this.matterPermissions['viewer_transcripts-access_to_annotations_tag-view'] && !this.matterPermissions['viewer_transcripts-access_to_annotations_tag-manage'])
            return

          if (_.isNil(data.page))
            data.page = 1

          if (data.designationsFirst)
            return this.fetchDesignations(_.assign(data, { designationsFirst: true }))

          this.$store.dispatch('viewer/fetchAnnotations', _.assign(data, { scope: 'documents', matterId: this.matterId, document_id: (this.entry.document_id ? this.entry.document_id : this.entry.id), followUp: data.followUp || data.designationsFetched })) // isTranscript: this.isTranscript
          .then(resp => {
            if (data.page == 1)
              this.indexObjectionBasis()

            if (resp.meta && resp.meta.pagination && resp.meta.pagination.total_pages > data.page && data.page){
              const page = data.page + 1
              setTimeout(() => { this.fetchAnnotations(_.assign(data, { page, silently: true, followUp: true })) }, 3)
            }
            else {
              if (!data.designationsFetched)
                this.fetchDesignations(_.assign(data, { page: 1, silently: data.silently, followUp: false }))
            }
          })
        }
        else {
          this.$store.dispatch('viewer/fetchAnnotationLayers', _.assign(data, { scope: 'documents', matterId: this.matterId, document_id: this.entry.document_id, isTranscript: this.isTranscript }))
        }
      },

      fetchDesignations(data){
        if (_.isNil(data.page))
          data.page = 1

        this.$store.dispatch('viewer/fetchDesignations', _.assign(data, { scope: 'documents', matterId: this.matterId, document_id: (this.entry.document_id ? this.entry.document_id : this.entry.id) })) // isTranscript: this.isTranscript
        .then(resp => {
          if (resp.meta && resp.meta.pagination && resp.meta.pagination.total_pages > data.page && data.page){
            const page = data.page + 1
            setTimeout(() => { this.fetchDesignations(_.assign(data, { page, silently: true, followUp: true })) }, 3)
          }
          else
            if (data.designationsFirst)
              this.fetchAnnotations(_.assign(data, { page: 1, silently: false, designationsFetched: true, designationsFirst: false }))
        })
      },

      indexObjectionBasis(){
        this.$store.dispatch('objectionBasis/index', { matter_id: this.matterId })
      },

      fetchExhibitLinks(data){
        if (!this.isTranscript) return false

        if ((!this.matterPermissions || _.isEmpty(this.matterPermissions)) && this.fetchExhibitsCountAttempt < 199){
          this.fetchExhibitsCountAttempt = this.fetchExhibitsCountAttempt + 1
          console.log('fetchExhibitsCountAttempt', this.fetchExhibitsCountAttempt)
          return setTimeout(() => { this.fetchExhibitLinks(data) }, 199)
        }
        this.fetchExhibitsCountAttempt = 0

        if (!this.matterPermissions['viewer_transcripts-access_to_exhibits_tag-view'] && !this.matterPermissions['viewer_transcripts-access_to_exhibits_tag-manage'])
          return false
        this.$store.dispatch('viewer/fetchExhibitsLinks', _.assign(data, { scope: 'documents', matterId: this.matterId, document_id: (this.entry.document_id ? this.entry.document_id : this.entry.id), isTranscript: this.isTranscript }))
      },

      fetchActiveCustomFieldsForNew(){
        const scope = this.scope == 'master-file' ? 'masterFile' : this.scope == 'documents' ? 'document' : 'evidence'
        const custom_ids = _.map(this.matter.activated_custom_fields, 'custom_field_definition_id')
        this.$store.dispatch('viewer/fetchCustomFields', { scope, custom_ids, matterId: this.matterId })
        .then(result => {
          this.customFields = this.formatCustomFields(result)
        })
      },

      checkForActiveCustomFields(count){
        count = count + 1
        if (_.isNil(this.matter) || _.isNil(this.matter.activated_custom_fields))
          return setTimeout(() => { this.checkForActiveCustomFields(count) }, 1000)

        if (this.matter.activated_custom_fields && this.matter.activated_custom_fields.length)
          this.fetchActiveCustomFieldsForNew()
      },

      createEntry(data, noun){
        this.saving = true
        data.matterId = this.matterId
        data = this.validateBatesAttach(data)
        const haveTags = !!(data.tags && data.tags.length)
        data.tag_ids = haveTags ? _.map(data.tags, 'id') : []
        const haveLinkedDocs = !!(data.linked_documents && data.linked_documents.length)
        const haveCustomFieldVal = this.haveCustomFieldValues()

        this.$store.dispatch('viewer/create', _.omit(data, this.fieldsToOmit))
        .then(resp => {
          this.entry = resp.body
          data.id = resp.body.id
          if (resp.body.document_id)
            data.document_id = resp.body.document_id
          this.entry.tags = data.tags
          this.entry.linked_documents = data.linked_documents
          this.entry = _.assign({}, this.entry)
          if (haveLinkedDocs){
            this.saveLinkedDocuments(data, noun, false, haveCustomFieldVal)
          }
          else if (haveCustomFieldVal){
            this.saveCustomFields(data, noun, false)
          }
          else {
            this.confirmSaveSuccess(noun, false)
          }
          this.$router.replace(this.$route.path.replace('/add', `/${ resp.body.id }`) + this.$route.hash)
        })
        .catch(err => {
          this.saving = false
          if (err.body && err.body.error && err.body.error.message && err.body.error.message == 'Virus detected')
            this.displayVirusToast()
          else
            this.$store.commit('notifications/add', { message: `There was a problem creating the ${ noun }.`, isError: true })
        })
      },

      /*
      saveEntry(data, type, silently){
        const noun = this.isTranscript ? 'Transcript' : data.has_file && type != 'clearDocument' ? 'Document' : 'Entry'
        data.scope = this.scope == 'evidence' ? 'evidences' : this.scope == 'master-file' ? 'master_files' : 'documents'
        data.matter_id = data.matterId = this.matterId

        data = this.validateBatesAttach(data)

        // const tagsModified = !_.isEqual(this.entry.tags, data.tags)
        const linkedDocsModified = !_.isEqual(this.entry.linked_documents, data.linked_documents)
        const haveCustomFieldVal = this.haveCustomFieldValues()

        if (this.isNew && (_.isNil(this.entry) || _.isNil(this.entry.id))){
          this.createEntry(data, noun)
        }
        else {
          if (!this.allowEdit)
            return
          this.saving = true
          this.setDirty(false)

          if (data.bates_start == '')
            data.bates_start = null
          if (data.bates_end == '')
            data.bates_end = null
          if (data.attach_begin == '')
            data.attach_begin = null
          if (data.attach_end == '')
            data.attach_end = null
          if (data.control_number == '')
            data.control_number = null
          if (data.deposition_exhibit == '')
            data.deposition_exhibit = null
          if (data.trial_exhibit == '')
            data.trial_exhibit = null
          if (data.transcript && data.descriptive_date)
            data.descriptive_date = null
          if (data.summary == '' || _.isNil(data.summary) || data.summary == '<p></p>'){
            data.summary = null
            data.summary_touched_at = null
          }
          
          data.tag_ids = data.tags && data.tags.length ? _.map(data.tags, 'id') : []

          _.each(this.profileFields, (pf) => { data[pf] = [] })
          if ((type == 'addDocument' || type == 'clearDocument') && (this.scope == 'evidence' || this.scope == 'master-file')){
            data.id = data.document_id
            data.scope = 'documents'
          }
          const fieldsOmitted = data.file && data.has_file ? _.concat(this.fieldsToOmit, 'file') : this.fieldsToOmit
          this.$store.dispatch('viewer/save', _.omit(data, fieldsOmitted))
          .then(() => {
            if (linkedDocsModified){
              this.saveLinkedDocuments(data, noun, silently, haveCustomFieldVal)
            }
            else if (haveCustomFieldVal){
              this.saveCustomFields(data, noun, false)
            }
            else {
              const transcriptToggled = (this.wasTranscript && !data.transcript) || (!this.wasTranscript && data.transcript)
              const fileAddedOrRemoved = (type == 'addDocument') || (type == 'clearDocument')
              this.confirmSaveSuccess(noun, silently, type, transcriptToggled || fileAddedOrRemoved)
            }
          })
          .catch(err => {
            if (type == 'clearDocument'){
              console.error(err)
              this.$store.commit('notifications/add', { isError: true, message: 'There was a problem clearing the file.' })
            }
            else {
              if (err.body && err.body.error && err.body.error.message && err.body.error.message == 'Virus detected')
                this.displayVirusToast()
              else
                this.displaySaveError(noun, err)
            }
          })
        }
      },

      // THIS WAS ALREADY NOT BEING USED
      saveTags(data, noun, silently, linkedDocsModified, haveCustomFieldVal){
        const tag_names = _.map(data.tags, function(tag) { return typeof tag === 'object' ? tag.name : tag })
        const tagData = { id: data.id, matterId: data.matterId, scope: data.scope, tag_names }
        this.$store.dispatch('viewer/saveTags', tagData).then(resp => {
          if (linkedDocsModified){
            this.saveLinkedDocuments(data, noun, silently, haveCustomFieldVal)
          }
          else if (haveCustomFieldVal){
            this.saveCustomFields(data, noun, silently)
          }
          else {
            this.confirmSaveSuccess(noun, silently)
          }
        })
        .catch(err => {
          this.displaySaveError(noun, err)
        })
      },

      saveLinkedDocuments(data, noun, silently, haveCustomFieldVal){
        const headlines = _.map(data.linked_documents, 'id')
        _.each(headlines, (id) => id.toString())
        const linkedDocsData = { id: data.document_id || data.id, matterId: data.matterId, headlines }
        this.$store.dispatch('viewer/saveLinkedDocs', linkedDocsData).then(resp => {
          if (haveCustomFieldVal){
            this.saveCustomFields(data, noun, silently)
          }
          else {
            this.confirmSaveSuccess(noun, silently)
          }
        })
        .catch(err => {
          this.displaySaveError(noun, err)
        })
      },

      saveCustomFields(data, noun, silently){
        const fields = _.map(data.custom_fields, fld => {
          let assoc_id = null
          if ((this.entry.type == 'clone_evidence' && this.scope == 'evidence') || this.scope == 'documents')
            assoc_id = this.entry.id
          else
            assoc_id = this.entry.document_id
          return _.assign(fld, { assoc_id })
        })
        const datas = { custom_field_values: fields, matterId: this.matterId }
        this.$store.dispatch('viewer/saveCustomFields', datas)
        .then(() => {
          this.confirmSaveSuccess(noun, silently)
        })
        .catch(err => {
          console.error('saveCustomFields', err)
          this.displaySaveError(noun, err)
        })
      },

      haveCustomFieldValues(){
        if (this.hadCustomFields)
          return true
        let haveCustomFieldVal = false
        $('#customFieldsForm input').each((i, cf) => {
          if ($(cf).val().trim() != '')
            haveCustomFieldVal = true
        })
        return haveCustomFieldVal
      },

      validateBatesAttach(data){
        if (data.bates_start && !data.bates_end)
          data.bates_end = data.bates_start
        else if (data.bates_end && !data.bates_start)
          data.bates_start = data.bates_end

        if (data.attach_begin && !data.attach_end)
          data.attach_end = data.attach_begin
        else if (data.attach_end && !data.attach_begin)
          data.attach_begin = data.attach_end

        return data
      },

      confirmSaveSuccess(noun, silently, type, suppressStandardNotification){
        this.fetchViewerEntry(true)
        const scope = this.getScope()

        if (_.isNil(this.broadcaster))
          this.broadcaster = new BroadcastChannel('app-channel')

        if (!this.isNew && !this.batesOrAttachDirty){
          this.broadcaster.postMessage({ modifiedId: this.entry.id, modifiedDocId: this.entry.document_id, scope })
        }
        else {
          if (this.isNew)
            this.broadcaster.postMessage({ refreshIndex: scope })
          else
            this.broadcaster.postMessage({ setIndexStale: scope })
        }
        this.broadcaster.postMessage({ refreshDrawer: scope })

        if (!silently && type != 'clearDocument' && !suppressStandardNotification)
          this.$store.commit('notifications/add', { message: `${ noun } ${ this.isNew ? 'created' : 'updated' }` })

        if (this.tagCreated){
          this.$store.dispatch('tags/index', { matter_id: this.matterId })
          this.tagCreated = false
        }
        this.setBatesOrAttachDirty(false)
        setTimeout(() => { this.saving = false }, 200)
        setTimeout(() => { this.setIsNew(false) }, 3000)
      },

      setTagCreated(){
        this.tagCreated = true
      },

      displaySaveError(noun, err){
        console.error(err)
        this.saving = false
        this.$store.commit('notifications/add', { isError: true, message: `There was a problem saving the ${ noun }.` })
      },
      */

      setIsNew(bool){
        this.isNew = bool
      },

      initialize(){
        this.fetchUser()
        this.fetchMatterDetails()
        this.lastViewer = this.$route.name
        this.$store.dispatch('tags/index', { matter_id: this.matterId })
        this.hadCustomFields = false
        this.tagCreated = false
        this.savedRoute = null
        this.confirmAbandonVid = false
        this.confirmAbandonDoc = false
        this.abandonVidConfirmed = false
        this.abandonDocConfirmed = false
        if (!this.isNew)
          this.clearSuggestConvertToTranscript()
      },

      fetchMatterDetails(){
        if (_.isNil(this.matter))
          return setTimeout(() => { this.fetchMatterDetails() }, 300)
        // if (_.isNil(this.matter.activated_custom_fields))
        this.$store.dispatch('matters/fetch', { id: this.matterId }).then(() => {
          this.fetchViewerEntry()
        })
      },

      fetchUser(){
        this.$store.dispatch('me/fetch', { matter_id: this.matterId })
      },

      initIndexSync(id){
        if (_.isNil(this.broadcaster))
          this.broadcaster = new BroadcastChannel('app-channel')
        this.broadcaster.postMessage({ 'updateActiveIndexItem': id, scope: this.scope })
      },

      convertToTranscript(modifiedEntry, clear){
        const doc_type = modifiedEntry.witnesses && modifiedEntry.witnesses.length ? 'Deposition Transcript' : 'Transcript'
        const data = _.assign({}, modifiedEntry, { transcript: true, doc_type })
        if (modifiedEntry.headline == '') // temporary until we can disable the button when the headline is wiped
          modifiedEntry.headline = modifiedEntry.filename
        this.saveEntry(data, null, true)
        if (clear)
          this.clearSuggestConvertToTranscript()
      },

      clearSuggestConvertToTranscript(){
        this.suggestConvertToTranscript = false
        localStorage.removeItem('new-transcript-file-added')
      },

      proceedToAbandonVid(){
        this.abandonVidConfirmed = true
        if (this.syncObject && this.syncObject.id) {
          this.$store.dispatch('viewer/deleteVideoDeposition', { matterId: this.matterId, depoId: this.syncObject.id })
          .catch(e => { console.error('error deleting video depo sync', e)})
        }
        this.$router.push(this.savedRoute)
      },

      proceedToAbandonDoc(){
        this.abandonDocConfirmed = true
        setTimeout(() => { this.confirmAbandonDoc = false }, 10)
        setTimeout(() => { this.abandonDocConfirmed = false }, 100)
        this.$router.push(this.savedRoute)
      },

      openInNewWin(){
        this.confirmAbandonVid = false
        this.confirmAbandonDoc = false
        const route = this.$router.resolve(this.savedRoute)
        this.savedRoute = null
        window.open(route.href)
      },

      refreshExcerpts(param){
        let payload =  { refreshIndex: this.scope, refreshExcerptTray: this.scope }
        if (param)
          payload = _.assign(payload, param)
        if (_.isNil(this.broadcaster))
          this.broadcaster = new BroadcastChannel('app-channel')
        this.broadcaster.postMessage(payload)
        this.broadcaster.postMessage({ refreshIndex: 'annotations' })
      },

      setUploading(bool){
        this.uploading = bool
      },

      setTranscriptHealthStatus(status){
        this.entry.transcript_health_status = status
        if (status != 'healthy')
          this.entry.transcript_word_index_id = null
      },

      wipeEntry(){
        const bak = _.cloneDeep(this.entry)
        this.entry = { type: '', transcript: false, id: bak.id }
      },

      mutateDirectly({ field, value, save }){ // Currently only used for Summary ECAI & Extract Metadata: is_ai_extract_acceptable
        this.entry[field] = value
        if (save)
          this.saveEntry(this.entry, null, true)
      }
    },

    watch: {
      $route(){
        let mid = window.location.href.split('matters/')[1]
        mid = mid.split('/')[0]
        if (mid != this.matterId)
          this.$store.commit('matters/activate', mid)
        if ((this.entry.id != this.$route.params.id) || (this.lastViewer != this.$route.name) || (this.hadFile != this.entry.has_file)){
          this.$nextTick(() => { this.initialize() })
        }
      },

      entry(n, old){
        if (old.pageCount && !n.pageCount)
          this.entry.pageCount = old.pageCount
        if (this.wasTranscript && !this.entry.transcript && !_.isEmpty(old) && n.id == old.id)
          this.$store.commit('notifications/add', { message: 'Item converted from Transcript to Document' })
        else if (!this.wasTranscript && this.entry.transcript && !_.isEmpty(old) && n.id == old.id)
          this.$store.commit('notifications/add', { message: 'Item converted from Document to Transcript' })
      },

      newTagCreated() {
        if (this.newTagCreated) {
          if (_.isNil(this.broadcaster))
            this.broadcaster = new BroadcastChannel('app-channel')
          this.broadcaster.postMessage({ 'reloadTags': true })
        }
      },

      matterId(){
        this.viewerShown = false
        this.$store.commit('me/clearPermissions')
        this.fetchUser()
        this.initialize()
      },

      isReady(bool){
        if (bool)
          this.viewerShown = true
      }
    },

    created() {
      this.initialize()

      window.addEventListener('unload', (e) => {
        /* CODE TO BLOCK THE CLOSING OF THE WINDOW IN ORDER TO BE ABLE TO SEE any console.info IN THIS METHOD BELOW THESE LINES
        console.info('HI THERE!!!')
        e = e || window.event
        if (e) e.returnValue = 'Sure?'
        return 'Sure?'
        */
        localStorage.removeItem('open-viewer-entry')
        /* Stopped working, assume a Chrome security update, so restoring window.opener.vue method for this one case
        if (_.isNil(this.broadcaster))
          this.broadcaster = new BroadcastChannel('app-channel')
        this.broadcaster.postMessage({ 'updateActiveIndexItem': null, scope, 'resetViewerZoom': true })
        */
        try {
          if (window.opener && window.opener.vue)
            window.opener.vue.updateActiveIndexItem(null, this.scope)
        }
        catch (e){
          console.error('err', e)
        }

        (e || window.event).returnValue = null
        return null
      })
    },

    mounted(){
      document.body.classList.add('everchron-viewer')
    },

    beforeUnmount(){
      if (!_.isNil(this.broadcaster))
        this.broadcaster.close()
    },

    beforeRouteUpdate(to, from, next) {
      if (!(from.fullPath.includes('?np=true') && !to.fullPath.includes('?np=true'))){ // #11959 so it doesn't trip the modal multiple times
        if (this.vidDepoInProgress && this.abandonVidConfirmed){
          this.confirmAbandonVid = false
          this.abandonVidConfirmed = false
          return next()
        }
        else if (this.vidDepoInProgress && !this.abandonVidConfirmed){
          this.savedRoute = to
          this.confirmAbandonVid = true
          return next(false)
        }
        else if (this.uploading && this.abandonDocConfirmed){
          this.confirmAbandonDoc = false
          this.abandonDocConfirmed = false
          return next()
        }
        else if (this.uploading && !this.abandonDocConfirmed){
          this.savedRoute = to
          this.confirmAbandonDoc = true
          return next(false)
        }
        else {
          return next()
        }
      }
      else {
        return next()
      }
    }
  }
</script>

<style>
  body.everchron-viewer .intercom-container,
  body.everchron-viewer .intercom-namespace,
  .intercom-lightweight-app {
    display: none
  }
</style>
