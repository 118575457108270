.case-caption[data-v-137e47b8] {
  font-weight: 600;
  font-style: italic;
}
.subtle[data-v-137e47b8] {
  color: var(--color-gray-10);
}
dl[data-v-137e47b8] {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
}
dt[data-v-137e47b8] {
  color: var(--color-gray-10);
}
dd[data-v-137e47b8] {
  color: var(--color-gray-12);
  margin: 0 40px 0 6px;
}
