.selection[data-v-1e30526a] {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--color-white);
  margin: 0 -12px;
  gap: var(--spacing-15);
  padding: var(--spacing-20);
  box-shadow: var(--elevation-10);
  border-radius: var(--border-radius-small);
}
.options[data-v-1e30526a] {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-10);
}
