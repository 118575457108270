.sso-logo[data-v-08a21422] {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-10);
  column-gap: var(--spacing-5);
}
.sso-section[data-v-08a21422] {
  padding: 16px 0 0 52px;
}
.sso-config[data-v-08a21422] {
  border-radius: var(--border-radius-medium);
  background: var(--color-gray-1);
}
