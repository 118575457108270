.form-section[data-v-7f03cc36] {
  width: 100%;
}
.label-column[data-v-7f03cc36] {
  width: 180px;
}
.form-column[data-v-7f03cc36] {
  flex: 1;
  position: relative;
  margin: 0;
}
.prompt-list[data-v-7f03cc36] {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-10);
}
.prompt-list-item.sortable-chosen .prompt-list-item-header[data-v-7f03cc36] {
    cursor: grabbing;
}
.prompt-list-item-header[data-v-7f03cc36] {
    cursor: grab;
    height: var(--spacing-30);
}
.prompt-list-item .ecs-form-check[data-v-7f03cc36] {
    margin: 0;
}
.prompt-editor[data-v-7f03cc36] {
  margin: var(--spacing-5) 0;
  padding: var(--spacing-15);
  border-radius: var(--border-radius-medium);
  background: var(--color-gray-1);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-10);
}
