.citations[data-v-37286e85] {
  border: 1px solid #D7DAE1;
  padding: 4px;
  border-radius: 6px;
}
.citations .ecs-text[data-v-37286e85] {
    padding: 4px 8px 8px 8px;
}
.columns-box[data-v-37286e85] {
  border-radius: var(--border-radius-large);
  padding: var(--spacing-10);
  background: var(--color-gray-2);
}
.column-box[data-v-37286e85] {
  border-radius: var(--border-radius-medium);
  background: #FFF;
  box-shadow: 0px 0px 0px 1px rgba(71, 75, 96, 0.05), 0px 1px 4px rgba(71, 75, 96, 0.1);
  width: 100%;
  padding: var(--spacing-15) var(--spacing-20) var(--spacing-20) var(--spacing-15);
}
.column-box-form[data-v-37286e85] {
    padding-left: var(--spacing-50);
}
.column-box-form > div[data-v-37286e85] {
      flex: 1;
}
