.tag-outline-item[data-v-15d1086a] {
  list-style-type: none;
  padding-left: var(--spacing-25);
}
.tag-outline-item li[data-v-15d1086a] {
    margin-top: var(--spacing-5);
    position: relative;
}
.tag-outline-item li > ul[data-v-15d1086a] {
      padding-left: 0;
}
.tag-outline-item li > ul li[data-v-15d1086a] {
        padding-left: var(--spacing-15);
}
.tag-outline-item li > ul li[data-v-15d1086a]:before {
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='15' viewBox='0 0 8 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1V12.5C1 13.6046 1.89543 14.5 3 14.5H7.5' stroke='%23D7DAE1' stroke-linecap='round'/%3E%3C/svg%3E");
          content: '';
          height: 15px;
          top: 0;
          left: 0;
          width: 8px;
          position: absolute;
}
.ecs-tag.not-selected[data-v-15d1086a] {
  background: var(--color-white) !important;
  color: var(--color-gray-8) !important;
  border: 1px solid var(--color-gray-4) !important;
}
