.matter-details[data-v-2240ab47] {
  min-height: 100%;
}
.matter-title-bar[data-v-2240ab47] {
  padding: var(--spacing-30) var(--spacing-40) var(--spacing-20) var(--spacing-40);
}
.matter-tab-bar[data-v-2240ab47] {
  padding: 0 var(--spacing-40);
}
.matter-tabs[data-v-2240ab47] {
  padding: var(--spacing-30) var(--spacing-40);
}
.matter-info-sidebar[data-v-2240ab47] {
  border-left: 1px solid var(--color-gray-4);
  padding: var(--spacing-30) var(--spacing-20) var(--spacing-20) var(--spacing-20);
}
.matter-info-sidebar-sticky[data-v-2240ab47] {
    position: sticky;
    top: var(--spacing-30);
    width: 100%;
}
.matter-info-sidebar-sticky > div[data-v-2240ab47] {
      width: 100%;
}
.matter-user-count[data-v-2240ab47] {
  flex: 1;
}
.matter-user-count .count[data-v-2240ab47] {
    color: var(--color-gray-15);
    font-size: var(--type-scale-4-font-size);
    line-height: var(--type-scale-4-line-height);
}
.matter-user-count .description[data-v-2240ab47] {
    font-size: var(--type-scale-3-font-size);
    line-height: var(--type-scale-3-line-height);
    color: var(--color-gray-9);
}
