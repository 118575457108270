.transcript-file[data-v-d23fd290] {
  font-weight: 500;
}
.transcript-file > span[data-v-d23fd290] {
    color: #65687A;
}
.transcript-card-title[data-v-d23fd290] {
  display: flex;
  align-items: center;
  font-size: 16px;
  flex: 1;
  padding-left: 12px;
  cursor: pointer;
  min-width: 0;
}
.transcript-card-title .avatar[data-v-d23fd290] {
    margin-right: 4px;
}
.transcript-card-title .title[data-v-d23fd290] {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 4px;
}
.transcript-card-title:hover .title[data-v-d23fd290] {
    text-decoration: underline;
}
.designations[data-v-d23fd290] {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  color: #75798F;
}
.designations.client[data-v-d23fd290] {
    color: #0F5CA2;
}
.designations.client .designations-bar[data-v-d23fd290] {
      background: #227FD3;
}
.designations.opposing[data-v-d23fd290] {
    color: #3D7301;
}
.designations.opposing .designations-bar[data-v-d23fd290] {
      background: #5DAF00;
}
.designations.other[data-v-d23fd290] {
    color: #7C7E82;
}
.designations.other .designations-bar[data-v-d23fd290] {
      background: #95989C;
}
.designations.joint[data-v-d23fd290] {
    color: #81715B;
}
.designations.joint .designations-bar[data-v-d23fd290] {
      background: #A4927A;
}
.designations-bar[data-v-d23fd290] {
    height: 8px;
    border-radius: 8px;
    margin-bottom: 8px;
    background: #D7DAE1;
}
.designations-legend[data-v-d23fd290] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
}
.designations-legend .count[data-v-d23fd290] {
      font-weight: 500;
      margin-right: 8px;
}
.designations-video[data-v-d23fd290] {
    display: flex;
    align-items: center;
    font-size: 10px;
    color: #858E9E;
}
.designations-video .icon[data-v-d23fd290] {
      margin-right: 2px;
}
.annotations[data-v-d23fd290] {
  font-size: 14px;
  color: #65687A;
  margin-top: 8px;
}
.annotations .count[data-v-d23fd290] {
    font-weight: 500;
    color: #202127;
}
.definition-list[data-v-d23fd290] {
  font-size: 14px;
  margin-top: 8px;
  line-height: 20px;
}
