.user-sidebar[data-v-7ff9da3a] {
  position: relative;
}
.close-sidebar[data-v-7ff9da3a] {
  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 100;
}
.persona[data-v-7ff9da3a] {
  text-align: center;
  position: relative;
  padding: var(--spacing-25);
}
.persona .avatar[data-v-7ff9da3a] {
    margin: 0 auto var(--spacing-10) auto;
}
.persona .name[data-v-7ff9da3a] {
    font-size: var(--type-scale-3-font-size);
    line-height: var(--type-scale-3-line-height);
    word-break: break-all;
}
