.type[data-v-a7525706],
.headline[data-v-a7525706],
.favorability-count[data-v-a7525706],
.type-label[data-v-a7525706] {
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
}
.date[data-v-a7525706] {
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  font-variant-numeric: tabular-nums;
  color: var(--color-gray-9);
}
.type[data-v-a7525706] {
  color: var(--color-gray-10);
}
.headline[data-v-a7525706] {
  color: var(--color-gray-15);
}
.favorability-count[data-v-a7525706] {
  font-weight: var(--font-weight-bold);
  font-variant-numeric: tabular-nums;
  text-align: right;
}
.type-label[data-v-a7525706] {
  color: var(--color-gray-12);
}
