.ecs-context-button-group[data-v-c698906c] {
  position: absolute;
  bottom: 4px;
  right: 4px;
  opacity: 0;
  transition: .3s;
}
.image-upload[data-v-c698906c] {
  width: 140px;
  height: 140px;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
  position: relative;
  transition: .3s;
}
.image-upload:hover .ecs-context-button-group[data-v-c698906c], .image-upload.popover-open .ecs-context-button-group[data-v-c698906c] {
    opacity: 1;
}
.image-upload-empty .delete[data-v-c698906c] {
    display: none;
}
.image-upload.drag-over[data-v-c698906c] {
    box-shadow: 0 0 0 3px #FFF, 0 0 0 5px var(--color-blue-6);
}
.image-upload.error[data-v-c698906c] {
    box-shadow: 0 0 0 3px #FFF, 0 0 0 5px var(--color-red-6);
}
.image-upload input[type=file][data-v-c698906c] {
    display: none;
}
