<template>
  <div>
    <div class="p-4">
      <ecs-data-list>
        <ecs-data-list-item identifier="Cite" type="mono" :loading="processingRequest">
          {{ mark.citation }}
        </ecs-data-list-item>
      </ecs-data-list>
    </div>

    <ecs-collapse title="Excerpt Text" id="excerpt-text" :visible="collapsibles[0].open" indent="md" border-bottom border-top>
      <template v-slot:controls>
        <div v-ec-tooltip="{ content: disableCopyWithCite ? 'Not available for rectangle annotations' : 'Copy w/ Cite', delay: [750, 5] }" class="mr-2">
          <ecs-button @click.stop="$emit('copyWithCite')" :disabled="disableCopyWithCite || loadingCopyWithCite" :loading="loadingCopyWithCite" type="secondary" icon="clipboard" icon-only aria-label="Copy w/ Cite" />
        </div>
      </template>
      <ecs-skeleton-loader v-if="processingRequest" type="multi" :count="3" />
      <pre v-else-if="markText" class="m-0 citation">{{ markText }}</pre>
      <div v-else class="citation-rectangle" :style="{ border: '1px solid ' + mark.fillColor, color: mark.fillColor }">
        <span>Rectangle Annotation</span>
        <div :style="{ backgroundColor: mark.fillColor }"></div>
      </div>
    </ecs-collapse>

    <ecs-section indent="md" border-bottom title="Coding">
      <ec-tag-view v-bind="{ tags, headline: 'Annotation Tags', loading: processingRequest }"/>

      <h4 class="subline mt-4 mb-2">Favorability</h4>
      <ecs-rating-favorability :rating="favorability" label :loading="processingRequest" />
    </ecs-section>

    <ecs-section v-if="profiles.length" indent="md" border-bottom title="People & Entities">
      <ec-profiles-viewer v-bind="{ profiles, matterId }" />
    </ecs-section>

    <ecs-collapse v-if="hasChronEntry" :title="chronEntryHeading" :class="{ subtle: hasInactiveChronEntry }" id="transcript-annotation-chron" indent="md" border-bottom>
      <ecs-data-list id="transcript-annotation-chron-dl" indent="md" border-bottom>
        <ecs-data-list-item identifier="Entry Date" :loading="processingRequest">
          <ec-date-time v-bind="{ value: annotation.date, utc: true, withTime: true }" format="informal" />
        </ecs-data-list-item>
        <ecs-data-list-item identifier="Entry Type" :loading="processingRequest">{{ annotation.doc_type }}</ecs-data-list-item>
        <ecs-data-list-item identifier="Entry Headline" full :loading="processingRequest">{{ annotation.headline }}</ecs-data-list-item>
        <ecs-data-list-item v-if="annotation.summary" identifier="Summary" full :loading="processingRequest">
          <ecs-formatted small><div v-html="annotation.summary" /></ecs-formatted>
        </ecs-data-list-item>
      </ecs-data-list>
    </ecs-collapse>

    <ecs-collapse v-else-if="annotation.summary && annotation.summary != '<p></p>'" title="Summary" id="transcript-annotation-summary" indent="md" border-bottom>
      <ecs-formatted small :loading="processingRequest"><div v-html="annotation.summary" /></ecs-formatted>
    </ecs-collapse>

    <!--NOTE THESE ARE HIDDEN FOR NOW-->
    <span class="hidden">
      <h4>Exhibits</h4>
      <ec-exhibits-viewer v-bind="{ exhibits }" />
    </span>

    <span class="hidden">
      <h4>Entries</h4>
      <ec-entries-viewer v-bind="{ entries }" />
    </span>
  </div>
</template>

<script>
import EcProfilesViewer from './profiles/profiles_viewer'
import EcExhibitsViewer from './exhibits/exhibits_viewer'
import EcEntriesViewer from './entries/entries_viewer'
import EcTagsViewer from './tags/tags_viewer'
import EcDateTime from 'ec-common/components/utility/datetime'
import TranscriptAnnotationMixin from 'ec-common/components/viewer/mixins/transcript_annotation_mixin'
import EcTagView  from 'ec-common/components/utility/tag-view.vue'

export default {
  components: { EcTagsViewer, EcProfilesViewer, EcExhibitsViewer, EcEntriesViewer, EcDateTime, EcTagView },

  mixins: [TranscriptAnnotationMixin],
  emits: ['copyWithCite'],

  props: {
    dateFormat    : Object,
    entryTags     : Array,
    documentId    : Number,
    hasChronEntry   : Boolean,
    chronEntryHeading : String,
    disableCopyWithCite : Boolean,
    hasInactiveChronEntry : Boolean
  },

  data(){
    return {
      excerptVisible: true,
      tags: []
    }
  },

  computed: {
    annotation() {
      return this.mark.annotation || {}
    },

    favorability() {
      return this.annotation.favorability || 'Unrated'
    },

    profiles() {
      if(this.annotation.profiles && _.isString(this.annotation.profiles) && this.annotation.profiles.length > 0) {
        try {
          return JSON.parse(this.annotation.profiles)
        } catch (error) {
          return []
        }
      }
      return []
    },

    entries() {
      return this.annotation.entries || []
    },

    exhibits() {
      return this.annotation.exhibits || []
    },

    citation(){
      return `Pg. ${ this.mark.highlight.pageStart }`
    },

    allTags(){
      return this.$store.getters['tags/all']
    },

    membershipMatterIds () {
      return this.$store.getters['memberships/matterIds']
    },

    matterUsers () {
      if (_.includes(this.membershipMatterIds, _.toString(this.matterId)))
        return this.$store.getters['memberships/findByMatter'](this.matterId)
      return []
    },

    author(){
      const author = _.find(this.matterUsers, ['user_id', this.mark.authorId])
      return author ? author.user_name : null
    },

    processingRequest(){
      return this.$store.getters['annotations/processing']
    },

    loadingCopyWithCite(){
      return (!this.mark || (!this.mark.selectedText && !this.mark.text)) && !this.disableCopyWithCite
    }
  },

  methods: {
    getTagId(tagName){
      return _.find(this.allTags, ['name', tagName])
    }
  },

  watch:{
    entryTags: {
      deep: true, immediate: true, handler() {
        this.tags = []
        _.each(this.entryTags, t => {
          const tag = this.getTagId(_.isString(t) ? t : t.name)
          if (_.isNil(tag)) {
            this.tags.push({ name: t, id: Math.floor(Math.random() * (9000 - 8000) + 8000)}) // random ID is ok since this section uses the name
          }
          else {
            this.tags.push(tag)
          }
        })
      }
    }
  },

  mounted(){
    if (!this.matterUsers.length)
      this.$store.dispatch('memberships/index', {matter_id: this.matterId, commit: true})
  }
}
</script>

<style scoped>
  .subtle {
    opacity: .7
  }
</style>
