.ecs-tiptap[data-v-3c980ba6] {
  position: relative;
  min-height: 40px;
  padding-top: 35px;
  border: 1px solid #CED2D8;
  border-radius: 3px;
  background: #FFF;
  transition: .2s border-color, .2s box-shadow;
}
.ecs-tiptap > div[data-v-3c980ba6]:last-child {
    min-height: inherit;
    max-height: inherit;
    position: relative;
}
.ecs-tiptap.focus[data-v-3c980ba6] {
    border-color: rgba(21, 126, 251, 0.5);
    box-shadow: 0 0 0 2px rgba(21, 126, 251, 0.15);
}
[data-v-3c980ba6] .ProseMirror {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  line-height: 1.4em;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  box-shadow: none;
  min-height: inherit;
  max-height: inherit;
  border-radius: 3px;
  overflow: auto;
}
[data-v-3c980ba6] .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #A1A6B0;
    pointer-events: none;
    height: 0;
}
[data-v-3c980ba6] .ProseMirror-focused p.is-editor-empty:first-child::before {
    opacity: 0;
}
[data-v-3c980ba6] .ProseMirror > *:first-child {
    margin-top: 0;
}
[data-v-3c980ba6] .ProseMirror > *:last-child {
    margin-bottom: 0;
}
[data-v-3c980ba6] .ProseMirror p {
    margin: 1em 0;
}
[data-v-3c980ba6] .ProseMirror i {
    font-style: italic;
}
[data-v-3c980ba6] .ProseMirror u {
    text-decoration: underline;
}
[data-v-3c980ba6] .ProseMirror b,[data-v-3c980ba6] .ProseMirror strong {
    font-weight: 600;
}
[data-v-3c980ba6] .ProseMirror ul {
    list-style-type: disc;
    padding-left: 16px;
    margin: 1em 0;
}
[data-v-3c980ba6] .ProseMirror ol {
    padding-left: 16px;
    margin: 1em 0;
}
[data-v-3c980ba6] .ProseMirror ul li > *:first-child,[data-v-3c980ba6] .ProseMirror ol li > *:first-child,[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] li > div > *:first-child {
    margin-top: 0;
}
[data-v-3c980ba6] .ProseMirror ul li > *:last-child,[data-v-3c980ba6] .ProseMirror ol li > *:last-child,[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] li > div > *:last-child {
    margin-bottom: 0;
}
[data-v-3c980ba6] .ProseMirror blockquote {
    border-left: 3px solid rgba(161, 166, 176, 0.4);
    color: #65687A;
    margin: 1em 0;
    padding-left: 16px;
}
[data-v-3c980ba6] .ProseMirror a {
    color: #0961EB;
    text-decoration: none;
    transition: .2s;
}
[data-v-3c980ba6] .ProseMirror a:after {
      opacity: .7;
      transition: .2s;
}
[data-v-3c980ba6] .ProseMirror a:hover {
      color: #0857d2;
}
[data-v-3c980ba6] .ProseMirror a:hover:after {
        opacity: 1;
}
[data-v-3c980ba6] .ProseMirror img {
    max-width: 100%;
    height: auto;
    margin: 1em 0;
}
[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
}
[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] li {
      display: flex;
}
[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] li > label {
        flex: 0 0 auto;
        display: flex;
        align-items: start;
        margin: 0 0.5rem 0 0;
}
[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] li > div {
        flex: 1 1 auto;
}
[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] p {
      margin: 0;
}
[data-v-3c980ba6] .ProseMirror ul[data-type="taskList"] input[type="checkbox"] {
      cursor: pointer;
      margin: 4px 0 3px 0;
}
[data-v-3c980ba6] .ProseMirror .tableWrapper {
    margin: 1em 0;
}
[data-v-3c980ba6] .ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
}
[data-v-3c980ba6] .ProseMirror table td,[data-v-3c980ba6] .ProseMirror table th {
      min-width: 1em;
      border: 1px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
}
[data-v-3c980ba6] .ProseMirror table td > *,[data-v-3c980ba6] .ProseMirror table th > * {
        margin: 0;
}
[data-v-3c980ba6] .ProseMirror table th {
      font-weight: 500;
      text-align: left;
      background-color: #f1f3f5;
}
[data-v-3c980ba6] .ProseMirror table .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
}
[data-v-3c980ba6] .ProseMirror table .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
}
.ecs-tiptap-menu[data-v-3c980ba6] {
  padding: 4px 4px 5px 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  border-bottom: none;
  border-radius: 3px 3px 0 0;
  overflow-y: clip;
  overflow-x: auto;
  z-index: 1;
}
.ecs-tiptap-menu[data-v-3c980ba6]:after {
    content: "";
    position: absolute;
    left: 5px;
    right: 5px;
    bottom: 0;
    height: 1px;
    background: #E7E9EC;
}
.ecs-tiptap-menu > div[data-v-3c980ba6] {
    display: flex;
    align-items: center;
}
.ecs-tiptap-menu .ecs-button[data-v-3c980ba6] {
    margin-right: 4px;
}
.ecs-tiptap-menu .ecs-button[data-v-3c980ba6]:last-child {
      margin-right: 0;
}
.ecs-tiptap-menu .separator[data-v-3c980ba6] {
    width: 1px;
    height: 16px;
    background: #D7DAE1;
    border-radius: 1px;
    margin: 0 8px;
}
.ecs-bubble-menu[data-v-3c980ba6] {
  display: inline-flex;
  align-items: center;
  background-color: rgba(42, 45, 61, 0.97);
  padding: 4px;
  border-radius: 6px;
}
.ecs-bubble-menu button[data-v-3c980ba6] {
    border: none;
    background: none;
    color: #FFF;
    opacity: 0.7;
    transition: .2s;
    padding: 0;
    display: flex;
    border-radius: 4px;
    margin-right: 4px;
}
.ecs-bubble-menu button[data-v-3c980ba6]:last-child {
      margin-right: 0;
}
.ecs-bubble-menu button[data-v-3c980ba6]:hover {
      opacity: .9;
      background: rgba(0, 0, 0, 0.5);
}
.ecs-bubble-menu button.is-active[data-v-3c980ba6] {
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
}
.ecs-bubble-menu button[data-v-3c980ba6]:disabled {
      opacity: .3;
      pointer-events: none;
}
[data-v-3c980ba6] .mention {
  color: #0961EB;
  background-color: #DFEEFF;
  border-radius: 4px;
  padding: 0.1rem 0.3rem;
}
