.radio-grid[data-v-3e890aa2] {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: var(--spacing-15);
}
.column-label[data-v-3e890aa2] {
  padding: 6px 32px 6px 0;
  text-align: right;
}
.custom-template-selection[data-v-3e890aa2] {
  padding: var(--spacing-10) 0 0 30px;
  position: relative;
}
.custom-template-selection .manage[data-v-3e890aa2] {
    position: absolute;
    top: -24px;
    right: 0;
}
.preview[data-v-3e890aa2] {
  border-radius: var(--border-radius-medium) 0 0 0;
  border-left: 1px solid var(--color-gray-3);
  border-top: 1px solid var(--color-gray-3);
  padding: 2px;
  overflow-x: auto;
  display: flex;
  margin-top: var(--spacing-20);
  position: relative;
}
.preview > span[data-v-3e890aa2] {
    flex-shrink: 0;
    padding: var(--spacing-5) var(--spacing-10);
    border-right: 1px solid var(--color-gray-3);
    font-size: var(--type-scale-2-font-size);
    line-height: var(--type-scale-2-line-height);
    font-weight: var(--font-weight-medium);
    color: var(--color-gray-10);
}
.edit-button[data-v-3e890aa2] {
  margin: -2px 0 -2px 8px;
}
.back-button[data-v-3e890aa2] {
  margin: 0 0 0 -12px;
}
.templates-index-header[data-v-3e890aa2] {
  padding: 0 var(--spacing-20) var(--spacing-10) var(--spacing-20);
}
