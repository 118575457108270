.import-card-main[data-v-d629f760] {
  font-size: var(--type-scale-3-font-size);
  line-height: var(--type-scale-3-line-height);
}
.import-card-title[data-v-d629f760] {
  padding: 14px var(--spacing-20);
  flex: 1;
}
.import-card-title time[data-v-d629f760] {
    min-width: 160px;
}
.import-card-meta[data-v-d629f760] {
  padding-right: var(--spacing-20);
  flex-shrink: 0;
}
.import-card-status[data-v-d629f760] {
  min-height: 52px;
}
.import-card-details[data-v-d629f760] {
  padding: 0 var(--spacing-10);
  background: var(--color-white);
  cursor: default;
}
.import-card-details-inner[data-v-d629f760] {
    padding: var(--spacing-10) var(--spacing-10) var(--spacing-10) 0;
    border-top: 1px solid var(--color-gray-3);
}
.import-status[data-v-d629f760] {
  border: 1px solid var(--color-gray-3);
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-5) var(--spacing-10) var(--spacing-5) var(--spacing-5);
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
}
.import-status-icon[data-v-d629f760] {
    position: relative;
    width: 24px;
    height: 24px;
}
.import-status-icon .status-sticker[data-v-d629f760] {
      position: absolute;
      bottom: -1px;
      right: -2px;
      z-index: 1;
}
.hide[data-v-d629f760] {
  visibility: hidden;
}
