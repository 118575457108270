.dialog-confirm-text[data-v-1f74e46e] {
  position: relative;
}
.dialog-confirm-text-watermark[data-v-1f74e46e] {
    position: absolute;
    bottom: 0;
    background: transparent;
    opacity: .7;
}
.dialog-confirm-text-input[data-v-1f74e46e] {
    position: relative;
    z-index: 2;
}
