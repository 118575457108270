.viewer-legend[data-v-60ae76a2] {
  width: 200px;
  border-radius: var(--border-radius-medium);
  padding: var(--spacing-5);
  position: absolute;
  left: 8px;
  top: 6px;
  z-index: 3;
  overflow: auto;
  max-height: calc(100vh - 106px);
  backdrop-filter: blur(34px);
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 0 1px rgba(71, 75, 96, 0.05), 0 2px 8px rgba(71, 75, 96, 0.15);
}
.viewer-legend-split-screen[data-v-60ae76a2] {
    top: calc(35vh + 36px + 6px);
    max-height: calc(65vh - 36px - 106px);
}
.viewer-legend > *[data-v-60ae76a2] {
    margin-bottom: var(--spacing-10);
}
.viewer-legend > *[data-v-60ae76a2]:last-child {
      margin-bottom: 0;
}
.viewer-legend:hover .close-legend[data-v-60ae76a2] {
    opacity: 1;
}
.party-header[data-v-60ae76a2] {
  font-size: var(--type-scale-2-font-size);
  line-height: var(--type-scale-2-line-height);
  font-weight: var(--font-weight-bold);
  color: var(--color-gray-12);
  padding: var(--spacing-5) var(--spacing-15) var(--spacing-5) var(--spacing-5);
}
.close-legend[data-v-60ae76a2] {
  position: absolute;
  right: 4px;
  top: 4px;
  opacity: 0;
  transition: .2s;
  z-index: 2;
}
@media only screen and (min-width: 1290px) {
.ecs-viewer-canvas:not(.sidebar-expanded) .viewer-legend[data-v-60ae76a2] {
    backdrop-filter: blur(0);
    background: transparent;
    box-shadow: none;
}
}
@media only screen and (min-width: 1940px) {
.ecs-viewer-canvas.sidebar-expanded .viewer-legend[data-v-60ae76a2] {
    backdrop-filter: blur(0);
    background: transparent;
    box-shadow: none;
}
}
