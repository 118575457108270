.custom-table .multiselect.standard.invisible {
  min-height: 32px;
}
.custom-table .multiselect.standard.invisible .multiselect__select {
    height: 32px;
}
.custom-table .multiselect.standard.invisible .multiselect__select:before {
      top: 12px;
}
.custom-table .multiselect.standard.invisible .multiselect__tags {
    min-height: 32px;
    padding: 0px 24px 0px 0px;
}
.custom-table .multiselect.standard.invisible .multiselect__tags-wrap {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 2px;
    align-items: center;
}
.custom-table .multiselect.standard.invisible .multiselect__tag {
    height: 26px;
    margin: 0;
}
.custom-table .multiselect.standard.invisible .multiselect__input,
  .custom-table .multiselect.standard.invisible .multiselect__single {
    height: 32px;
    line-height: 32px;
    overflow: hidden;
    white-space: nowrap;
}
.custom-table .multiselect.standard.invisible .multiselect__single > span {
    -webkit-line-clamp: 1;
    white-space: normal;
    height: 32px;
}
.custom-table .multiselect.standard.invisible .multiselect__placeholder {
    height: 32px;
}
.custom-table .ecs-form-check-label {
  line-height: 18px;
}
.custom-table .multiselect .icon {
  margin: 0 2px 0 -6px;
  height: 24px;
}
.ecs-collapsable-content > .ecs-data-grid.bordered {
  border: none;
}
.table-secondary {
  opacity: .6;
  font-style: italic;
}
