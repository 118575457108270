.label-right[data-v-6c6a06dd] {
  padding-right: 30px;
  text-align: right;
}
.label-right label[data-v-6c6a06dd] {
    margin-bottom: 0;
    font-weight: 500;
}
hr[data-v-6c6a06dd] {
  border: none;
  border-bottom: 1px solid #ECEDF2;
}
.fetching[data-v-6c6a06dd] {
  padding: 100px;
}
