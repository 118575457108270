.analysis-info > *:first-child {
  padding-top: 20px;
}
.analysis-info > *:first-child.ecs-collapsable {
    padding-top: 0;
    border-top: none;
}
.analysis-info .ecs-collapsable + *:not(.ecs-collapsable) {
  margin-top: 20px;
}
.analysis-info .ecs-collapsable + .ecs-collapsable {
  border-top: none;
}
