<template>
  <span v-if="ready" :style="appHeight">
    <div id="portal-dialog" />
    <div id="portal-modal" />
    <ec-app-error-handlers />
    <ec-app-nav-header v-if="!isViewer" />
    <ec-notifications />
    <router-view v-if="ready" :key="matterId" @refresh="refresh" />
    <ecs-banner v-if="!ignoreBrowserWarning && (browser == 'unsupported' || browser == 'mobile')" @dismiss="setIgnoreBrowserWarning" type="warning" dismissable fixed>
      <template v-if="browser == 'unsupported'">
        Everchron does not support this browser. We recommend using <a href="https://www.google.com/chrome/" target="_blank" title="Get Chrome">Chrome</a> for the best experience.
      </template>
      <template v-else>
        Everchron does not support mobile browsers. We recommend using <a href="https://www.google.com/chrome/" target="_blank" title="Get Chrome">Chrome</a> for desktop.
      </template>
    </ecs-banner>
  </span>
</template>

<script>
  import EcAppErrorHandlers from 'ec-common/components/utility/error-handlers'
  import EcNotifications    from 'ec-common/components/utility/notifications'
  import EcAppNavHeader     from 'ec-common/components/utility/nav-header'
  import validators         from 'ec-common/validators'
  import UserPreferences    from 'ec-common/mixins/user-preferences'
  import browserDetect      from 'browser-detect'

  export default {
    components: { EcAppNavHeader, EcNotifications, EcAppErrorHandlers },
    mixins: [UserPreferences],

    data() {
      return {
        ready          : false,
        viewerNavDir   : null,
        windowHeight   : 0,
        windowWidth    : 0,
        windowWidthWas : 0,
        broadcaster    : null,
        initialMatter  : null,
        ignoreBrowserWarning : false,
        fetchMatterDeetsAttempts : 0
      }
    },

    computed: {
      matterId() {
        return _.parseInt(this.$route.params.matterId) || 0
      },

      matter() {
        return this.$store.getters['matters/active']
      },

      matters() {
        return this.$store.getters['matters/all']
      },

      appHeight() {
        return { '--app-height': this.windowHeight + 'px' }
      },

      isViewer() {
        return window.location.href.indexOf('/viewer/') != -1
      },

      sectionVisible() {
        return this.$store.getters['general/sectionVisible']
      },

      // Checks the browser name and mobile via the browser-detect package. Returns 'null' if browser name is Chrome or Edge and not mobile. Returns 'unsupported' if browser is not Chrome or Edge. Returns 'mobile' if platform is mobile (no matter which browser).
      browser() {
        const browser = browserDetect()
        if (browser.name == 'chrome' || browser.name == 'edge')
          return browser.mobile ? 'mobile' : null
        else
          return 'unsupported'
      }
    },

    methods: {
      setRoleClass() {
        const classList = document.querySelector('body').classList
        _.each(classList.value.match(/role-[^\s]+/g), klass => {
          classList.remove(klass)
        })
        if (this.matter)
          classList.add(`role-${ this.matter.role }`)
      },

      getWindowHeight() {
        this.windowHeight = window.innerHeight
      },

      getWindowWidth() {
        const breakpoint = 1500
        this.windowWidth = window.innerWidth

        if (this.windowWidth >= breakpoint && this.windowWidthWas < breakpoint){
          this.$store.commit('me/modalSidebarVisible', true)
        } else if (this.windowWidth < breakpoint && this.windowWidthWas >= breakpoint){
          this.$store.commit('me/modalSidebarVisible', false)
        }

        this.windowWidthWas = this.windowWidth
      },

      setActiveMatter() {
        if (this.matterId != this.initialMatter){
          localStorage.removeItem('excerpt-view')
          const idx = _.findIndex(this.matters, ['id', this.matterId])
          if (idx < 0)
            return this.fetchMatterDetails()
        }
        this.$store.commit('matters/activate', this.matterId)
        this.initialMatter = this.matterId
        if (this.isViewer && !!this.matter)
          this.ready = true
      },

      refresh() {
        this.$store.commit('documents/clearFilter')
        this.$store.commit('documents/reset')
        this.$store.commit('masterFile/reset')
        this.$store.commit('witnesses/reset', { rows: [] })
        this.$store.commit('evidence/reset')
        this.$store.commit('imports/reset')
        this.$store.commit('tags/reset')
        this.setActiveMatter()
        this.setRoleClass()
      },

      refreshIndex(scope, deleted) {
        if (scope == 'any')
          scope = this.$store.getters['general/sectionVisible']
        this.$bus.$emit('refresh', scope, true, null, deleted)
      },

      refreshDrawer(scope) {
        this.$bus.$emit('refreshDrawer', scope)
      },

      refreshExcerptTray(scope, deleted) {
        if (!deleted)
          this.$bus.$emit('refreshExcerptTray', deleted)
      },

      refreshEntry(modifiedId, modifiedDocId, scope){
        const indexes = ['documents', 'evidence', 'transcripts', 'masterFile', 'master-file']
        if (scope == 'master-file')
          scope = 'masterFile'
        if (indexes.includes(this.sectionVisible)){
          let sv = _.clone(this.sectionVisible)
          let id = modifiedId
          let entry = null
          if (sv == 'transcripts')
            sv = 'documents'
          if (sv != scope){
            if (scope == 'documents'){
              entry = this.$store.getters[`${ sv }/findByDocId`](modifiedId)
            }
            else if (scope == 'masterFile' && sv == 'evidence' || scope == 'evidence' && sv == 'masterFile'){
              entry = this.$store.getters[`${ sv }/findByDocId`](modifiedDocId)
            }
            else if (sv == 'documents' && (scope == 'masterFile' || scope == 'evidence')){
              entry = this.$store.getters['documents/find'](modifiedDocId)
            }
            else {
              entry = this.$store.getters[`${ sv }/find`](modifiedId)
            }
            if (_.isArray(entry)) // Chronology getter returns an array
              entry = entry[0]
          }
          else {
            entry = this.$store.getters[`${ sv }/find`](modifiedId)
          }
          id = !_.isNil(entry) && entry.id ? entry.id : null

          if (!_.isNil(id)){
            this.$store.dispatch(`${ sv }/fetch`, { id, matter_id: this.matterId })
            // this.$bus.$emit('refreshEntry')
          }
          else {
            this.setIndexStale(scope)
          }
        }
      },

      updateActiveIndexItem(entryId, scope) {
        this.$bus.$emit('updateActiveIndexEntry', entryId, scope, this.viewerNavDir)
        if (!_.isNil(entryId))
          localStorage.setItem('open-viewer-entry', `${ scope }-${ entryId }-${ this.viewerNavDir }-${ this.matterId }`)
        this.viewerNavDir = null
      },

      updateNavDirection(dir) {
        this.viewerNavDir = dir
      },

      resetViewerZoom(){
        localStorage.removeItem('viewerZoom')
      },

      getWindowSize(){
        this.getWindowHeight()
        this.getWindowWidth()
      },

      updateTitle(newTitle){
        document.title = newTitle
      },

      setIndexStale(scope){
        this.$bus.$emit('setIndexStale', scope)
      },

      maybeSetIndexStale(id, scope){
        // Every time the Viewer is saved, re-fetch the IDs to know if the currently-open-in-the-viewer would still be visible
        // in the index based on the current query and if not, set the index as stale
        const query = this.$store.getters[`${ scope }/query`]
        if (!_.isNil(query) && query != ''){
          this.$store.dispatch(`${ scope }/ids`, { skipCommit: true, matter_id: this.matter.id })
          .then(resp => {
            if (!_.includes(resp.body, id))
              this.setIndexStale(scope)
          })
        }
      },

      manageTags(){
        const routerData = this.$router.resolve({
          name: 'matters.edit', params: { matterId: this.matterId }, hash: '#tags'
        })
        window.open(routerData.href, '_matterSettings')
      },

      manageObjectionBases(){
        const routerData = this.$router.resolve({
          name: 'matters.edit', params: { matterId: this.matterId }, hash: '#fields', query: { tab: 'objectionbases' }
        })
        window.open(routerData.href, '_matterSettings')
      },

      beforeUnloadHandler(e){
        this.broadcaster.postMessage({ 'action': 'setIndexRestore' })
      },

      fetchMatterDetails(){
        if (this.isViewer){
          let mid = window.location.href.split('matters/')[1]
          mid = mid.split('/')[0]
          this.$store.dispatch('matters/fetch', { id: Number(mid) }).then(resp => {
            this.$store.commit('matters/update', resp.body)
            this.setActiveMatter()
            return Promise.resolve()
          })
        }
        else if (this.matters && this.matters.length > 0 && this.matterId > 0){
          this.$store.dispatch('matters/fetch', { id: this.matterId })
          this.fetchMatterDeetsAttempts = 0
        }
        else {
          this.fetchMatterDeetsAttempts++
          if (this.fetchMatterDeetsAttempts < 6)
            setTimeout(() => { this.fetchMatterDetails() }, 600)
        }
      },

      setIgnoreBrowserWarning() {
        sessionStorage.setItem('ignoreBrowserWarning', true)
      },

      reloadTags() {
        this.$bus.$emit('reloadTags')
      }
    },

    watch: {
      matterId() {
        this.refresh()
      },

      '$route': {
        deep: true, handler(newVal, oldVal) {
          this.$store.dispatch('route/updateState', newVal)
        }
      }
    },

    beforeCreate() {
      this.$store.initializeModules()
    },

    created() {
      if (this.isViewer){
        Promise.all([
          // this.$store.dispatch('me/load', { matter_id: this.matterId }), // Calling from viewer/index now
          this.$store.dispatch('organization/fetch')
        ]).then(() => {
          this.setRoleClass()
        })
      }
      else {
        Promise.all([
          this.$store.dispatch('matters/index'),
          this.$store.dispatch('matters/indexNearlined'),
          this.$store.dispatch('me/load'),
          this.$store.dispatch('organization/fetch'),
          this.$store.dispatch('versions/fetch')
        ]).then(() => {
          this.setRoleClass()
          this.setActiveMatter()
          this.ready = true
        })
      }

      this.initialMatter = this.matterId
      this.loadUserPreferences()
      window.addEventListener('beforeunload', this.beforeUnloadHandler)
      this.$bus.$on('updateTitle', this.updateTitle)
      if (!this.isViewer){ // #13318
        window.ecIndexHash = Math.random().toString(36).slice(2, 9)
      }

      this.broadcaster = new BroadcastChannel('app-channel')
      this.broadcaster.onmessage = messageEvent => {
        const data = messageEvent.data

        if (data && data.modifiedId) // Fetch and update collection for just the entry modified in the Viewer
          this.refreshEntry(data.modifiedId, data.modifiedDocId, data.scope)

        if (data && data.refreshIndex)
          this.refreshIndex(data.refreshIndex, data.deleted)

        if (data && data.refreshDrawer)
          this.refreshDrawer(data.refreshDrawer)

        if (data && data.setIndexStale)
          this.setIndexStale(data.setIndexStale)

        if (data && data.maybeSetIndexStale)
          this.maybeSetIndexStale(data.id, data.maybeSetIndexStale)

        if (data && data.hasOwnProperty('updateActiveIndexItem'))
          this.updateActiveIndexItem(data.updateActiveIndexItem, data.scope)

        if (data && data.action == 'setIndexRestore')
          this.updateActiveIndexItem(null, null)

        if (data && data.refreshExcerptTray)
          this.refreshExcerptTray(data.refreshExcerptTray, data.deleted)

        if (data && data.resetViewerZoom)
          this.resetViewerZoom()

        if (data && data.updateNavDirection)
          this.updateNavDirection(data.updateNavDirection)

        if (data && data.reloadTags)
          this.reloadTags()
      }
      
      window.addEventListener('beforeunload', this.beforeUnloadHandler)
      this.$bus.$on('updateTitle', this.updateTitle)

      if (!this.isViewer){ // #13318
        window.ecIndexHash = Math.random().toString(36).slice(2, 9)
      }

      this.loadUserPreferences()
      localStorage.removeItem('excerpt-view')
    },

    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.getWindowSize)
        this.$bus.$on('manageTags', this.manageTags)
        this.$bus.$on('manageObjectionBases', this.manageObjectionBases)
        this.getWindowHeight()
        this.getWindowWidth()
      })
      localStorage.removeItem('excerpt-view')
      this.ignoreBrowserWarning = sessionStorage.getItem('ignoreBrowserWarning') == 'true'

      if (!this.isViewer){
        this.fetchMatterDetails()

        // Clean up after #13318
        const storage = Object.entries(localStorage)
        const indexHashes = _.filter(storage, s => {
          return s[0].match(/.+-query-.+/)
        })
        const i = _.findIndex(indexHashes, ih => {
          return _.includes(ih[0], window.ecIndexHash)
        })
        if (i >= 0){
          indexHashes.splice(i, 1)
        }
        _.each(indexHashes, hash => {
          localStorage.removeItem(hash[0])
        })
        // End clean up after #13318

        $(function(){ // Prevents Ctrl/Cmd+a from selecting all of the text on the page, and Ctrl+d from bookmarking page
          $(document).keydown(function(e) {
            if (e.ctrlKey || e.metaKey)
              if (e.keyCode == 68 || (e.keyCode == 65 && e.target.nodeName != 'INPUT')) {
                return false
            }
          })
        })
      }
    },

    // I can't think of any scenario when these will execute, as in the Viewer window, the index.vue and viewer.vue are always present,
    // and the lifecycle hooks do not fire when the window is closed, so, it would appear that not unbinding these doesn't hurt anything?
    beforeUnmount() {
      this.$store.resetModules()
      window.removeEventListener('resize', this.getWindowSize)
      window.removeEventListener('beforeunload', this.beforeUnloadHandler)
      this.$bus.$off('manageTags', this.manageTags)
      this.$bus.$off('manageObjectionBases', this.manageObjectionBases)
      this.$bus.$off('updateTitle', this.updateTitle)
      if (!_.isNil(this.broadcaster))
        this.broadcaster.close()
      localStorage.removeItem('open-viewer-entry')
    },

    validators
  }
</script>

<style>
.intercom-lightweight-app {
  display: none
}
</style>
