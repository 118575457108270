.transcript-health-alert[data-v-40be25d1] {
  background: var(--color-green-1);
  padding: var(--spacing-20) var(--spacing-20) var(--spacing-15) var(--spacing-20);
}
.transcript-health-footer[data-v-40be25d1] {
  padding: var(--spacing-5) var(--spacing-10);
  border-top: 1px solid var(--color-gray-3);
}
.profile-relevance-switch[data-v-40be25d1] {
  margin: -8px auto 12px auto;
  display: inline-flex !important;
}
