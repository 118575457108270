.designation-mark[data-v-47396124] {
  position: absolute;
  left: 0;
  z-index: 2;
  width: 10px;
  pointer-events: none;
}
.designation-mark.active[data-v-47396124] {
    z-index: 3;
}
.designation-mark.active .designation-mark-pane[data-v-47396124] {
      opacity: .15;
}
.designation-mark-wrap[data-v-47396124] {
    height: 100%;
}
.designation-mark-rail[data-v-47396124] {
    height: 100%;
    width: 6px;
    position: absolute;
    z-index: 1;
    pointer-events: auto;
}
.designation-mark-rail[data-v-47396124]:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 3px;
      background: currentColor;
}
.designation-mark-rail[data-v-47396124]:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 6px;
      box-shadow: 0 3px 0 0 inset currentColor, 0 -3px 0 0 inset currentColor;
}
.designation-mark-rail:hover + .designation-mark-party[data-v-47396124] {
      opacity: 1;
}
.designation-mark-rail:hover ~ .designation-mark-pane[data-v-47396124] {
      opacity: .08;
}
.designation-mark-rail.counter-only[data-v-47396124]:before {
      background: linear-gradient(45deg, rgba(0, 0, 0, 0) 18.75%, currentColor 18.75%, currentColor 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 68.75%, currentColor 68.75%, currentColor 100%);
      background-size: 8px 8px;
}
.designation-mark-pane[data-v-47396124] {
    height: 100%;
    background: currentColor;
    opacity: 0;
    transition: .2s;
    pointer-events: none;
}
.designation-mark-party[data-v-47396124] {
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    position: absolute;
    width: max-content;
    top: 50%;
    left: -10px;
    height: 20px;
    font-size: 11px;
    font-weight: 500;
    user-select: none;
    opacity: 0;
    transition: .2s;
    filter: brightness(70%);
}
.designation-mark .ecs-context-button-group[data-v-47396124] {
    position: absolute;
    right: 10px;
    top: -35px;
    opacity: 0;
    pointer-events: auto;
}
.designation-mark .ecs-context-button-group.show[data-v-47396124] {
      opacity: 1;
}
.designation-mark .ecs-context-button-group .radius[data-v-47396124] {
      border-radius: 5px;
}
